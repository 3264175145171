import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button } from "@mui/material";
import { DataGridStyle } from "../../../styles";
import Header from "../../../components/Header";
import { handlePageChange } from "../../../services/utils/filters";
import { TabContext, TabPanel } from "@mui/lab";
import { useFetchInventoryStock } from "../../../services/apis/inventory";
import StockDialog from "./StockDialog";
import { saveAs } from "file-saver";
import DashboardMainHeader from "../../../components/DashboardMainHeader";
import AddButton from "../../../components/common/AddButton";
import { FirstBox, SecondBox } from "../../../components/common/CustomBox";
import { ItemsColumns } from "../../../components/common/Columns";
import NewTabs from "../../../components/common/NewTabs";

const Stock = () => {
  const tabs = [
    { label: "Ingots", value: "1" },
    { label: "Fractions", value: "2" },
  ];
  const [stock, setStock] = useState([]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("1");
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
    orderBy: null,
    sort: null,
    type: "ingot",
  });
  const { data, isFetching, refetch } = useFetchInventoryStock(queryParameters);

  const handleChange = (newValue) => {
    setValue(newValue);
    if (newValue === "1") {
      setQueryParameters({
        ...queryParameters,
        page: 0,
        type: "ingot",
      });
    } else if (newValue === "2") {
      setQueryParameters({
        ...queryParameters,
        page: 0,
        type: "fraction",
      });
    }
  };

  const downloadStockIngots = async () => {
    try {
      const response = await refetch(queryParameters);
      const stocks = response.data.data.result;

      const headers = ["Product", "Quantity", "Product Status"];

      const csvContent = [
        headers.join(","),
        ...stocks.map((stock) => {
          return [
            stock.name,
            stock.amount,
            stock.availability === 0 ? "Out Of Stock" : "In Stock",
          ].join(",");
        }),
      ].join("\n");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      saveAs(blob, "stocks.csv");
    } catch (error) {
      console.error("Error downloading stocks:", error);
    }
  };
  const ingotsColumns = ItemsColumns([
    { field: "name", headerName: "Product" },
    { field: "amount", headerName: "Quantity" },
    {
      field: "availability",
      headerName: "Product Status",
      specialRender: "availability",
    },
  ]);

  const fractionsColumns = ItemsColumns([
    { field: "name", headerName: "Product" },
    { field: "amount", headerName: "Quantity" },
    {
      field: "availability",
      headerName: "Product Status",
      specialRender: "availability",
    },
  ]);

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <DashboardMainHeader />

      <Header title="Products Inventory" marginL={"20px"} />
      <StockDialog
        value={value}
        queryParameters={queryParameters}
        fetchData={() => {
          refetch(queryParameters);
        }}
        setStock={setStock}
        stock={stock}
        open={open}
        onClose={() => setOpen(false)}
        label={"Request"}
      />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 2,
          p: 2,
          justifyContent: "end",
        }}
      >
        <AddButton
          title={"+ Request Order"}
          onClick={() => {
            setOpen(true);
          }}
        />
      </Box>
      <SecondBox>
        <FirstBox m="40px 0 0 0" height="100%">
          <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
            <TabContext value={value}>
              <Box
                sx={{
                  width: "100%",
                  paddingTop: "2%",
                  paddingBottom: "1%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <NewTabs tabs={tabs} value={value} onChange={handleChange} />
              </Box>
              <TabPanel value="1">
                <Box
                  sx={{
                    height: "auto",
                    width: "100%",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: "10px",
                    }}
                  >
                    <Button
                      onClick={() => downloadStockIngots()}
                      sx={{
                        borderRadius: "9px",
                        background: "#917244",
                        "&:hover": {
                          background: "#917244",
                        },
                        width: "171px",
                        height: "42px",
                        color: "#FFF",
                        fontSize: "16px",
                        textTransform: "none",
                      }}
                    >
                      {"Download Report"}
                    </Button>
                  </Box>
                  <DataGrid
                    disableSelectionOnClick
                    sx={{
                      cursor: "pointer",
                      backgroundColor: "#ffffff",
                      height: "630px",
                    }}
                    pageSize={10}
                    rowCount={data?.data?.totalItems || 0}
                    rows={isFetching ? [] : data?.data?.result}
                    loading={isFetching}
                    columns={ingotsColumns}
                    paginationMode="server"
                    onPageChange={(p) =>
                      handlePageChange(p, queryParameters, setQueryParameters)
                    }
                    rowsPerPageOptions={[10]}
                    totalPages={data?.data?.totalPages}
                    getRowId={(row) => row.id}
                    localeText={{
                      noRowsLabel: "No gold ingots stocks",
                    }}
                  />
                </Box>
              </TabPanel>
              <TabPanel value="2">
                <Box
                  sx={{
                    height: "auto",
                    width: "100%",
                    justifyContent: "center",
                    background: "#ffffff",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: "10px",
                    }}
                  >
                    <Button
                      onClick={() => downloadStockIngots()}
                      sx={{
                        borderRadius: "9px",
                        background: "#917244",
                        "&:hover": {
                          background: "#917244",
                        },
                        width: "171px",
                        height: "42px",
                        color: "#FFF",
                        fontSize: "16px",
                        textTransform: "none",
                      }}
                    >
                      {"Download Report"}
                    </Button>
                  </Box>
                  <DataGrid
                    disableSelectionOnClick
                    sx={{
                      cursor: "pointer",
                      backgroundColor: "#ffffff",
                      height: "630px",
                    }}
                    pageSize={10}
                    rowCount={data?.data?.totalItems || 0}
                    rows={isFetching ? [] : data?.data?.result}
                    columns={fractionsColumns}
                    loading={isFetching}
                    paginationMode="server"
                    onPageChange={(p) =>
                      handlePageChange(p, queryParameters, setQueryParameters)
                    }
                    rowsPerPageOptions={[10]}
                    totalPages={data?.data?.totalPages}
                    getRowId={(row) => row.id}
                    localeText={{
                      noRowsLabel: "No gold fractions stock",
                    }}
                  />
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        </FirstBox>
      </SecondBox>
    </Box>
  );
};

export default Stock;
