import { Box, Button, Step, StepLabel, Stepper } from "@mui/material";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../components/Header";
import { useState } from "react";
import CustomDialog from "../../components/CustomDialog";
import StepOneTicketQuestions from "./StepOneTicketQuestions";
import StepThreeTicketQuestions from "./StepThreeTicketQuestions";
import StepTwoTicketQuestions from "./StepTwoTicketQuestions";
import { addTicketQuestion } from "../../services/apis/tickets";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { useStylesHook } from "../../components/common/useStyles";
import { stepsEA } from "../../data/steps";

const AddNewTicketQuestion = () => {
  const { id } = useParams();
  const location = useLocation();
  const selectedTicketData = location?.state?.id;
  const c = useStylesHook();
  const [isValid, setIsValid] = useState([false, false, false]);
  const [activeStep, setActiveStep] = useState(0);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("No");
  const dispatch = useDispatch();
  const [faqData, setFaqData] = useState({
    contactUs_type_id: null,
    sort: null,
    trans: [
      {
        local: "en",
        question: "",
        answer: "",
      },
      {
        local: "ar",
        question: "",
        answer: "",
      },
      {
        local: "eg",
        question: ".",
        answer: ".",
      },
    ],
    subQuestions: [
      {
        trans: [
          {
            local: "en",
            question: "",
            answer: "",
          },
          {
            local: "ar",
            question: "",
            answer: "",
          },
          {
            local: "eg",
            question: ".",
            answer: ".",
          },
        ],
      },
    ],
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  const [subQuestions] = useState([
    {
      title: "",
      description: "",
    },
  ]);
  const handleAddSubQuestion = () => {
    setFaqData((prevFaqData) => ({
      ...prevFaqData,
      subQuestions: [
        ...prevFaqData.subQuestions,
        {
          trans: [
            {
              local: "en",
              question: "",
              answer: "",
            },
            {
              local: "ar",
              question: "",
              answer: "",
            },
            {
              local: "eg",
              question: ".",
              answer: ".",
            },
          ],
        },
      ],
    }));
  };
  const handleDeleteSubQuestion = (index) => {
    setFaqData((prevFaqData) => ({
      ...prevFaqData,
      subQuestions: prevFaqData.subQuestions.filter((_, i) => i !== index),
    }));
  };
  const handleSave = async () => {
    try {
      setLoading(true);
      const sentData =
        type === "No"
          ? {
              contactUs_type_id: faqData.contactUs_type_id,
              sort: faqData.sort,
              trans: faqData?.trans,
            }
          : { ...faqData };
      await addTicketQuestion(sentData);
      setSuccessDialogOpen(true);
    } catch (error) {
      if (error.response.status === 422) {
        dispatch(
          setData({
            openSnack: true,
            message: "Fields Should not be empty",
          })
        );
      } else {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      }
    } finally {
      setLoading(false);
    }
  };
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <StepOneTicketQuestions
            selectedTicketData={selectedTicketData}
            subjectId={id}
            subQuestions={subQuestions}
            handleAddSubQuestion={handleAddSubQuestion}
            handleDeleteSubQuestion={handleDeleteSubQuestion}
            type={type}
            handleTypeChange={handleTypeChange}
            faqData={faqData}
            setFaqData={setFaqData}
            setIsValid={setIsValid}
            isValid={isValid}
          />
        );
      case 1:
        return (
          <StepTwoTicketQuestions
            selectedTicketData={selectedTicketData}
            subjectId={id}
            subQuestions={subQuestions}
            handleAddSubQuestion={handleAddSubQuestion}
            handleDeleteSubQuestion={handleDeleteSubQuestion}
            type={type}
            faqData={faqData}
            setFaqData={setFaqData}
            setIsValid={setIsValid}
            isValid={isValid}
          />
        );
      // case 2:
      //   return (
      //     <StepThreeTicketQuestions
      //       selectedTicketData={selectedTicketData}
      //       subjectId={id}
      //       subQuestions={subQuestions}
      //       handleAddSubQuestion={handleAddSubQuestion}
      //       handleDeleteSubQuestion={handleDeleteSubQuestion}
      //       type={type}
      //       faqData={faqData}
      //       setFaqData={setFaqData}
      //       setIsValid={setIsValid}
      //       isValid={isValid}
      //     />
      //   );
      default:
        return null;
    }
  };
  return (
    <Box>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <Box sx={{ mb: "3%" }}>
          <DashboardMainHeader />
        </Box>

        <Header title={!id ? "Add Question" : "Edit Question"} />
      </Box>
      <Box
        m="20px"
        sx={{
          height: "70vh",
          background: "#FFFFFF",
          borderRadius: "12px",
          boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08)",
          overflow: "auto",
          padding: "4%",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Stepper className={c.root} activeStep={activeStep}>
            {stepsEA.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <>
            {renderStepContent(activeStep)}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              {activeStep !== 0 && (
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#ffffff",
                    },
                    backgroundColor: "#ffffff",
                    width: "200px",
                    height: "46px",
                    borderRadius: "12px",
                    color: "#917244",
                    border: "3px solid #917244",
                    fontSize: "14px",
                    marginRight: "20px",
                  }}
                >
                  Back
                </Button>
              )}
              <Button
                disabled={!isValid[activeStep] || loading}
                sx={{
                  "&:hover": { backgroundColor: "#917244" },
                  backgroundColor: "#917244",
                  width: "200px",
                  height: "46px",
                  borderRadius: "12px",
                  color: "#ffffff",

                  fontSize: "14px",
                }}
                onClick={
                  activeStep === stepsEA.length - 1 ? handleSave : handleNext
                }
              >
                {activeStep === stepsEA.length - 1 ? "Save" : "Next"}
              </Button>
            </Box>
          </>
        </Box>
      </Box>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title={
          !id
            ? "Question has been added Successfully."
            : "Question has been Edit Successfully."
        }
        message=""
        buttonText="Go to Ticket Questions"
        buttonLink="/ticketQuestions"
      />
      <ReusableSnackbar />
    </Box>
  );
};

export default AddNewTicketQuestion;
