import dayjs from "dayjs";

export const handleFromDate = (date, queryParameters, setQueryParameters) => {
  const formattedDate = date ? dayjs(date).format("MM/DD/YYYY") : "";
  setQueryParameters({ ...queryParameters, date_from: formattedDate });
};

export const handleToDate = (date, queryParameters, setQueryParameters) => {
  const formattedDate = date ? dayjs(date).format("MM/DD/YYYY") : "";
  setQueryParameters({ ...queryParameters, date_to: formattedDate });
};

export const handlePageChange = async (
  page,
  queryParameters,
  setQueryParameters
) => {
  setQueryParameters({ ...queryParameters, page });
};

export const handleSort = async (
  params,
  queryParameters,
  setQueryParameters
) => {
  if (params.length !== 0) {
    const orderBy =
      params[0].field === "orderStatus" ? "order_status_id" : params[0].field;
    const sort = params[0].sort === "asc" ? 1 : -1;
    setQueryParameters({ ...queryParameters, orderBy, sort });
  }
};

export const handleFilterByNumber = (
  value,
  type,
  queryParameters,
  setQueryParameters
) => {
  const valInNum = parseInt(value);
  if (queryParameters[type]?.includes(valInNum)) {
    let updatedFilter = queryParameters[type].filter((p) => p !== valInNum);
    if (updatedFilter.length === 0) updatedFilter = "";
    setQueryParameters({
      ...queryParameters,
      [type]: updatedFilter,
    });
  } else {
    setQueryParameters({
      ...queryParameters,
      [type]: [...queryParameters[type], valInNum],
    });
  }
};

export const handleFilterByString = (
  value,
  type,
  queryParameters,
  setQueryParameters
) => {
  if (queryParameters[type]?.includes(value)) {
    let updatedFilter = queryParameters[type].filter(
      (status) => status !== value
    );
    if (updatedFilter.length === 0) updatedFilter = "";
    setQueryParameters({
      ...queryParameters,
      [type]: updatedFilter,
    });
  } else {
    setQueryParameters({
      ...queryParameters,
      [type]: [...queryParameters[type], value],
    });
  }
};

export function checkString(input, queryParameters, setQueryParameters) {
  // Clear all query parameters before setting new ones
  setQueryParameters({
    ...queryParameters,
    phone: null,
    nationalId: null,
    email: null,
    serial: null,
    id: null,
  });

  // Regular expressions
  const regex10Digits = /^\d{10}$/; // Matches exactly 10 digits
  const regex14Digits = /^\d{14}$/; // Matches exactly 14 digits
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Matches email format
  const regexSerial = /^\d{12}$/; // Matches exactly 12 digits
  const regexEgyptianPhone = /^(01\d{9}|\d{10})$/; // Matches Egyptian numbers starting with 01 or 10 digits

  if (regexEgyptianPhone.test(input)) {
    // Remove leading "0" if present
    const formattedPhone = input.startsWith("0") ? input.slice(1) : input;
    if (regex10Digits.test(formattedPhone)) {
      setQueryParameters({ ...queryParameters, phone: formattedPhone });
    }
    return "Phone";
  } else if (regex14Digits.test(input)) {
    setQueryParameters({ ...queryParameters, nationalId: input });
    return "14 digits";
  } else if (regexEmail.test(input)) {
    setQueryParameters({ ...queryParameters, email: input });
    return "Email";
  } else if (regexSerial.test(input)) {
    setQueryParameters({ ...queryParameters, serial: input });
    return "12 digits";
  }

  return "Invalid input"; // Default case for invalid input
}

export const handleIsOnlineStatus = (
  value,
  queryParameters,
  setQueryParameters
) => {
  setQueryParameters({ ...queryParameters, is_online: value });
};
export const handleIsRoleType = (
  value,
  queryParameters,
  setQueryParameters
) => {
  setQueryParameters({ ...queryParameters, role_type: value });
};
