import { IconButton, InputBase } from "@mui/material";
import Header from "../../components/Header";
import { useDeletedUsers } from "../../services/apis/users";
import { useNavigate } from "react-router-dom";
import { ItemsColumns } from "../../components/common/Columns";
import { useState } from "react";
import {
  handleFilterByString,
  handlePageChange,
} from "../../services/utils/filters";
import NewTable from "../../components/common/NewTable";
import FilterDrawer from "../../components/common/FilterDrawer";

const DeletedUsers = () => {
  const navigate = useNavigate();
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
    phone: null,
    limit: 10,
  });
  const { data, isFetching } = useDeletedUsers(queryParameters);
  const deletedUsersColumns = ItemsColumns([
    { field: "full_name", headerName: "Username", flex: 2 },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 2,
      specialRender: "customRender",
      renderFunction: (params) => {
        return <p>0{params?.row?.phone}</p>;
      },
    },
    { field: "email", flex: 2 },
    {
      field: "status",
      headerName: "Document Status",
      documentStatus: true,
      flex: 2,
    },

    { field: "deleted_at", headerName: "Deletion Date", date: true, flex: 2 },
  ]);
  const handleSearchInput = (e) => {
    const input = e.target.value;
    let processedInput = input.replace(/^0+/, "");
    setQueryParameters((prev) => ({
      ...prev,
      phone: processedInput || null,
    }));
  };
  const handleRowClick = (row) => {
    navigate(`/users/${row.id}`);
  };
  return (
    <div>
      <Header title="Deleted Users" marginL={"25px"} />
      <div
        className="bg-white mx-4 rounded-xl"
        style={{
          boxShadow: "0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012",
        }}
      >
        <div className=" m-8 md:flex items-center justify-between">
          <div className="flex items-center rounded-xl bg-[#f5f5f5] p-2 w-[95%] md:w-3/5">
            <IconButton type="button" aria-label="search">
              <img alt="search-normal" src={`../../assets/search-normal.png`} />
            </IconButton>
            <InputBase
              onChange={handleSearchInput}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search using Phone number , email or National ID.... "
            />
            <FilterDrawer
              setQueryParameters={setQueryParameters}
              firstList={[
                { id: "no kycRequest", name: "no kycRequest" },
                { id: "pending", name: "pending" },
                { id: "approved", name: "approved" },
                { id: "rejected", name: "rejected" },
              ]}
              firstKey="kyc_status"
              firstTitle="KYC Status"
              secondTitle="Online Status"
              handleFirstCheckBox={(e) => {
                handleFilterByString(
                  e.target.value,
                  "kyc_status",
                  queryParameters,
                  setQueryParameters
                );
              }}
              isUsers={true}
            />
          </div>
          <button
            // onClick={() => downloadUsers()}
            className="rounded-xl bg-[#917244] text-white px-12 py-3 text-sm mt-4 w-[95%] md:w-fit hidden md:block   md:m-0 mr-10"
          >
            Download Report
          </button>
        </div>
        <div className="">
          <NewTable
            columns={deletedUsersColumns}
            isLoading={isFetching}
            data={data?.data?.result}
            totalItems={data?.data?.totalItems}
            totalPages={data?.data?.totalPages}
            setLimit={setQueryParameters}
            limit={queryParameters.limit}
            centerHeader
            handlePageChange={(newPage) =>
              handlePageChange(newPage, queryParameters, setQueryParameters)
            }
            handleRowClick={handleRowClick}
            currentPage={queryParameters}
          />
        </div>
        <div className="flex items-center justify-center m-2">
          <button
            // onClick={() => downloadUsers()}
            className="rounded-lg bg-[#917244] text-white px-4 py-2 text-sm mt-4 w-[90%] md:hidden mx-auto  md:m-0"
          >
            Download Report
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeletedUsers;
