import { useState } from "react";
import TextFieldComponent from "../../../components/TextFieldComponent";
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import CustomTextArea from "../../../components/CustomTextArea";
import { FirstTypography } from "../../../components/common/CustomTypogrphy";

const StepThreeTicketQuestions = ({
  setFaqData,
  faqData,
  type,
  subjectId,
  selectedTicketData,
  setIsValid,
}) => {
  const [newFaqPayload, setNewFaqPayload] = useState({
    local: "eg",
    question: "",
    answer: "",
  });
  useEffect(() => {
    const allSubQuestionsValid = faqData?.subQuestions?.every(
      (item) => item?.trans[2]?.question && item?.trans[2]?.answer
    );

    const isMainQuestionValid = faqData?.trans[2]?.question;
    const isMainAnswerValid = faqData?.trans[2]?.answer;

    if (isMainQuestionValid && type === "No" && isMainAnswerValid) {
      setIsValid([true, true, true]);
    } else if (isMainQuestionValid && type === "Yes" && allSubQuestionsValid) {
      setIsValid([true, true, true]);
    } else {
      setIsValid([true, true, false]);
    }
  }, [faqData, type, setIsValid]);
  const handleSubQuestionChange = (index, field, fieldType, value) => {
    setFaqData((prevFaqData) => ({
      ...prevFaqData,
      subQuestions: prevFaqData.subQuestions.map((subQuestion, i) => {
        if (i === index) {
          return {
            ...subQuestion,
            trans: subQuestion.trans.map((translation) => {
              if (translation.local === field) {
                return {
                  ...translation,
                  [fieldType]: value, // Update either 'question' or 'answer' field
                };
              }
              return translation;
            }),
          };
        }
        return subQuestion;
      }),
    }));
  };
  useEffect(() => {
    // Check if type is "No", then remove subQuestions array from faqData
    if (type === "No") {
      setFaqData((prevFaqData) => {
        const { subQuestions, ...updatedFaqData } = prevFaqData;
        return updatedFaqData;
      });
    }
  }, [type, setFaqData]);
  return (
    <Box sx={{ marginTop: "3%" }}>
      {type === "No" && (
        <Box>
          <TextFieldComponent
            sx={{ "& fieldset": { border: "none" } }}
            style={{
              height: "48px",
              width: "100%",
              backgroundColor: "#F7F7F7",
              borderRadius: "9px",
            }}
            name="Question (Egyptian Arabic)"
            placeholder="Enter Question (Egyptian Arabic)"
            value={faqData?.trans?.find((t) => t.local === "eg").question}
            onChange={(event) => {
              const disallowEnglishRegex = /^[^a-zA-Z]*$/;
              if (
                event.target.value === "" ||
                disallowEnglishRegex.test(event.target.value)
              ) {
                const updatedPayload = { ...faqData };
                const enTranslation = updatedPayload.trans.find(
                  (t) => t.local === "eg"
                );
                if (enTranslation) {
                  enTranslation.question = event.target.value;
                  setFaqData(updatedPayload);
                }
              }
            }}
          />

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <FirstTypography title={"Answer (Egyptian Arabic)"} />

            <CustomTextArea
              style={{
                minHeight: "80px",
                maxHeight: "200px",
                maxWidth: "100%",
                minWidth: "100%",
                backgroundColor: "#FFF",
                borderRadius: "9px",
                boxShadow: "none",
                border: "1px solid #917244",
              }}
              name="Answer"
              placeholder="Enter Answer"
              value={faqData.trans.find((t) => t.local === "eg").answer}
              onChange={(event) => {
                const disallowEnglishRegex = /^[^a-zA-Z]*$/;
                if (
                  event.target.value === "" ||
                  disallowEnglishRegex.test(event.target.value)
                ) {
                  const updatedPayload = { ...faqData };
                  const enTranslation = updatedPayload.trans.find(
                    (t) => t.local === "eg"
                  );
                  if (enTranslation) {
                    enTranslation.answer = event.target.value;
                    setFaqData(updatedPayload);
                  }
                }
              }}
            />
          </Box>
        </Box>
      )}
      {type === "Yes" && (
        <Box>
          <TextFieldComponent
            sx={{ "& fieldset": { border: "none" } }}
            style={{
              height: "48px",
              width: "100%",
              backgroundColor: "#F7F7F7",
              borderRadius: "9px",
            }}
            name="Question (Egyptian Arabic)"
            placeholder="Enter Question Question (Egyptian Arabic)"
            value={faqData.trans.find((t) => t.local === "eg").question}
            onChange={(event) => {
              const disallowEnglishRegex = /^[^a-zA-Z]*$/;
              if (
                event.target.value === "" ||
                disallowEnglishRegex.test(event.target.value)
              ) {
                const updatedPayload = { ...faqData };
                const enTranslation = updatedPayload.trans.find(
                  (t) => t.local === "eg"
                );
                if (enTranslation) {
                  enTranslation.question = event.target.value;
                  setFaqData(updatedPayload);
                }
              }
            }}
          />
          {!subjectId && (
            <Box>
              {faqData.subQuestions.map((subQuestion, index) => (
                <Box key={index}>
                  <TextFieldComponent
                    sx={{ "& fieldset": { border: "none" } }}
                    style={{
                      height: "48px",
                      width: "100%",
                      backgroundColor: "#F7F7F7",
                      borderRadius: "9px",
                    }}
                    name={`Sub Question ${index + 1} (Egyptian Arabic)`}
                    placeholder={`Enter Sub Question ${
                      index + 1
                    } (Egyptian Arabic)`}
                    value={
                      faqData.subQuestions[index].trans.find(
                        (t) => t.local === "eg"
                      ).question
                    }
                    onChange={(event) => {
                      const disallowEnglishRegex = /^[^a-zA-Z]*$/;
                      if (
                        event.target.value === "" ||
                        disallowEnglishRegex.test(event.target.value)
                      ) {
                        handleSubQuestionChange(
                          index,
                          "eg",
                          "question",
                          event.target.value
                        );
                      }
                    }}
                  />

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#4C4C4C",
                        marginBottom: "5px",
                        marginTop: "15px",
                      }}
                    >
                      Answer (Egyptian Arabic)
                    </Typography>
                    <CustomTextArea
                      style={{
                        minHeight: "80px",
                        maxHeight: "200px",
                        maxWidth: "100%",
                        minWidth: "100%",
                        backgroundColor: "#FFF",
                        borderRadius: "9px",
                        boxShadow: "none",
                        border: "1px solid #917244",
                      }}
                      name="Answer (Egyptian Arabic)"
                      placeholder="Enter Answer (Egyptian Arabic)"
                      value={
                        faqData.subQuestions[index].trans.find(
                          (t) => t.local === "eg"
                        ).answer
                      }
                      onChange={(event) => {
                        const disallowEnglishRegex = /^[^a-zA-Z]*$/;
                        if (
                          event.target.value === "" ||
                          disallowEnglishRegex.test(event.target.value)
                        ) {
                          handleSubQuestionChange(
                            index,
                            "eg",
                            "answer",
                            event.target.value
                          );
                        }
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          )}
          {subjectId && (
            <Box>
              {Array.from({
                length: selectedTicketData?.subQuestionsCount || 0,
              }).map((_, index) => (
                <Box key={index}>
                  <TextFieldComponent
                    sx={{ "& fieldset": { border: "none" } }}
                    style={{
                      height: "48px",
                      width: "100%",
                      backgroundColor: "#F7F7F7",
                      borderRadius: "9px",
                    }}
                    name={`Sub Question ${index + 1}`}
                    placeholder={`Enter Sub Question ${index + 1}`}
                    value={newFaqPayload.title}
                    onChange={(event) => {
                      var temppayload = { ...newFaqPayload };
                      temppayload.title = event.target.value;
                      setNewFaqPayload({ ...temppayload });
                    }}
                  />

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#4C4C4C",
                        marginBottom: "5px",
                        marginTop: "15px",
                      }}
                    >
                      Answer
                    </Typography>
                    <CustomTextArea
                      style={{
                        minHeight: "80px",
                        maxHeight: "200px",
                        maxWidth: "100%",
                        minWidth: "100%",
                        backgroundColor: "#FFF",
                        borderRadius: "9px",
                        boxShadow: "none",
                        border: "1px solid #917244",
                      }}
                      name="Answer"
                      placeholder="Enter Answer"
                      value={newFaqPayload.description}
                      onChange={(event) => {
                        var temppayload = { ...newFaqPayload };
                        temppayload.description = event.target.value;
                        setNewFaqPayload({ ...temppayload });
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
export default StepThreeTicketQuestions;
