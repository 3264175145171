import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { fetchPaymentInfo } from "../../services/apis/paymentInfo";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { DataGridStyle } from "../../styles";
import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";
import NewTable from "../../components/common/NewTable";
import editWhite from "../../svg/edit-white.svg";
import NewPaymentInfoDialog from "./NewPaymentInfoDialog";
const PaymentInfo = () => {
  const [newData, setNewData] = useState([{ data: "", value: "" }]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleResponse = async () => {
    try {
      setLoading(true);
      const response = await fetchPaymentInfo();
      const data = await response?.data?.data;

      setNewData([
        { data: "Company name", value: data[2]?.meta?.companyName },
        { data: "Company address", value: data[2]?.meta?.companyAddress },
        { data: "Bank name", value: data[2]?.meta?.bankName },
        { data: "Account number", value: data[2]?.meta?.accountNumber },
        { data: "IBAN", value: data[2]?.meta?.IBAN },
        { data: "SWIFT Code", value: data[2]?.meta?.swiftCode },
      ]);
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const bankPaymentColumns = ItemsColumns([
    {
      field: "data",
    },
    {
      field: "value",
    },
  ]);

  useEffect(() => {
    handleResponse();
  }, []);
  return (
    <Box
      style={{ overflowY: "auto", maxHeight: "100vh" }}
      sx={{
        "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#FFF",
          borderBottom: "none",
          pointerEvents: "none",
        },
      }}
    >
      <DashboardMainHeader />
      <Header title="Payment Information" marginB={"30px"} marginL={"20px"} />

      <button
        className="text-white bg-[#917244] flex items-center gap-1 px-5 py-2 rounded-lg ml-auto mx-6"
        onClick={() => {
          setOpenModal(true);
        }}
      >
        <img src={editWhite} alt="edit" />
        <p>Edit Data</p>
      </button>
      <NewPaymentInfoDialog
        open={openModal}
        setOpen={setOpenModal}
        newData={newData}
        fetchData={handleResponse}
      />
      <SecondBox>
        <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
          <NewTable
            columns={bankPaymentColumns}
            isLoading={loading}
            data={newData}
            noPagination
          />
        </Box>
      </SecondBox>
    </Box>
  );
};
export default PaymentInfo;
