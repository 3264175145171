import { useState } from "react";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { DataGridStyle } from "../../styles";
import ActionAlert from "../user-profile/ActionAlert";
import CustomDialog from "../../components/CustomDialog";
import { useDeleteBank, useFetchBanks } from "../../services/apis/banks";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { handlePageChange } from "../../services/utils/filters";
import AddBankDialog from "./AddBankDialog";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import AddButton from "../../components/common/AddButton";
import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";
import NewTable from "../../components/common/NewTable";
const Banks = () => {
  const [disableWhileDelete, setDisableWhileDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [openDeletedAlert, setOpenDeletedAlert] = useState(false);
  const [functypeparams, setFuncTypeParams] = useState(null);
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
  });
  const { data: banks, isLoading, refetch } = useFetchBanks(queryParameters);
  const deleteBankMutation = useDeleteBank();
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddBank = () => {
    setOpen(true);
    setFuncTypeParams("add");
    setSelectedBankId(null);
  };
  const handleOpenDeleteAlert = (id) => {
    setSelectedBankId(id);
    setOpenDeletedAlert(true);
  };
  const handleEditBank = (bankId) => {
    setSelectedBankId(bankId);
    setOpen(true);
    setFuncTypeParams("edit");
  };
  const handleDeleteBank = async () => {
    if (selectedBankId) {
      setDisableWhileDelete(true);
      try {
        await deleteBankMutation.mutateAsync(selectedBankId);
        setDisableWhileDelete(false);
        setSuccessDialogOpen(true);
        handleCloseDeleteAlert();
      } catch (error) {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      } finally {
        setDisableWhileDelete(false);
      }
    }
  };
  const handleCloseDeleteAlert = () => {
    setOpenDeletedAlert(false);
  };
  const bankColumns = ItemsColumns([
    { field: "id", headerName: "ID" },
    { field: "name_en", headerName: "Bank Name - English" },
    { field: "name_ar", headerName: "Bank Name - Arabic" },
    {
      field: "action",
      headerName: "Actions",
      actions: {
        edit: (params) => handleEditBank(params.row.id),
        delete: (params) => handleOpenDeleteAlert(params.row.id),
      },
    },
  ]);

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Banks" marginL={"25px"} />
      <SecondBox>
        <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              gap: 2,
              pr: 2,
              pt: 2,
            }}
          >
            <AddButton
              title={"+ New Bank"}
              secondDesign={true}
              onClick={() => {
                handleAddBank();
              }}
            />
          </Box>
          <NewTable
            columns={bankColumns}
            isLoading={isLoading}
            data={banks?.result}
            totalPages={banks?.totalPages}
            totalItems={banks?.totalItems}
            handlePageChange={(newPage) =>
              handlePageChange(newPage, queryParameters, setQueryParameters)
            }
            currentPage={queryParameters}
          />
        </Box>
      </SecondBox>
      <ActionAlert
        openAlert={openDeletedAlert}
        handleCloseAlert={handleCloseDeleteAlert}
        handleReject={handleDeleteBank}
        message={`Are you sure you want to Delete this Bank ?`}
        label={"Delete"}
        disabled={disableWhileDelete}
      />
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Bank has been deleted Successfully."
        message=""
        buttonText="Go to Banks"
      />
      <AddBankDialog
        functypeparams={functypeparams}
        queryParameters={queryParameters}
        handleResponse={() => {
          refetch(queryParameters);
        }}
        banks={banks?.result}
        bankId={selectedBankId}
        open={open}
        onClose={handleClose}
        label={functypeparams === "add" ? "Add" : "Save"}
      />
      <ReusableSnackbar />
    </Box>
  );
};

export default Banks;
