import { Skeleton, Tooltip } from "@mui/material";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import infoCircle from "../../svg/info-circle.svg";
import { ItemsColumns } from "../../components/common/Columns";
import NewTable from "../../components/common/NewTable";
import { setData } from "../../store/slices/errorMessageSlice";
import {
  useGetCompetitors,
  useGetGoldPrice,
  useUpdateGoldPrice,
} from "../../services/apis/liveGoldPrice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import greenArrow from "../../svg/arrow-up-green.svg";
import greenArrow1 from "../../svg/arrow-up-green1.svg";
import redArrow from "../../svg/arrow-down-red.svg";
import { useGlobalSocket } from "../../useGlobalSocket";
const LiveGoldPrice = () => {
  const dispatch = useDispatch();
  const {
    data,
    refetch,
    isLoading: goldPriceLoading,
    isFetching,
  } = useGetGoldPrice();
  const { mutateAsync } = useUpdateGoldPrice();
  const { data: goldCompetitors } = useGetCompetitors({ page: 0 });
  const [isDisabled, setIsDisabled] = useState(false);
  const [competitors, setCompetitors] = useState(null);

  const [gold, setGold] = useState({});
  const [goldUpdate, setGoldUpdate] = useState({});
  useEffect(() => {
    if (data && !isFetching) {
      setGold((prevGold) => ({
        ...prevGold,
        ...data,
        updateType: data?.mode === "V" ? "value" : "percentage",
      }));
    }
    if (!goldPriceLoading) {
      data?.mode === "V"
        ? setGoldUpdate({
            buyPerV: data?.buyPer,
            sellPerV: data?.sellPer,
            buyPerP: goldUpdate?.buyPerP || 0,
            sellPerP: goldUpdate?.sellPerP || 0,
          })
        : setGoldUpdate({
            buyPerV: goldUpdate?.buyPerV || 0,
            sellPerV: goldUpdate?.sellPerV || 0,
            buyPerP: data?.buyPer,
            sellPerP: data?.sellPer,
          });
    }
  }, [isFetching, data, goldPriceLoading]);
  const { addEventListener, removeEventListener } = useGlobalSocket();
  useEffect(() => {
    const competitorsListener = (data) => {
      setCompetitors(data?.competitors);
    };

    const buyUpdateListener = (data) => {
      setGold((prevGold) => ({
        ...prevGold,
        DateOfRecordBuy: data?.DateOfRecord,
        buy_gold_24: data?.buy,
        buyDiff: data?.buyDiff,
        buyPercentage: data?.buyPercentage,
      }));
    };

    const sellUpdateListener = (data) => {
      setGold((prevGold) => ({
        ...prevGold,
        DateOfRecordSell: data?.DateOfRecord,
        sell_gold_24: data?.sell,
        sellDiff: data?.sellDiff,
        sellPercentage: data?.sellPercentage,
      }));
    };

    addEventListener("goldPrice-competitors", competitorsListener);
    addEventListener("goldPrice-update-buy", buyUpdateListener);
    addEventListener("goldPrice-update-sell", sellUpdateListener);

    return () => {
      removeEventListener("goldPrice-competitors", competitorsListener);
      removeEventListener("goldPrice-update-buy", buyUpdateListener);
      removeEventListener("goldPrice-update-sell", sellUpdateListener);
    };
  }, [addEventListener, removeEventListener]);

  const LiveGoldPriceColumns = ItemsColumns([
    { headerName: "Competitor Name", field: "provider" },
    {
      headerName: "Buy Price (EGP) per Gram",
      field: "buy_gold_24",
      specialRender: "customRender",
      renderFunction: (params) =>
        params?.row?.buyPercentage > 0 ? (
          <div className="flex items-center gap-2">
            <img src={greenArrow} alt="green" />
            {Number(params.row?.buy_gold_24)?.toLocaleString()}
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <img src={redArrow} alt="red" />
            {Number(params.row?.buy_gold_24)?.toLocaleString()}
          </div>
        ),
    },
    {
      headerName: "Sell Price (EGP) per Gram",
      field: "sell_gold_24",
      specialRender: "customRender",
      renderFunction: (params) =>
        params?.row?.sellPercentage > 0 ? (
          <div className="flex items-center gap-2">
            <img src={greenArrow} alt="green" />
            {Number(params.row?.sell_gold_24)?.toLocaleString()}
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <img src={redArrow} alt="red" />
            {Number(params.row?.sell_gold_24)?.toLocaleString()}
          </div>
        ),
    },
    { headerName: "Last Update", field: "date", date: true },
  ]);

  return (
    <div className="mx-6">
      <DashboardMainHeader />
      <Header title={"Live Gold Price"} />

      {/* Gold Price Cards */}
      <div className="grid md:grid-cols-2 items-center mt-8 gap-8">
        {/* Buy Price Card */}
        <div
          className="w-full rounded-[20px] bg-white"
          style={{
            boxShadow:
              "0px 1px 3px rgba(0, 0, 0, 0.02), 0px 1px 6px rgba(0, 0, 0, 0.07)",
          }}
        >
          <div className="flex flex-col p-6">
            <div className="flex items-center justify-between">
              <h1 className="text-[#595959] text-xs">Live Buy Gold (Sabika)</h1>
              <Tooltip title="Gold" placement="top" className="ml-auto">
                <img src={infoCircle} alt="info" className="cursor-pointer" />
              </Tooltip>
            </div>
            <div className="flex items-center justify-between mt-4">
              <div className={`text-[#917244] text-2xl font-semibold`}>
                {gold?.buy_gold_24 ? (
                  Number(gold?.buy_gold_24)?.toLocaleString()
                ) : (
                  <Skeleton width={200} />
                )}{" "}
                <span className="text-[#595959] text-[0.625rem]">EGP</span>
              </div>
              <p
                className={`
               ${
                 gold?.buyPercentage > 0
                   ? "text-[#28A745] bg-[#28A7451A]"
                   : "text-[#DC3545] bg-[#DC35451A]"
               } rounded-[4px]  text-[0.8rem] flex p-1
                `}
              >
                {gold?.buyPercentage}
                %
                <img
                  src={gold?.buyPercentage > 0 ? greenArrow1 : redArrow}
                  alt="arrow up"
                />
              </p>
            </div>
          </div>
        </div>

        {/* Sell Price Card */}
        <div
          className="w-full rounded-[20px] bg-white"
          style={{
            boxShadow:
              "0px 1px 3px rgba(0, 0, 0, 0.02), 0px 1px 6px rgba(0, 0, 0, 0.07)",
          }}
        >
          <div className="flex flex-col p-6">
            <div className="flex items-center justify-between">
              <h1 className="text-[#595959] text-xs">
                Live Sell Gold (Sabika)
              </h1>
              <Tooltip title="Gold" placement="top" className="ml-auto">
                <img src={infoCircle} alt="info" className="cursor-pointer" />
              </Tooltip>
            </div>
            <div className="flex items-center justify-between mt-4">
              <div className="text-[#917244] text-2xl font-semibold">
                {gold?.sell_gold_24 ? (
                  Number(gold?.sell_gold_24)?.toLocaleString()
                ) : (
                  <Skeleton width={200} />
                )}{" "}
                <span className="text-[#595959] text-[0.625rem]">EGP</span>
              </div>
              <p
                className={`
                rounded-[4px] text-[0.8rem] flex p-1
              ${
                gold?.sellPercentage > 0
                  ? "text-[#28A745] bg-[#28A7451A]"
                  : "text-[#DC3545] bg-[#DC35451A]"
              }
              `}
              >
                {gold?.sellPercentage}
                %
                <img
                  src={gold?.sellPercentage > 0 ? greenArrow : redArrow}
                  alt="arrow up"
                />
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Update Prices Form */}
      <div
        className="mt-4 mb-8 p-6 rounded-[0.625rem] bg-white"
        style={{
          boxShadow:
            "0px 1px 3px rgba(0, 0, 0, 0.02), 0px 1px 6px rgba(0, 0, 0, 0.07)",
        }}
      >
        <p className="text-[#595959] mb-4 text-lg">Update Prices</p>
        {/* Radio Button for value or percentage */}
        <div className="mb-6">
          <div className="flex gap-4">
            <label className="flex items-center gap-2 cursor-pointer">
              <input
                type="radio"
                name="updateType"
                value="value"
                checked={gold?.updateType === "value"}
                onChange={(e) =>
                  setGold((prev) => ({ ...prev, updateType: e.target.value }))
                }
                className="hidden peer cursor-pointer"
              />
              <span className="w-5 h-5 flex items-center justify-center border-2 border-gray-400 rounded-full peer-checked:border-[#917244] peer-checked:after:content-[''] peer-checked:after:w-3 peer-checked:after:h-3 peer-checked:after:rounded-full peer-checked:after:bg-[#917244]"></span>
              <span className="text-gray-600">Value</span>
            </label>
            <label className="flex items-center gap-2 cursor-pointer">
              <input
                type="radio"
                name="updateType"
                value="percentage"
                checked={gold?.updateType === "percentage"}
                onChange={(e) =>
                  setGold((prev) => ({ ...prev, updateType: e.target.value }))
                }
                className="hidden peer cursor-pointer"
              />
              <span className="w-5 h-5 flex items-center justify-center border-2 border-gray-400 rounded-full peer-checked:border-[#917244] peer-checked:after:content-[''] peer-checked:after:w-3 peer-checked:after:h-3 peer-checked:after:rounded-full peer-checked:after:bg-[#917244]"></span>
              <span className="text-gray-600">Percentage</span>
            </label>
          </div>
        </div>

        <div className="grid md:grid-cols-2 items-center gap-8">
          {/* Buy Price Input */}
          <div className="w-full">
            <p className="mb-3">New Buy Price</p>
            <input
              min={
                gold.updateType !== "value" ? -15 : -(gold?.buy_gold_24 * 0.15)
              }
              max={gold.updateType !== "value" ? 15 : gold?.buy_gold_24 * 0.15}
              value={
                gold.updateType === "value"
                  ? goldUpdate?.buyPerV
                  : goldUpdate?.buyPerP
              }
              onChange={(e) => {
                let value = e.target.value;
                if (!/^-?\d*\.?\d*$/.test(value)) {
                  return;
                }
                // Allow an empty input or just a minus sign
                if (value === "" || value === "-") {
                  gold.updateType === "value"
                    ? setGoldUpdate((prev) => ({ ...prev, buyPerV: value }))
                    : setGoldUpdate((prev) => ({ ...prev, buyPerP: value }));

                  return;
                }

                const parsedValue = parseFloat(value);

                // Define the min and max values based on the updateType
                let minValue, maxValue, errorMessage;
                if (gold.updateType !== "value") {
                  minValue = -15;
                  maxValue = 15;
                  errorMessage = `Buy Price   should be between ${
                    Math.floor(minValue * 100) / 100
                  } and ${Math.floor(maxValue * 100) / 100}`;
                } else {
                  minValue =
                    Math.floor(-(gold?.buy_gold_24 * 0.15) * 100) / 100;
                  maxValue = Math.floor(gold?.buy_gold_24 * 0.15 * 100) / 100;
                  errorMessage = `Buy Price   should be between ${
                    Math.floor(minValue * 100) / 100
                  } and ${Math.floor(maxValue * 100) / 100}`;
                }

                // Check if the value is within the valid range
                if (parsedValue < minValue || parsedValue > maxValue) {
                  // Dispatch error message if the value is out of range
                  dispatch(
                    setData({
                      severity: "error",
                      openSnack: true,
                      message: errorMessage,
                    })
                  );
                } else {
                  gold.updateType === "value"
                    ? setGoldUpdate((prev) => ({ ...prev, buyPerV: value }))
                    : setGoldUpdate((prev) => ({ ...prev, buyPerP: value }));
                }
              }}
              className="w-full p-3 rounded-xl border border-[#e6e6e6] placeholder-[#e6e6e6]"
              placeholder="Enter New Buy Price"
              type="text" // Use 'text' to handle custom decimal validation and initial '-' sign
              style={{
                boxShadow:
                  "0px 1px 3px rgba(0, 0, 0, 0.02), 0px 1px 6px rgba(0, 0, 0, 0.07)",
              }}
            />
          </div>

          {/* Sell Price Input */}
          <div className="w-full">
            <p className="mb-3">New Sell Price</p>
            {/* <input
              min={
                gold.updateType !== "value" ? -15 : -(gold?.sell_gold_24 * 0.15)
              }
              max={gold.updateType !== "value" ? 15 : gold?.sell_gold_24 * 0.15}
              value={gold?.sellPer}
              onChange={(e) => {
                let value = e.target.value;

                // Allow an empty input or just a minus sign
                if (value === "" || value === "-") {
                  setGold((prev) => ({ ...prev, sellPer: value }));
                  return;
                }

                // Check if the input is a valid number within range with up to two decimals
                if (/^-?(15|[0-9]{1,2})(\.\d{0,2})?$/.test(value)) {
                  const parsedValue = parseFloat(value);

                  // Ensure parsed value falls within the allowed range
                  if (parsedValue >= -15 && parsedValue <= 15) {
                    setGold((prev) => ({
                      ...prev,
                      sellPer: value, // Keep value as string to allow precise input control
                    }));
                  }
                }
              }}  
              className="w-full p-3 rounded-xl border border-[#e6e6e6] placeholder-[#e6e6e6]"
              placeholder="Enter New Buy Price"
              type="text" // Use 'text' to handle custom decimal validation and initial '-' sign
              style={{
                boxShadow:
                  "0px 1px 3px rgba(0, 0, 0, 0.02), 0px 1px 6px rgba(0, 0, 0, 0.07)",
              }}
            /> */}
            <input
              min={
                gold.updateType !== "value" ? -15 : -(gold?.sell_gold_24 * 0.15)
              }
              max={gold.updateType !== "value" ? 15 : gold?.sell_gold_24 * 0.15}
              value={
                gold.updateType === "value"
                  ? goldUpdate?.sellPerV
                  : goldUpdate?.sellPerP
              }
              onChange={(e) => {
                let value = e.target.value;
                if (!/^-?\d*\.?\d*$/.test(value)) {
                  return;
                }
                // Allow an empty input or just a minus sign
                if (value === "" || value === "-") {
                  gold.updateType === "value"
                    ? setGoldUpdate((prev) => ({ ...prev, sellPerV: value }))
                    : setGoldUpdate((prev) => ({ ...prev, sellPerP: value }));
                  return;
                }

                const parsedValue = parseFloat(value);

                // Define the min and max values based on the updateType
                let minValue, maxValue, errorMessage;
                if (gold.updateType !== "value") {
                  minValue = -15;
                  maxValue = 15;
                  errorMessage = `  Sell Price should be between ${
                    Math.floor(minValue * 100) / 100
                  } and ${Math.floor(maxValue * 100) / 100}`;
                } else {
                  minValue =
                    Math.floor(-(gold?.sell_gold_24 * 0.15) * 100) / 100;
                  maxValue = Math.floor(gold?.sell_gold_24 * 0.15 * 100) / 100;
                  errorMessage = `  Sell Price should be between ${
                    Math.floor(minValue * 100) / 100
                  } and ${Math.floor(maxValue * 100) / 100}`;
                }

                // Check if the value is within the valid range
                if (parsedValue < minValue || parsedValue > maxValue) {
                  // Dispatch error message if the value is out of range
                  dispatch(
                    setData({
                      severity: "error",
                      openSnack: true,
                      message: errorMessage,
                    })
                  );
                } else {
                  // Update state if the value is valid
                  gold.updateType === "value"
                    ? setGoldUpdate((prev) => ({ ...prev, sellPerV: value }))
                    : setGoldUpdate((prev) => ({ ...prev, sellPerP: value }));
                }
              }}
              className="w-full p-3 rounded-xl border border-[#e6e6e6] placeholder-[#e6e6e6]"
              placeholder="Enter New Sell Price"
              type="text" // Use 'text' to handle custom decimal validation and initial '-' sign
              style={{
                boxShadow:
                  "0px 1px 3px rgba(0, 0, 0, 0.02), 0px 1px 6px rgba(0, 0, 0, 0.07)",
              }}
            />
          </div>
        </div>

        {/* Update Button */}
        <button
          disabled={isFetching || isDisabled || !gold?.updateType}
          onClick={async () => {
            setIsDisabled(true); // Disable the button
            setTimeout(() => setIsDisabled(false), 5000); // Enable it after 5 seconds
            // Uncomment for mutation
            await mutateAsync({
              mode: gold?.updateType === "value" ? "V" : "P",
              buychange:
                gold.updateType === "value"
                  ? goldUpdate.buyPerV
                  : goldUpdate.buyPerP,
              sellchange:
                gold.updateType === "value"
                  ? goldUpdate.sellPerV
                  : goldUpdate.sellPerP,
            })
              .then(() => {
                refetch();
                dispatch(
                  setData({
                    severity: "success",
                    openSnack: true,
                    message: "Prices updated successfully!",
                  })
                );
              })
              .catch((error) => {
                dispatch(
                  setData({
                    severity: "error",
                    openSnack: true,
                    message: error.response.data.message,
                  })
                );
              });
          }}
          className="text-white bg-[#917244] font-medium px-12 py-[0.906rem] rounded-xl mt-8 md:ml-auto flex disabled:opacity-50"
        >
          Update Price
        </button>
      </div>

      <NewTable
        className="mb-10"
        columns={LiveGoldPriceColumns}
        isLoading={goldPriceLoading}
        data={competitors ? competitors : goldCompetitors}
        noPagination
      />
      <ReusableSnackbar />
    </div>
  );
};

export default LiveGoldPrice;
