import { Box, Button, Step, StepLabel, Stepper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import { useState } from "react";

import CustomDialog from "../../components/CustomDialog";
import StepOneNotification from "./StepOneNotification";
import StepTwoNotification from "./StepTwoNotification";
import StepThreeNotification from "./StepThreeNotification";
import {
  sendSegmentedNotifications,
  useNotificationsCities,
} from "../../services/apis/notifications";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";

const steps = [
  "Enter Notification Details",
  "Choose User Segmentation",
  "Finish",
];
const AddNewNotification = () => {
  const useStyles = makeStyles(() => ({
    root: {
      "& .MuiStepIcon-active": { color: "#917244 !important" },
      "& .MuiStepIcon-completed": { color: "#4C4C4C !important" },
      "& .Mui-disabled .MuiStepIcon-root": { color: "#B3B3B3 !important" },
      "& .MuiStepLabel-root .Mui-active": {
        color: "#917244",
      },
      "& .MuiStepLabel-root .Mui-completed": {
        color: "#917244",
      },
    },
  }));

  const c = useStyles();
  const { data: cities } = useNotificationsCities();
  const [activeStep, setActiveStep] = useState(0);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    age: null,
    age_from: null,
    age_to: null,
    gender: null,
    city_id: [],
    area_id: [],
    trans: [
      {
        local: "en",
        title: "",
        message: "",
      },
      {
        local: "ar",
        title: "",
        message: "",
      },
      {
        local: "eg",
        title: "",
        message: "",
      },
    ],
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const dispatch = useDispatch();

  const handleSave = async () => {
    try {
      setIsSubmitting(true);
      const sentData = {
        ...formData,
        age: formData?.age === "" ? null : formData?.age,
        age_from: formData?.age_from === "" ? null : formData?.age_from,
        age_to: formData?.age_to === "" ? null : formData?.age_to,
      };
      await sendSegmentedNotifications(sentData);
      setSuccessDialogOpen(true);
    } catch (error) {
      if (error.response.status === 422) {
        dispatch(
          setData({
            openSnack: true,
            message: "Age To Must be greater than age from",
          })
        );
      } else {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <StepOneNotification formData={formData} setFormData={setFormData} />
        );
      case 1:
        return (
          <StepTwoNotification formData={formData} setFormData={setFormData} />
        );
      case 2:
        return (
          <StepThreeNotification
            formData={formData}
            setFormData={setFormData}
            cities={cities?.data?.data}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <Box sx={{ mb: "3%" }}>
          <DashboardMainHeader />
        </Box>

        <Header title="Add Notification" />
      </Box>
      <Box
        m="20px"
        sx={{
          height: "auto",
          background: "#FFFFFF",
          borderRadius: "12px",
          boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08)",
          overflow: "auto",
          padding: "4%",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Stepper className={c.root} activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <>
            {renderStepContent(activeStep)}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              {activeStep !== 0 && (
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#ffffff",
                    },
                    backgroundColor: "#ffffff",
                    width: "200px",
                    height: "46px",
                    borderRadius: "12px",
                    color: "#917244",
                    border: "3px solid #917244",
                    fontSize: "14px",
                    marginRight: "20px",
                  }}
                >
                  Back
                </Button>
              )}
              <Button
                disabled={
                  activeStep === 0
                    ? !formData.trans[0]?.title ||
                      !formData.trans[1]?.title ||
                      !formData.trans[2]?.title ||
                      !formData.trans[0]?.message ||
                      !formData.trans[1]?.message ||
                      !formData.trans[2]?.message
                    : isSubmitting
                }
                sx={{
                  "&:hover": { backgroundColor: "#917244" },
                  backgroundColor: "#917244",
                  width: "200px",
                  height: "46px",
                  borderRadius: "12px",
                  color: "#ffffff",

                  fontSize: "14px",
                }}
                onClick={
                  activeStep === steps.length - 1 ? handleSave : handleNext
                }
              >
                {activeStep === steps.length - 1 ? "Save" : "Next"}
              </Button>
            </Box>
          </>
        </Box>
      </Box>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Notification has been added Successfully."
        message=""
        buttonText="Go to Notifications"
        buttonLink="/notifications"
      />
      <ReusableSnackbar />
    </Box>
  );
};

export default AddNewNotification;
