import React from "react";
import {
  Button,
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  Select,
  MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import { useEffect } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import CustomDialog from "../../components/CustomDialog";
import { mergeFractions } from "../../services/apis/inventory";
import { useMutation, useQueryClient } from "react-query";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { MenuProps } from "../../components/common/MenuProps";

const MergeFractionDialog = ({ open, label, onClose, data, row, refetch }) => {
  const dispatch = useDispatch();

  const [selectedFraction, setSelectedFraction] = useState(null);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [mergeFractionPayload, setMergeFractionPayload] = useState({
    serial_from: row?.id || "",
    serial_to: "",
  });
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedFraction(selectedValue);
    setMergeFractionPayload((prevPayload) => ({
      ...prevPayload,
      serial_to: selectedValue?.id,
    }));
  };
  const handleClose = () => {
    setSelectedFraction(null);
    onClose();
  };
  const queryClient = useQueryClient();
  const mutation = useMutation(mergeFractions, {
    onSuccess: () => {
      setSuccessDialogOpen(true);
      refetch();
      queryClient.invalidateQueries("fractions");
      handleClose();
    },
    onError: (error) => {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    },
  });
  const handleSubmit = async () => {
    mutation.mutate(mergeFractionPayload);
  };
  const filteredData = data?.result?.filter(
    (item) =>
      item?.serial !== row?.serial && item?.currentWeight > row?.soldWeight
  );

  useEffect(() => {
    setMergeFractionPayload((prevPayload) => ({
      ...prevPayload,
      serial_from: row?.id || "",
    }));
  }, [row, data]);
  return (
    <>
      <Dialog
        maxWidth="662px"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "662px",
            height: "auto",
            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            paddingTop: "5%",
            paddingBottom: "8%",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              width: "80%",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={handleClose}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  color: "#000000",
                  fontWeight: "400",
                  fontSize: "24px",
                  display: "flex",
                  alignSelf: "flex-start",
                }}
              >
                <Typography sx={{ color: "#4C4C4C", fontSize: "24px" }}>
                  Merge Fractions Ingot
                </Typography>
              </Box>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#4C4C4C",
                  marginBottom: "5px",
                  marginTop: "10px",
                }}
              >
                Select Serial to transfer Weight to
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  displayEmpty
                  value={selectedFraction || ""}
                  onChange={handleChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return <em>Select Serial to transfer Weight to</em>;
                    }
                    return `${selected?.serial} - ${selected?.currentWeight} Gm`;
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {filteredData?.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      {`${item.serial} - ${item.currentWeight} Gm`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: "flex", gap: 2, marginTop: "10px" }}>
              <Button
                disabled={!selectedFraction || mutation.isLoading}
                variant="contained"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  background: "#917244",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#917244",
                  },
                }}
                onClick={handleSubmit}
              >
                {label}
              </Button>

              <Button
                variant="outlined"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#917244",
                  fontWeight: 600,
                  borderColor: "#917244",
                  "&:hover": {
                    borderColor: "#917244",
                  },
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Fraction has been Merged Successfully."
        message=""
        buttonText="Go to Sell Fractions Ingots"
      />
      <ReusableSnackbar />
    </>
  );
};

export default MergeFractionDialog;
