import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import useWindowSize from "../hooks/useWindowSize";

function UploadImage({ setImage, setFile, image, handleChange }) {
  const { width } = useWindowSize();
  const [inputKey] = useState(0);
  const fileInputRef = useRef();

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setFile(file);

    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileInputChange = (event) => {
    handleChange(event);
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      {!image && (
        <div
          onClick={(e) => {
            if (width < 768) {
              handleUploadButtonClick(e);
            }
          }}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          className="mt-1 w-full h-14 border-2 border-dashed border-[#ccc] flex items-center justify-center relative rounded-xl bg-[#cccccc4c] "
        >
          <img src={`../../assets/export.png`} alt="" />
          <div className="md:flex">
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileInputChange}
            />

            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileInputChange}
              key={inputKey}
            />
            {!image && (
              <div className="hidden md:block">
                <Button
                  sx={{
                    color: "#917244",
                    textDecoration: "underLine",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                  onClick={handleUploadButtonClick}
                >
                  click to upload
                </Button>
              </div>
            )}
            {image ? (
              <img src={image} alt="Dropped" height="152px" width="100%" />
            ) : (
              <p className="hidden md:block text-[#808080] text-xs mt-[0.65rem]">
                or drag and drop
              </p>
            )}
          </div>
          <p className="text-[#cccccc] text-xs ml-2 ">
            SVG, PNG, JPG or GIF (max. 3MB)
          </p>
        </div>
      )}
    </>
  );
}

export default UploadImage;
