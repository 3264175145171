export const allowedExtensions = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "bmp",
  "svg",
  "webp",
  "tiff",
  "ico",
  "heic",
  "heif",
  "avif",
];
export const maxFileSize = 20 * 1024 * 1024; // 20MB in bytes
