// import { useEffect, useState } from "react";
// import { Box } from "@mui/material";
// import Header from "../../components/Header";
// import { fetchTerms, deleteTerm } from "../../services/apis/terms";
// import { DataGridStyle } from "../../styles";
// import { useNavigate } from "react-router-dom";
// import ActionAlert from "../user-profile/ActionAlert";
// import CustomDialog from "../../components/CustomDialog";
// import EditTerm from "./EditTerm";
// import DashboardMainHeader from "../../components/DashboardMainHeader";
// import ReusableSnackbar from "../../components/ReusableSnackbar";
// import { useDispatch } from "react-redux";
// import { setData } from "../../store/slices/errorMessageSlice";
// import AddButton from "../../components/common/AddButton";
// import { ItemsColumns } from "../../components/common/Columns";
// import NewTable from "../../components/common/NewTable";
// const Terms = () => {
//   const [disableWhileDelete, setDisableWhileDelete] = useState(false);

//   const [open, setOpen] = useState(false);
//   const [loading, setLoading] = useState(true);
//   const [terms, setTerms] = useState([]);
//   const [selectedTermId, setSelectedTermId] = useState(null);
//   const [selectedTerm, setSelectedTerm] = useState(null);
//   const [successDialogOpen, setSuccessDialogOpen] = useState(false);
//   const [openDeletedAlert, setOpenDeletedAlert] = useState(false);
//   const dispatch = useDispatch();

//   const navigate = useNavigate();
//   const getTerms = async () => {
//     setLoading(true);
//     setTerms([]);
//     try {
//       const response = await fetchTerms();
//       setTerms(response.data.data);
//       setLoading(false);
//     } catch (error) {
//       console.log(error.response.data.message);
//     } finally {
//       setLoading(false);
//     }
//   };
//   const handleDeleteTerm = async () => {
//     if (selectedTermId) {
//       setDisableWhileDelete(true);
//       try {
//         await deleteTerm(selectedTermId);
//         setDisableWhileDelete(false);
//         setSuccessDialogOpen(true);
//         handleCloseDeleteAlert();
//         getTerms();
//       } catch (error) {
//         dispatch(
//           setData({
//             openSnack: true,
//             message: error.response.data.message,
//           })
//         );
//       }
//     }
//   };
//   const handleEditTerm = (termId) => {
//     setSelectedTerm(termId);
//     setOpen(true);
//   };
//   const handleCloseDeleteAlert = () => {
//     setOpenDeletedAlert(false);
//   };
//   const handleOpenDeleteAlert = (id) => {
//     setSelectedTermId(id);
//     setOpenDeletedAlert(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//   };
//   const termsColumns = ItemsColumns([
//     {
//       field: "id",
//       headerName: "Order",
//     },
//     {
//       field: "title_en",
//       headerName: "Title",
//       flex: 2,
//     },
//     {
//       field: "description_en",
//       headerName: "Text",
//       flex: 2,
//       specialRender: "customRender",
//       renderFunction: (params) => {
//         return (
//           <p>
//             {params?.row?.description_en?.length > 100
//               ? `${params.row.description_en.substring(0, 100)}...`
//               : params.row.description_en}
//           </p>
//         );
//       },
//     },
//     {
//       field: "action",
//       headerName: "Actions",
//       actions: {
//         edit: (params) => handleEditTerm(params.row.id),
//         delete: (params) => handleOpenDeleteAlert(params.row.id),
//       },
//     },
//   ]);

//   const handleAddTerms = () => {
//     navigate(`/AddNewTerms`);
//   };
//   useEffect(() => {
//     getTerms();
//   }, []);
//   return (
//     <>
//       <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
//         <DashboardMainHeader />

//         <Header title="Terms and Conditions" marginL={"25px"} />
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "end",
//             width: "100%",
//             gap: 2,
//             pr: 2,
//           }}
//         >
//           <AddButton
//             width="250px"
//             title={"+ New Terms & Conditions"}
//             onClick={handleAddTerms}
//           />
//         </Box>
//         <Box
//           m="20px"
//           sx={{
//             height: "auto",
//             background: "#FFF",
//             borderRadius: "12px",
//             boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08);",
//           }}
//         >
//           <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
//             <NewTable
//               columns={termsColumns}
//               isLoading={loading}
//               data={terms}
//               noPagination
//             />
//             {/* <DataGrid
//               rows={terms}
//               columns={termsColumns}
//               loading={loading}
//               autoHeight={true}
//               components={{
//                 Pagination: () => null,
//                 Footer: () => null,
//                 LoadingOverlay: () =>
//                   CustomLoadingOverlay({ columns: 4, rows: 2 }),
//               }}
//               localeText={{
//                 noRowsLabel: "No Terms and Conditions",
//               }}
//             /> */}
//           </Box>
//         </Box>
//         <ActionAlert
//           openAlert={openDeletedAlert}
//           handleCloseAlert={handleCloseDeleteAlert}
//           handleReject={handleDeleteTerm}
//           message={`Are you sure you want to Delete this Term ?`}
//           label={"Delete"}
//           disabled={disableWhileDelete}
//         />
//         <CustomDialog
//           open={successDialogOpen}
//           onClose={() => setSuccessDialogOpen(false)}
//           title="Term has been deleted Successfully."
//           message=""
//           buttonText="Go to Terms and Conditions"
//         />
//         <EditTerm
//           fetchData={fetchTerms}
//           setTerms={setTerms}
//           terms={terms}
//           termId={selectedTerm}
//           open={open}
//           onClose={handleClose}
//           label={"Save"}
//         />
//         <ReusableSnackbar />
//       </Box>
//     </>
//   );
// };

// export default Terms;

import {
  Box,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import editSVG from "../../svg/edit.svg";
import CustomDialog from "../../components/CustomDialog";
import { MyEditor } from "../../components/MyEditor";
import Markdown from "markdown-to-jsx";
import { useAboutUs, useEditAboutUs } from "../../services/apis/about";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import AddButton from "../../components/common/AddButton";
import { FirstBox, SecondBox } from "../../components/common/CustomBox";
import { FirstTypography } from "../../components/common/CustomTypogrphy";
import { useEditTermsUs, useTermsUs } from "../../services/apis/terms";
const Terms = () => {
  const [isEdit, setIsEdit] = useState(false);
  const lang = [
    { id: 1, local: "en", name: "English" },
    { id: 2, local: "ar", name: "Arabic" },
    // { id: 3, local: "eg", name: "Egyptian Arabic" },
  ];
  const { data: terms, isLoading } = useTermsUs();
  // const { data, isLoading } = useAboutUs();
  const { mutate } = useEditTermsUs();

  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [currentLang, setCurrentLang] = useState("en");
  const [translatedText, setTranslatedText] = useState({
    en: "",
    ar: "",
    // eg: "",
  });
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };
  const dispatch = useDispatch();

  useEffect(() => {
    setTranslatedText({
      ar: terms ? terms[0]?.value : "",
      en: terms ? terms[1]?.value : "",
    });
  }, [terms]);
  const containsLetter = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    const textContent = tempDiv.textContent || tempDiv.innerText || "";
    return /\S/.test(textContent);
  };
  const onSubmit = async () => {
    try {
      const id = currentLang === "en" ? 22 : 21;
      const currentValue =
        currentLang === "en" ? translatedText.en : translatedText.ar;

      mutate({ id, body: { value: currentValue } });
    } catch (error) {}
  };
  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <DashboardMainHeader />
      <Header title="Terms And Conditions" marginL={"20px"} />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 2,
          p: 2,
          justifyContent: "end",
        }}
      >
        <AddButton
          onClick={() => setIsEdit(true)}
          title={
            <>
              <img src={editSVG} alt="" style={{ marginRight: "10px" }} /> Edit
            </>
          }
        />
      </Box>
      <SecondBox>
        <FirstBox m="40px 0 0 0" height="100%" padding={"40px"}>
          <Typography
            fontSize={"32px"}
            color={"#666666"}
            fontWeight={"500"}
            sx={{ fontFamily: "Roboto" }}
          >
            Terms & Conditions{" "}
          </Typography>
          <Box sx={{ width: "100%", display: isEdit ? "" : "none" }}>
            <FirstTypography title={"Select Language"} />

            <FormControl sx={{ width: "100%" }}>
              <Select
                displayEmpty
                onChange={(e) => {
                  setCurrentLang(e.target.value);
                }}
                value={currentLang}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>{""}</em>;
                  }
                  return (
                    lang.find((item) => item.local === selected)?.name || ""
                  );
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                {lang.map((item) => (
                  <MenuItem key={item.local} value={item.local}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: "100%" }}>
            <FirstTypography
              title={"About us"}
              extraSx={{ display: isEdit ? "" : "none" }}
            />

            {isEdit ? (
              <MyEditor
                text={translatedText[currentLang]}
                setText={(newContent) => {
                  setTranslatedText((prevState) => ({
                    ...prevState,
                    [currentLang]: newContent,
                  }));
                }}
              />
            ) : (
              <>
                {isLoading ? (
                  <div className="grid gap-4 mt-5">
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </div>
                ) : (
                  <Markdown>{translatedText["en"]}</Markdown>
                )}
              </>
            )}
          </Box>
        </FirstBox>
        <Box
          sx={{
            display: isEdit ? "flex" : "none",
            width: "100%",
            gap: 2,
            p: 2,
            justifyContent: "end",
          }}
        >
          <AddButton
            width="180px"
            title={"Save"}
            onClick={() => {
              if (!containsLetter(translatedText[currentLang])) {
                dispatch(
                  setData({
                    openSnack: true,
                    message: "Please Fill The Textarea",
                  })
                );
              } else {
                setIsEdit((prevState) => !prevState);
                setSuccessDialogOpen(true);
                onSubmit();
              }
            }}
          />
        </Box>
      </SecondBox>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title=" Terms & Conditions has been Edited Successfully."
        message=""
        buttonText="Go to Terms & Conditions"
      />
      <ReusableSnackbar />
    </Box>
  );
};

export default Terms;
