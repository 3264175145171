import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import { DataGridStyle } from "../../styles";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { formDate } from "../../services/helpers";

const FractionTransactionModal = ({ onClose, data, open }) => {
  const cellsStyle = {};
  return (
    <Dialog
      maxWidth="1000px"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          width: "900px",
          height: "auto",
          padding: "0 0 2% 0",
          borderRadius: "24px",
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            alignSelf: "flex-end",
            marginTop: "5px",
            float: "right",
          }}
        >
          <IoIosCloseCircleOutline
            onClick={onClose}
            color="#4C4C4C"
            style={{
              width: "24px",
              height: "24px",
              cursor: "pointer",
            }}
          />
        </Box>
        <Box sx={{ marginTop: "5%", marginLeft: "2%", marginBottom: "2%" }}>
          <Typography
            sx={{ fontSize: "24px", fontWeight: "500", color: "#666666" }}
          >
            Transaction Details
          </Typography>
        </Box>
        <Box m="40px 0 0 0" sx={DataGridStyle}>
          <TableContainer>
            <Table sx={{ borderCollapse: "collapse" }}>
              <TableHead sx={{}}>
                <TableRow>
                  <TableCell
                    style={{
                      textAlign: "center",
                      color: "#4C4C4C",
                      fontWeight: "600",
                    }}
                  >
                    ID
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      color: "#4C4C4C",
                      fontWeight: "600",
                    }}
                  >
                    User Phone number
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Gold Price
                  </TableCell>
                 
                  <TableCell
                    style={{
                      textAlign: "center",
                      color: "#4C4C4C",
                      fontWeight: "600",
                    }}
                  >
                    Price
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      color: "#4C4C4C",
                      fontWeight: "600",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      color: "#4C4C4C",
                      fontWeight: "600",
                    }}
                  >
                    Serial No.
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.serials
                  ?.concat(data?.sold_serials || [])
                  .map((serial, index) => (
                    <TableRow key={index} style={{ cursor: "pointer" }}>
                      <TableCell
                        sx={cellsStyle}
                        style={{ textAlign: "center" }}
                      >
                        {data.id}
                      </TableCell>
                      <TableCell
                        sx={cellsStyle}
                        style={{ textAlign: "center" }}
                      >
                        {data?.user_phone}
                      </TableCell>
                      <TableCell
                        sx={cellsStyle}
                        style={{ textAlign: "center" }}
                      >
                        {data?.price} EGP
                      </TableCell>
                      
                      <TableCell
                        sx={cellsStyle}
                        style={{ textAlign: "center" }}
                      >
                        {Math.abs(parseFloat(data.total))?.toLocaleString()} EGP
                      </TableCell>
                      <TableCell
                        sx={cellsStyle}
                        style={{ textAlign: "center" }}
                      >
                        {formDate(data?.created)}
                      </TableCell>
                      <TableCell
                        sx={cellsStyle}
                        style={{ textAlign: "center" }}
                      >
                        {serial}
                        {/* {`00-${serial}-${data.id}-00000-0${
                          Math.abs(data?.total_weight) * 1000
                        }`} */}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FractionTransactionModal;
