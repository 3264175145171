import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Skeleton } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import UserInfoField from "../user-profile/UserInfoField";
import { DataGridStyle } from "../../styles";
import { DataGrid } from "@mui/x-data-grid";
import {
  deleteTicketQuestion,
  fetchSubQuestionsDetails,
} from "../../services/apis/tickets";
import ActionAlert from "../user-profile/ActionAlert";
import CustomDialog from "../../components/CustomDialog";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { ItemsColumns } from "../../components/common/Columns";
import { CustomLoadingOverlay } from "../../components/common/CustomLoadingOverlay";
const SubQuestionsDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const selectedSubQuestionData = location?.state?.id;
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [openDeletedAlert, setOpenDeletedAlert] = useState(false);
  const [selectedSubjectId, setSelectedSubjectId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [subQuestionsDetails, setSubQuestionsDetails] = useState([]);
  const [subQuestionsHeaderDetails, setSubQuestionsHeaderDetails] = useState(
    []
  );
  const dispatch = useDispatch();

  const getRowId = (row) => row.id;
  const getSubQuestionsDetails = async () => {
    setLoading(true);
    setSubQuestionsDetails([]);
    try {
      const response = await fetchSubQuestionsDetails(id);
      setSubQuestionsDetails(response.data.data.subQuestions);
      setSubQuestionsHeaderDetails(response.data.data);
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  const navigate = useNavigate();
  const handleAddSubQuestion = () => {
    navigate(`/AddNewSubQuestion/${selectedSubQuestionData.id}`, {
      state: { selectedSubQuestionData: selectedSubQuestionData },
    });
  };
  const handleEditSubQuestion = (params) => {
    const subquestionToEdit = params.row;
    navigate(`/EditSubQuestion/${params.row.id}`, {
      state: { selectedSubQuestionData: subquestionToEdit },
    });
  };
  const handleOpenDeleteAlert = (id) => {
    setSelectedSubjectId(id);
    setOpenDeletedAlert(true);
  };
  const handleCloseDeleteAlert = () => {
    setOpenDeletedAlert(false);
  };
  const handleDeleteSubject = async () => {
    if (selectedSubjectId) {
      try {
        await deleteTicketQuestion(selectedSubjectId);
        setSuccessDialogOpen(true);
        handleCloseDeleteAlert();
        getSubQuestionsDetails();
      } catch (error) {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      }
    }
  };
  const subQuestionsColumns = ItemsColumns([
    {
      field: "sort",
      headerName: "Order",
      specialRender: "customRender",
      renderFunction: (params) => (params.value === null ? 0 : params.row.sort),
    },
    {
      field: "subQuestions",
      headerName: "Question",
      specialRender: "customRender",
      renderFunction: (params) => params?.row?.trans?.en?.question,
    },
    {
      field: "awesome",
      headerName: "Excellent",
      specialRender: "customRender",
      flex: 2,
      renderFunction: (params) => {
        const totalFeedback = params.row.totalFeedback || 0;
        const excellentCount = params.row.awesome || 0;
        const excellentPercentage = (excellentCount / totalFeedback) * 100 || 0;
        return `${excellentPercentage.toFixed(0)}%`;
      },
    },
    {
      field: "good",
      flex: 2,
      specialRender: "customRender",
      renderFunction: (params) => {
        const totalFeedback = params.row.totalFeedback || 0;
        const goodCount = params.row.good || 0;
        const goodPercentage = (goodCount / totalFeedback) * 100 || 0;
        return `${goodPercentage.toFixed(0)}%`;
      },
    },
    {
      field: "bad",
      flex: 2,
      specialRender: "customRender",
      renderFunction: (params) => {
        const totalFeedback = params.row.totalFeedback || 0;
        const badCount = params.row.bad || 0;
        const badPercentage = (badCount / totalFeedback) * 100 || 0;
        return `${badPercentage.toFixed(0)}%`;
      },
    },
    {
      field: "Actions",
      actions: {
        edit: (params) => handleEditSubQuestion(params),
        delete: (params) => handleOpenDeleteAlert(params.row.id),
      },
    },
  ]);

  useEffect(() => {
    getSubQuestionsDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box
      style={{
        overflowY: "auto",
        maxHeight: "calc(100vh)",
        display: "flex",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Box sx={{ alignSelf: "start", ml: 3 }}>
        <Header title={"Ticket Questions"} />
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "95%",
          alignItems: "center",
          alignSelf: "center",
          marginTop: "2%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "85%" }}>
              <UserInfoField
                title="Question"
                data={
                  loading ? (
                    <Skeleton sx={{ width: 200 }} />
                  ) : (
                    selectedSubQuestionData?.trans?.en?.question
                  )
                }
              />
            </Box>
            <Box sx={{ width: "85%" }}>
              <UserInfoField
                title="Subject"
                data={
                  loading ? (
                    <Skeleton sx={{ width: 200 }} />
                  ) : (
                    selectedSubQuestionData?.subject
                  )
                }
              />
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "85%" }}>
              <UserInfoField
                title="No Sub Questions"
                data={
                  loading ? (
                    <Skeleton sx={{ width: 200 }} />
                  ) : (
                    subQuestionsHeaderDetails?.subQuestionsCount
                  )
                }
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Header title="Sub Questions" marginL={"25px"} marginT={"3%"} />
      <Box
        m="20px"
        sx={{
          height: "auto",
          background: "#FFF",
          borderRadius: "12px",
          boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Box m="40px 0 0 0" height="100%" sx={DataGridStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              gap: 2,
              pr: 2,
            }}
          >
            <Button
              color="secondary"
              variant="contained"
              sx={{
                boxShadow: "none",
                border: "2px solid #917244",
                borderRadius: "9px",
                background: "#ffffff",
                "&:hover": {
                  background: "#ffffff",
                  boxShadow: "none",
                },
                width: "171px",
                height: "48px",
                color: "#917244",
                fontSize: "16px",
                textTransform: "none",
                display: "flex",
                alignSelf: "flex-end",
                alignItems: "flex-end",
                marginBottom: "20px",
              }}
              onClick={handleAddSubQuestion}
            >
              + New Sub Question
            </Button>
          </Box>
          <DataGrid
            components={{
              LoadingOverlay: () =>
                CustomLoadingOverlay({ columns: 6, rows: 2 }),
              Footer: () => null,
            }}
            getRowId={getRowId}
            rows={subQuestionsDetails}
            columns={subQuestionsColumns}
            loading={loading}
            sx={{
              cursor: "pointer",
              backgroundColor: "#ffffff",
            }}
            autoHeight
            localeText={{
              noRowsLabel: "No Sub Questions",
            }}
          />
        </Box>
      </Box>
      <ActionAlert
        openAlert={openDeletedAlert}
        handleCloseAlert={handleCloseDeleteAlert}
        handleReject={handleDeleteSubject}
        message={`Are you sure you want to Delete this Question ?`}
        label={"Delete"}
      />
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Sub Question has been deleted Successfully."
        message=""
        buttonText="Go to Sub Questions"
      />
      <ReusableSnackbar />
    </Box>
  );
};

export default SubQuestionsDetails;
