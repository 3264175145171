import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearToken } from "../store/slices/authenticationSlice";
import { RxHamburgerMenu } from "react-icons/rx";
import { toggleSidebar } from "../store/slices/sidebarSlice";
import useWindowSize from "../hooks/useWindowSize";
import { axiosInstance as axios } from "../services/config";
import profile from "../svg/profile.svg";
import logout from "../svg/logout.svg";
import notification from "../svg/notification.svg";
const DashboardMainHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogOut = async () => {
    try {
      await axios.post(`api/users/adminLogout`);
      window.history.pushState(null, null, "/login");
      window.history.replaceState(null, null, "/login");
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
      sessionStorage.removeItem("selectedTitle");
      sessionStorage.removeItem("isPageRefreshed");
      dispatch(clearToken());
    } catch (error) {
      console.error("Logout error:", error);
      window.history.pushState(null, null, "/login");
      window.history.replaceState(null, null, "/login");
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
      sessionStorage.removeItem("selectedTitle");
      sessionStorage.removeItem("isPageRefreshed");
      dispatch(clearToken());
    }
  };
  const { width } = useWindowSize();
  return (
    <div
      className={`flex 
     items-center mr-[1%] pt-[2%] mb-[30px]`}
    >
      <RxHamburgerMenu
        color="#917244"
        size={20}
        onClick={() => {
          dispatch(toggleSidebar());
        }}
        className={` mr-auto ml-6
          ${width > 768 ? "hidden" : "block"}
          `}
      />
      <div className="flex items-center justify-end w-full mx-6">
        <img
          src={profile}
          alt=""
          className=" cursor-pointer"
          onClick={() => {
            navigate("/AdminProfile");
          }}
        />
        <img
          src={notification}
          alt=""
          className="ml-3 mr-8 cursor-pointer"
          onClick={() => {}}
        />
        <img
          src={logout}
          alt=""
          className=" cursor-pointer"
          onClick={() => {
            handleLogOut();
          }}
        />
      </div>
    </div>
  );
};

export default DashboardMainHeader;
