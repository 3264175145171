import greenTick from "../../svg/green-tick.svg";
import add from "../../svg/add.svg";
export default function BottomAlert({ message, status, open, setOpen }) {
  return (
    <div className="relative">
      {open && (
        <div
          style={{
            boxShadow: `0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012;`,
          }}
          className={`
        fixed bottom-5 left-[40%] transform  bg-[#EAF7ED] text-[#28A745] px-4 py-3 rounded-xl shadow-lg animate-slide-up flex items-center 
        `}
        >
          <img src={greenTick} alt="greenTick" />
          <span className="flex-1 mx-2">{message}</span>
          <img
            src={add}
            alt="add"
            onClick={() => setOpen(false)}
            className="cursor-pointer ml-10"
          />
        </div>
      )}
    </div>
  );
}
