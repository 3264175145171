import {
  Box,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";

const StatusSelect = ({
  selectedStatus,
  handleStatusChange,
  data,
  MenuItemProps,
  Status,
}) => {
  return (
    <Box
      sx={{
        width: "120px",
        backgroundColor: "#FAFAFA",
        borderRadius: "6px",
        borderBottom: "none",
      }}
    >
      <FormControl sx={{ width: "100%" }}>
        <Select
          displayEmpty
          onChange={handleStatusChange}
          value={selectedStatus || ""}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color:
                      selectedStatus && selectedStatus.id === 1
                        ? "#FF453A"
                        : selectedStatus && selectedStatus.id === 2
                        ? "#917244"
                        : selectedStatus && selectedStatus.id === 3
                        ? "#3284FF"
                        : data?.status === 1
                        ? "#FF453A"
                        : data?.status === 2
                        ? "#917244"
                        : data?.status === 3
                        ? "#3284FF"
                        : "#30D158",
                  }}
                >
                  <span
                    style={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      backgroundColor:
                        selectedStatus && selectedStatus.id === 1
                          ? "#FF453A"
                          : selectedStatus && selectedStatus.id === 2
                          ? "#917244"
                          : selectedStatus && selectedStatus.id === 3
                          ? "#3284FF"
                          : data?.status === 1
                          ? "#FF453A"
                          : data?.status === 2
                          ? "#917244"
                          : data?.status === 3
                          ? "#3284FF"
                          : "#30D158",
                      marginRight: "8px",
                    }}
                  ></span>
                  {data?.status === 1
                    ? "Open"
                    : data?.status === 2
                    ? "Pending"
                    : data?.status === 3
                    ? "Resolved"
                    : data?.status === 4
                    ? "Closed"
                    : "Open"}
                </Box>
              );
            }
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                    backgroundColor:
                      selectedStatus && selectedStatus.id === 1
                        ? "#FF453A"
                        : selectedStatus && selectedStatus.id === 2
                        ? "#917244"
                        : selectedStatus && selectedStatus.id === 3
                        ? "#3284FF"
                        : "#30D158",
                    marginRight: "8px",
                  }}
                ></span>
                {selected.name}
              </Box>
            );
          }}
          MenuProps={MenuItemProps}
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
              },
            "& .MuiSelect-select.MuiSelect-select": {
              color:
                selectedStatus && selectedStatus.id === 1
                  ? "#FF453A"
                  : selectedStatus && selectedStatus.id === 2
                  ? "#917244"
                  : selectedStatus && selectedStatus.id === 3
                  ? "#3284FF"
                  : "#30D158",
            },
          }}
        >
          {Status.map((item) => (
            <MenuItem
              key={item.id}
              value={item}
              sx={{
                color:
                  item.id === 1
                    ? "#FF453A"
                    : item.id === 2
                    ? "#917244"
                    : item.id === 3
                    ? "#3284FF"
                    : "#30D158",
              }}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default StatusSelect;
