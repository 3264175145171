import { Box, Grid, Typography } from "@mui/material";
import UserOverviewMainCard from "../../components/UserOverviewMainCard";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { DataGrid } from "@mui/x-data-grid";
import DashboardDashedChart from "./DashboardDashedChart";
import InvesmentAmountCard from "../../components/InvesmentAmountCard";
import { useFetchFractions } from "../../services/apis/overview";
import dayjs from "dayjs";

const FractionalGoldTabPanel = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const today = new Date();
  const date14DaysAgo = new Date();
  date14DaysAgo.setDate(today.getDate() - 14);
  const formattedToday = today.toISOString().split("T")[0];
  const prevDay = new Date(today.setDate(today.getDate() - 1))
    .toISOString()
    .split("T")[0];
  const prevWeek = new Date(today.setDate(today.getDate() - 7))
    .toISOString()
    .split("T")[0];
  const [queryParameters, setQueryParameters] = useState({
    livePrice_startDate: prevDay,
    livePrice_endDate: formattedToday,
    distributionOfFraction_startDate: prevWeek,
    distributionOfFraction_endDate: formattedToday,
    buySell_startDate: prevWeek,
    buySell_endDate: formattedToday,
  });
  const { data: fractionsData, isFetching } =
    useFetchFractions(queryParameters);
  const walletBalancesColumns = [
    {
      field: "actions",
      headerName: "",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          cursor="pointer"
          // onClick={() => handleClick(params)}
        >
          <path
            d="M15.5799 12C15.5799 13.98 13.9799 15.58 11.9999 15.58C10.0199 15.58 8.41992 13.98 8.41992 12C8.41992 10.02 10.0199 8.42 11.9999 8.42C13.9799 8.42 15.5799 10.02 15.5799 12Z"
            stroke="#00303C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.4C18.8198 5.8 15.5298 3.72 11.9998 3.72C8.46984 3.72 5.17984 5.8 2.88984 9.4C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z"
            stroke="#00303C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      field: "full_name",
      headerName: "Username",
      flex: 2,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
    },
    {
      field: "phone",
      headerName: "Phone number",
      flex: 2,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
    },
    {
      field: "weight",
      headerName: "Weight",
      flex: 3,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
    },
  ];
  return (
    <>
      {/* {isLoading ? (
        <LoadingSpinner />
      ) : ( */}
      <Box>
        <Box>
          <Typography
            sx={{ color: "#808080", fontSize: "16px", fontWeight: "500" }}
          >
            OverView
          </Typography>
        </Box>
        {/* fractional Gold overview start  */}
        <Grid container spacing={1.5} pt={4}>
          {/* card Charts start  */}
          <Grid item lg={4} xs={12} sm={4} md={4} p={1}>
            <UserOverviewMainCard
              backgroundColor="#FFFFFF"
              title="Live Gold Price"
              total={
                fractionsData?.priceChart?.length
                  ? fractionsData.priceChart[
                      fractionsData.priceChart.length - 1
                    ].buy_gold_24
                  : " "
              }
              queryParameters={queryParameters}
              setQueryParameters={setQueryParameters}
              isLoading={isFetching}
              value={"EGP"}
              data={fractionsData}
            />
          </Grid>
          <Grid item lg={4} xs={12} sm={4} md={4} p={1}>
            <UserOverviewMainCard
              backgroundColor="#FFFFFF"
              title="Fractions in Stock"
              total={"521,562"}
              value={"Gram"}
            />
          </Grid>
          <Grid item lg={4} xs={12} sm={4} md={4} p={1}>
            <UserOverviewMainCard
              backgroundColor="#FFFFFF"
              title="Total Fractions Value"
              total={"145,984,673,922.268"}
              value={"EGP"}
            />
          </Grid>
          {/* end card charts  */}
          {/* Distribution of Fraction sizes start  */}
          <Grid mt={2} item xs={11.8} md={11.8} lg={11.8}>
            <DashboardDashedChart
              type={"Distribution"}
              height={440}
              width={"100%"}
              style={{
                boxShadow: "0px 0px 0px #D3D3D3",
                borderRadius: "24px",
              }}
              title="Distribution of Fraction Sizes"
              queryParameters={queryParameters}
              setQueryParameters={setQueryParameters}
              chart={{
                labels: fractionsData?.distributionOfFractionSizes?.map(
                  (item) => `${item.total_weight}g`
                ),
                series: [
                  {
                    name: "Fraction",
                    type: "area",
                    fill: "gradient",
                    data: fractionsData?.distributionOfFractionSizes?.map(
                      (item) => item.total
                    ),
                    color: "#917244",
                  },
                ],
              }}
            />
          </Grid>
          {/* end Distribution of Fraction sizes  */}
          {/* Spread Between Buying and Selling Price start  */}
          <Grid mt={2} item xs={12} md={6} lg={6}>
            <DashboardDashedChart
              type={"fractional"}
              height={288}
              width={"100%"}
              style={{
                boxShadow: "0px 0px 0px #D3D3D3",
                borderRadius: "24px",
              }}
              title="Spread Between Buying and Selling Price"
              queryParameters={queryParameters}
              setQueryParameters={setQueryParameters}
              chart={{
                labels: fractionsData?.buySellChart?.map((item) => {
                  const timezoneOffset = new Date().getTimezoneOffset();
                  const date = new Date(item.date.replace(/'/g, ""));
                  date.setHours(date.getHours() - timezoneOffset / 60);
                  return dayjs(date).format("YYYY-MMM-DD");
                }),
                series: [
                  {
                    name: "Buying",
                    type: "area",
                    fill: "gradient",
                    data: fractionsData?.buySellChart?.map((item) =>
                      Number(item.buy_gold_24)
                    ),
                    color: "#917244",
                  },
                  {
                    name: "Selling",
                    type: "area",
                    fill: "gradient",
                    data: fractionsData?.buySellChart?.map((item) =>
                      Number(item.sell_gold_24)
                    ),
                    color: "#FF453A",
                  },
                ],
              }}
            />
          </Grid>
          <Grid mt={2} item xs={12} md={6} lg={6}>
            <DashboardDashedChart
              type={"fractional"}
              height={288}
              width={"100%"}
              style={{
                boxShadow: "0px 0px 0px #D3D3D3",
                borderRadius: "24px",
              }}
              title="liquidity of the fractional gold market"
              queryParameters={queryParameters}
              setQueryParameters={setQueryParameters}
              chart={{
                labels: [
                  "10K",
                  "50K",
                  "100K",
                  "150K",
                  "200K",
                  "250K",
                  "300K",
                  "400K",
                  "450K",
                  "500K",
                  "550K",
                ],
                series: [
                  {
                    name: "Fraction",
                    type: "area",
                    fill: "gradient",
                    data: [
                      10000, 100000, 120000, 150000, 220000, 150000, 300000,
                      400000, 350000, 450000, 350000,
                    ],
                    color: "#917244",
                  },
                ],
              }}
            />
          </Grid>
          {/*End Spread Between Buying and Selling Price  */}
          {/* Largest Fractional Gold Holdings start  */}
          <Grid
            item
            p={2}
            mt={3}
            sx={{
              backgroundColor: "#ffffff",
              height: "418px",
              borderRadius: "24px",
              marginLeft: "1%",
            }}
            xs={12}
            md={12}
            lg={7.7}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{ color: "#808080", fontSize: "20px", fontWeight: "600" }}
              >
                Largest Fractional Gold Holdings
              </Typography>
            </Box>
            <Box
              m="20px"
              sx={{
                height: "auto",
                background: "#FFF",
                borderRadius: "12px",
              }}
            >
              <Box
                m="40px 0 0 0"
                height="100%"
                sx={{
                  "& .MuiDataGrid-columnHeader.bold-header": {
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#000000",
                  },
                  "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none",
                  },
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                    backgroundColor: "#FFF",
                  },
                  "& .name-column--cell": {
                    color: colors.greenAccent[300],
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#FFF",
                    borderBottom: "none",
                    pointerEvents: "none",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: "#FFF",
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: "#FFF",
                  },
                  "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                  },
                  "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                  },
                }}
              >
                <DataGrid
                  hideFooterSelectedRowCount={true}
                  rows={isFetching ? [] : fractionsData?.largestFractional}
                  columns={walletBalancesColumns}
                  autoHeight={true}
                  loading={isFetching}
                  components={{
                    Footer: () => null,
                  }}
                  disableColumnMenu={true}
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            mt={1.5}
            sx={{
              // backgroundColor: "#ffffff",
              height: "448px",
              // borderRadius: "24px",
              marginLeft: "1%",
            }}
            xs={12}
            md={12}
            lg={4}
          >
            <Grid item lg={12} xs={12} md={12}>
              <InvesmentAmountCard
                style={{ height: "200px" }}
                backgroundColor="#FFFFFF"
                title="Minimum Investment Amount"
                total={fractionsData?.config?.min_buy_fraction}
                value={"Gram"}
              />
              <Grid mt={2} item lg={12} xs={12} md={12}>
                <InvesmentAmountCard
                  style={{ height: "200px" }}
                  backgroundColor="#FFFFFF"
                  title="Maximum Investment Amount"
                  total={fractionsData?.config?.max_gold_fraction}
                  value={"Gram"}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* end Largest Fractional Gold Holdings  */}
        </Grid>
        {/* end fractional Gold overview  */}
      </Box>
      {/* )} */}
    </>
  );
};
export default FractionalGoldTabPanel;
