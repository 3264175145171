import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { styled, useTheme } from "@mui/material/styles";
import { fNumber } from "./Format-number";
import useChart from "./UseChart";
import Chart from "./Chart";
import { Box, Typography } from "@mui/material";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 334;
const LEGEND_HEIGHT = 70;

const StyledChart = styled(Chart)(({ theme }) => ({
  height: CHART_HEIGHT,
  "& .apexcharts-canvas, .apexcharts-inner, svg, foreignObject": {
    height: `100% !important`,
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

export default function BlockedUsersChart({
  title,
  subheader,
  chart,
  labelTitle,
  labelNumber,
  isFetching,
  ...other
}) {
  const theme = useTheme();

  const { series, options } = chart;
  const updatedSeries = series.map((item, index) => ({
    ...item,
    color:
      item.color ||
      (index === 0 ? "#917244" : index === 1 ? "#F2DA87" : "#FBF3D7"),
  }));

  const chartSeries = updatedSeries.map((i) => i.value);

  const chartOptions = useChart({
    chart: {
      type: "donut",
      sparkline: {
        enabled: true,
      },
    },
    colors: updatedSeries.map((item) => item.color),
    labels: series.map((i) => i.label),
    stroke: {
      colors: [theme.palette.background.paper],
    },
    legend: {
      floating: true,
      position: "bottom",
      horizontalAlign: "center",
    },
    dataLabels: {
      enabled: false,
      dropShadow: {
        enabled: false,
      },
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (value) => fNumber(value),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    // plotOptions: { pie: { expandOnClick: false } },

    plotOptions: {
      pie: {
        donut: {
          size: "100%",
          labels: {
            show: false,
          },
        },
      },
    },
    ...options,
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} sx={{}} />
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {isFetching ? (
          <table className="w-[93%]">
            <tbody>
              <tr className="animate-pulse">
                <td className="py-2 whitespace-nowrap w-full">
                  <div className="h-[21.6rem] bg-gray-200 rounded w-full" />
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <Box sx={{ position: "relative" }}>
            <StyledChart
              dir="ltr"
              type="pie"
              series={chartSeries}
              options={chartOptions}
              width="100%"
              height={230}
              sx={{ marginBottom: "20px" }}
            />
            <Typography
              variant="h6"
              sx={{
                position: "absolute",
                top: "70%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {labelTitle}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                position: "absolute",
                top: "77%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "#917244",
                fontSize: "20px",
              }}
            >
              {labelNumber}
            </Typography>
          </Box>
        )}
      </Box>
    </Card>
  );
}

BlockedUsersChart.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string,
};
