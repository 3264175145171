import React, { useEffect } from "react";
import {
  Box,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  MenuItem,
  Select,
  OutlinedInput,
  FormControl,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useState } from "react";
import BlockFilter from "./BlockFilter";
import { useBlockReasons } from "../../services/apis/blockReasons";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import danger from "../../svg/danger.svg";
import closeCircle from "../../svg/new-close-circle.svg";
import calendar from "../../svg/calendar.svg";
import brownDanger from "../../svg/brown-danger.svg";
import arrowCircleDown from "../../svg/arrow-circle-down.svg";
const BlockUserDialog = ({
  openAlert,
  handleCloseAlert,
  handleReject,
  label,
  message,
  userGroup,
}) => {
  const dispatch = useDispatch();

  const { data } = useBlockReasons({ page: 0, limit: 100 });
  const [blockType, setBlockType] = useState("1");

  const [queryParameters, setQueryParameters] = useState({
    date_from: null, // Current date
    date_to: null,
  });
  const [selectedReason, setSelectedReason] = useState("");
  const validateDates = () => {
    const { date_from, date_to } = queryParameters;

    if (!date_from || !date_to) {
      return "Both start and end dates are required.";
    }

    if (date_from === date_to) {
      return "Start date cannot be the same as the end date.";
    }

    if (new Date(date_to) < new Date(date_from)) {
      return "End date must be after the start date.";
    }

    return null;
  };
  const handleFormSubmit = () => {
    const dateError = validateDates();

    if (dateError && userGroup !== "blockedUser" && blockType === "1") {
      dispatch(
        setData({
          severity: "error",
          openSnack: true,
          message: dateError,
        })
      );
      return;
    }

    if (
      (blockType === "0" && selectedReason) ||
      (blockType === "1" &&
        selectedReason &&
        queryParameters?.date_to &&
        queryParameters?.date_from)
    ) {
      handleReject({
        blockType,
        dateTo: queryParameters?.date_to,
        dateFrom: queryParameters?.date_from,
        selectedReason,
      });
      setQueryParameters({
        date_from: null,
        date_to: null,
      });
      setSelectedReason("");
    } else if (userGroup === "blockedUser") {
      handleReject();
      setQueryParameters({
        date_from: null,
        date_to: null,
      });
      setSelectedReason("");
    } else {
      dispatch(
        setData({
          severity: "error",
          openSnack: true,
          message: "Please Fill All Fields",
        })
      );
    }
  };
  return (
    <div>
      <Dialog
        open={openAlert}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "32px",
          },
        }}
      >
        <DialogContent>
          <div className="p-16 ">
            <img
              src={closeCircle}
              alt="closeCircle"
              className="ml-auto cursor-pointer"
              onClick={handleCloseAlert}
            />
            <div>
              <img
                src={userGroup === "blockedUser" ? brownDanger : danger}
                alt="danger"
                className="mx-auto"
              />
              <p className=" text-[#595959] font-medium text-2xl font-ibmArabic mt-3 whitespace-nowrap text-center">
                {message}
              </p>
              <div className="mt-8">
                {userGroup !== "blockedUser" ? (
                  <>
                    <p className="text-[#595959] font-medium text-lg font-ibmArabic">
                      Block Type
                    </p>
                    <div className="flex items-center mt-4 mb-8">
                      <input
                        checked={blockType === "1"}
                        type="radio"
                        id="temporary"
                        name="fav_language"
                        value="1" // Set value to "1" for Temporary
                        onChange={(e) => {
                          setBlockType(e.target.value); // Set blockType to "1" when Temporary is selected
                        }}
                        className="w-5 h-5 mr-2 accent-red-500 cursor-pointer"
                      />
                      <label
                        htmlFor="temporary" // Use htmlFor instead of for
                        className="cursor-pointer font-ibmArabic mr-8 text-[#595959]"
                      >
                        Temporary
                      </label>

                      <input
                        checked={blockType === "0"}
                        type="radio"
                        id="permanently"
                        name="fav_language"
                        value="0" // Set value to "0" for Permanently
                        onChange={(e) => {
                          setBlockType(e.target.value); // Set blockType to "0" when Permanently is selected
                        }}
                        className="w-5 h-5 mr-2 accent-red-500 cursor-pointer"
                      />
                      <label
                        htmlFor="permanently" // Use htmlFor instead of for
                        className="text-[#595959] font-ibmArabic cursor-pointer"
                      >
                        Permanently
                      </label>
                    </div>

                    {blockType === "1" ? (
                      <>
                        <p className="text-[#595959] font-medium text-lg font-ibmArabic">
                          Join Date
                        </p>
                        <div className="grid grid-cols-2 gap-6 mt-4">
                          <div>
                            <p className="text-[#595959] text-sm font-ibmArabic mb-2">
                              From
                            </p>
                            <div className="relative">
                              <input
                                value={queryParameters?.date_from}
                                onChange={(e) => {
                                  setQueryParameters({
                                    ...queryParameters,
                                    date_from: e.target.value,
                                  });
                                }}
                                min={new Date().toISOString().split("T")[0]} // Disables past dates
                                type="date"
                                placeholder="01/01/2024" // This will not show in type="date" inputs directly.
                                className="p-4 bg-[#f5f5f5] rounded-xl w-full cursor-pointer"
                              />
                              <img
                                src={calendar}
                                alt="calendar"
                                className="absolute right-3 bottom-4 bg-[#f5f5f5] pointer-events-none cursor-pointer"
                              />
                            </div>
                          </div>
                          <div>
                            <p className="text-[#595959] text-sm font-ibmArabic mb-2">
                              To
                            </p>
                            <div className="relative">
                              <input
                                min={new Date().toISOString().split("T")[0]} // Disables past dates
                                value={queryParameters?.date_to}
                                onChange={(e) => {
                                  setQueryParameters({
                                    ...queryParameters,
                                    date_to: e.target.value,
                                  });
                                }}
                                type="date"
                                placeholder="01/01/2024" // This will not show in type="date" inputs directly.
                                className="p-4 bg-[#f5f5f5] rounded-xl w-full cursor-pointer"
                              />
                              <div className="cursor-pointer">
                                <img
                                  src={calendar}
                                  alt="calendar"
                                  className="absolute right-3 bottom-4 bg-[#f5f5f5] pointer-events-none   "
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                    <div className="relative mt-8">
                      <p className="text-[#595959] font-ibmArabic font-medium text-lg mb-4">
                        Select Block Reason
                      </p>
                      <select
                        onChange={(e) => {
                          setSelectedReason(e.target.value);
                        }}
                        className="block w-full p-4 text-[#808080] bg-[#f5f5f5] rounded-xl appearance-none focus:outline-none focus:ring-2 focus:bg-grey-100 cursor-pointer pr-10"
                        defaultValue=""
                      >
                        <option value="" disabled>
                          Please select a reason
                        </option>
                        {data?.result?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.trans.en}
                          </option>
                        ))}
                      </select>
                      <img
                        src={arrowCircleDown}
                        alt="arrowCircleDown"
                        className="absolute top-6 right-3 flex items-center justify-center h-full size-6 text-gray-500 pointer-events-none"
                      />
                    </div>
                  </>
                ) : null}
                <div className="flex items-center justify-center gap-2 font-ibmArabic mt-8">
                  <button
                    onClick={handleFormSubmit} // Call the handleFormSubmit function
                    className={`
                      px-20 py-3 rounded-xl  text-white font-medium
                      ${
                        userGroup === "blockedUser"
                          ? "bg-[#917244]"
                          : "bg-[#DC3545]"
                      }
                      `}
                    style={{
                      boxShadow: ` 0px 1px 3px 0px #00000005,0px 1px 6px 0px #00000012`,
                    }}
                  >
                    {label}
                  </button>
                  <button
                    onClick={(e) => {
                      handleCloseAlert(e);
                      setQueryParameters({
                        date_from: null,
                        date_to: null,
                      });
                      setSelectedReason("");
                    }}
                    className="px-20 py-3 rounded-xl  border border-[#595959] text-[#595959] font-medium"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <ReusableSnackbar />
    </div>
  );
};

export default BlockUserDialog;
