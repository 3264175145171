import React from "react";
import {
  Button,
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  Select,
  MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import { useEffect } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import CustomDialog from "../../components/CustomDialog";
import CustomTextArea from "../../components/CustomTextArea";
import { useEditBlockReason } from "../../services/apis/blockReasons";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { MenuProps } from "../../components/common/MenuProps";
import useWindowSize from "../../hooks/useWindowSize";

const EditBlockReasons = ({
  open,
  onClose,
  label,
  blockReasons,
  blockReasonsId,
  fetchData,
}) => {
  const editBlockReasonMutation = useEditBlockReason();
  const { width } = useWindowSize();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const dispatch = useDispatch();

  const blockReasonsEdit = blockReasons?.find(
    (blockReason) => blockReason.id === blockReasonsId
  );
  const [productsPayload, setProductsPayload] = useState({
    local: "en",
    reason: "",
  });
  const lang = [
    { id: 1, local: "en", name: "English" },
    { id: 2, local: "ar", name: "Arabic" },
    { id: 3, local: "eg", name: "Egyptian Arabic" },
  ];
  const handleLangChange = (event) => {
    const selectedLang = event.target.value;
    const selectedLangObj = lang.find((item) => item.local === selectedLang);
    if (selectedLangObj) {
      if (blockReasonsEdit) {
        setProductsPayload((prevState) => ({
          ...prevState,
          local: selectedLang,
          reason:
            selectedLang === "en"
              ? blockReasonsEdit?.trans?.en
              : selectedLang === "ar"
              ? blockReasonsEdit?.trans?.ar
              : blockReasonsEdit?.trans?.eg,
        }));
      }
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await editBlockReasonMutation.mutateAsync({
        id: blockReasonsId,
        body: productsPayload,
      });
      setIsSubmitting(true);
      onClose();
      setSuccessDialogOpen(true);
      await fetchData();
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    if (blockReasonsEdit) {
      setProductsPayload({
        local: "en",
        reason: blockReasonsEdit?.trans?.en,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockReasonsId, blockReasons, open]);
  return (
    <>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Reason has been edit Successfully."
        message=""
        buttonText="Go to Block Reason"
      />
      <ReusableSnackbar />
      <Dialog
        maxWidth="850px"
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            width: width > 768 ? "800px" : "100%",
            height: "auto",
            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            paddingTop: "5%",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              width: "80%",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={onClose}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p className="text-2xl text-center md:text-start  w-full">
                Edit Reason
              </p>
            </Box>
            <div className="w-full">
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#4C4C4C",
                  marginBottom: "5px",
                  marginTop: "15px",
                }}
              >
                Select Language
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  displayEmpty
                  onChange={handleLangChange}
                  value={productsPayload?.local || ""}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>{productsPayload.name}</em>;
                    }
                    return (
                      lang.find((item) => item.local === selected)?.name || ""
                    );
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {lang.map((item) => (
                    <MenuItem key={item.local} value={item.local}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="">
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#4C4C4C",
                  marginBottom: "5px",
                  marginTop: "15px",
                }}
              >
                Reason
              </Typography>
              <CustomTextArea
                style={{
                  minHeight: "54px",
                  maxHeight: "200px",
                  maxWidth: "608px",
                  width: width > 768 ? "608px" : "100%",
                  backgroundColor: "#FFF",
                  borderRadius: "9px",
                  boxShadow: "none",
                  border: "1px solid #917244",
                }}
                name="Reason"
                placeholder="Enter Reason"
                value={productsPayload?.reason}
                onChange={(event) => {
                  let allowedCharacters = /^[a-zA-Z0-9"()@'?:%.,\- ]*$/;
                  allowedCharacters =
                    productsPayload.local === "en"
                      ? /^[a-zA-Z0-9"()@'?:%.,\- ]*$/
                      : /^[\u0600-\u06FF0-9"()-@'?:%.,\s]*$/;
                  if (
                    productsPayload.local !== "en" &&
                    allowedCharacters.test(event.target.value)
                  ) {
                    var temppayload = { ...productsPayload };
                    temppayload.reason = event.target.value;
                    setProductsPayload({ ...temppayload });
                  } else {
                    if (
                      productsPayload?.local === "en" &&
                      allowedCharacters.test(event.target.value)
                    ) {
                      var temppayloadEn = { ...productsPayload };
                      temppayloadEn.reason = event.target.value;
                      setProductsPayload({ ...temppayloadEn });
                    }
                  }
                }}
              />
            </div>

            <div className="grid grid-cols-1 md:flex gap-2 ">
              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={{
                  width: width > 768 ? "200px" : "150px",
                  borderRadius: "9px",
                  background: "#917244",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#917244",
                  },
                }}
                onClick={handleSubmit}
              >
                {label}
              </Button>

              <Button
                variant="outlined"
                sx={{
                  width: width > 768 ? "200px" : "150px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#917244",
                  fontWeight: 600,
                  borderColor: "#917244",
                  "&:hover": {
                    borderColor: "#917244",
                  },
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditBlockReasons;
