import React, { useEffect } from "react";
import {
  Typography,
  Box,
  Divider,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridStyle } from "../../../styles";
import { formDate } from "../../../services/helpers";
import {
  confirmPendingOrdersDetails,
  fetchPendingOrdersDetails,
} from "../../../services/apis/orders";
import UserInfoField from "../../user-profile/UserInfoField";
import Header from "../../../components/Header";
import DashboardMainHeader from "../../../components/DashboardMainHeader";
import LoadingSpinner from "../../../components/LoadingSpinner";
import CustomDialog from "../../../components/CustomDialog";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";
import { MenuProps } from "../../../components/common/MenuProps";
import { ItemsColumns } from "../../../components/common/Columns";

const PendingOrdersDetails = () => {
  const { id } = useParams();
  const [pendingDetails, setPendingDetails] = useState([]);
  const [pendingDetailsData, setPendingDetailsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const [selectedSerials, setSelectedSerials] = useState([]);

  const handleChange = (event) => {
    setSelectedSerials(event.target.value);
  };

  const handleSave = async (row) => {
    const dataToSend = {
      id: row.id,
      serials: selectedSerials,
    };
    try {
      if (selectedSerials.length === 0) {
        dispatch(
          setData({
            openSnack: true,
            message: "Please Select serial first",
          })
        );
      } else {
        await confirmPendingOrdersDetails(dataToSend);
        setSuccessDialogOpen(true);
      }
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error?.response?.data?.message,
        })
      );
    }
  };

  const getPendingDetails = async () => {
    setLoading(true);
    setPendingDetailsData([]);
    try {
      const response = await fetchPendingOrdersDetails(id);
      const pendingDetails = response?.data?.data;
      setPendingDetails(pendingDetails);
      setPendingDetailsData(pendingDetails?.orderDetails);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error?.response?.data?.message,
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const getPendingDiscrepancy = async () => {
    setPendingDetailsData([]);
    try {
      const response = await fetchPendingOrdersDetails(id);
      const pendingDetails = response?.data?.data;
      setPendingDetailsData(pendingDetails?.orderDetails);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const getTypes = () => {
    if (pendingDetails.order_type === 1) {
      return "Storage Order";
    } else if (pendingDetails.order_type === 2) {
      return "Physical Delivery";
    } else if (pendingDetails.order_type === 3) {
      return "Fractions";
    } else if (pendingDetails.order_type === 4) {
      return "Stored then Delivered";
    }
  };
  const calculateNewTotalSum = () => {
    if (!pendingDetailsData) return 0;

    const sum = pendingDetailsData.reduce(
      (sum, orderDetail) => sum + parseFloat(orderDetail.newTotal),
      0
    );

    return sum.toLocaleString();
  };

  let totalDiscrepancy = 0;

  pendingDetailsData?.forEach((detail) => {
    const newTotal = parseFloat(detail.newTotal);
    const total = parseFloat(detail.total);
    const discrepancy = newTotal - total;
    totalDiscrepancy += discrepancy;
  });
  const detailsColumns = ItemsColumns([
    {
      field: "action",
      headerName: "",
      specialRender: "customRender",
      renderFunction: (params) => (
        <Box
          onClick={() => {
            getPendingDiscrepancy();
          }}
          sx={{ cursor: "pointer" }}
        >
          <img
            src={`../../assets/rotate-left.png`}
            alt="Action"
            style={{ width: 24, height: 24 }}
          />
        </Box>
      ),
    },
    {
      field: "amount",
      headerName: "Ingots amount",
      flex: 2,
    },
    { field: "totalAvailable", headerName: "Available amount", flex: 2 },
    { field: "name", headerName: "Product", flex: 2 },
    {
      field: "serials",
      headerName: "Serial No.",
      specialRender: "customRender",
      renderFunction: (params) => {
        const selectedValues = selectedSerials || [];
        const disabled =
          selectedValues.length === params.row.amount &&
          !selectedValues.includes(params.value);
        return (
          <FormControl
            disabled={params.row.serials.length > 0 ? false : true}
            style={{
              width: "166.8px",
            }}
          >
            <Select
              displayEmpty
              input={<OutlinedInput />}
              MenuProps={MenuProps}
              labelId="serials-dropdown"
              multiple
              value={selectedSerials || ""}
              onChange={handleChange}
              inputProps={{ "aria-label": "Without label" }}
              // renderValue={(selected) => selected.join(", ")}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Select Serials</em>;
                }

                const selectedSerialsNames = params.row.serials
                  .filter((serial) => selected.includes(serial.serial))
                  .map((serial) => serial.serial);
                return selectedSerialsNames.join(", ");
              }}
            >
              {params.row.serials.map((serial) => (
                <MenuItem
                  key={serial.id}
                  value={serial.serial}
                  disabled={disabled && !selectedValues.includes(serial.serial)}
                >
                  <Checkbox
                    checked={selectedSerials?.indexOf(serial.serial) > -1}
                  />
                  <ListItemText primary={serial.serial} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "discrepancy",
      flex: 2,
      specialRender: "customRender",
      renderFunction: (params) => {
        const discrepancy =
          parseFloat(params.row.newTotal) - parseFloat(params.row.total);
        return `${parseFloat(discrepancy)?.toLocaleString()} EGP`;
      },
    },
    {
      field: "Actions",
      specialRender: "customRender",
      renderFunction: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <Typography
              onClick={() => handleSave(params.row)}
              sx={{
                color: "#917244",
                fontSize: "14px",
                fontWeight: "400",
                backgroundColor: "rgba(233, 194, 55, 0.05)",
                padding: "8px 24px",
                borderRadius: "100px",
                cursor: "pointer",
              }}
            >
              Save
            </Typography>
            <Typography
              onClick={() => setSelectedSerials([])}
              sx={{
                color: "#FF453A",
                fontSize: "14px",
                fontWeight: "400",
                backgroundColor: "rgba(242, 48, 69, 0.05)",
                padding: "8px 24px",
                borderRadius: "100px",
                cursor: "pointer",
              }}
            >
              Reset
            </Typography>
          </Box>
        );
      },
    },
  ]);
  const fractionsColumns = ItemsColumns([
    {
      field: "action",
      headerName: "",
      specialRender: "customRender",
      renderFunction: (params) => (
        <Box
          onClick={() => {
            getPendingDiscrepancy();
          }}
          sx={{ cursor: "pointer" }}
        >
          <img
            src={`../../assets/rotate-left.png`}
            alt="Action"
            style={{ width: 24, height: 24 }}
          />
        </Box>
      ),
    },
    { field: "totalAvailable", headerName: "Available amount", flex: 2 },
    { field: "total_weight", headerName: "Amount", flex: 2 },
    {
      field: "serials",
      headerName: "Serial No.",
      flex: 2,
      specialRender: "customRender",
      renderFunction: (params) => {
        const selectedValues = selectedSerials || [];
        const disabled =
          selectedValues.length >= 1 && !selectedValues.includes(params.value);
        return (
          <FormControl
            disabled={params.row.serials.length > 0 ? false : true}
            style={{
              width: "166.8px",
            }}
          >
            <Select
              displayEmpty
              input={<OutlinedInput />}
              MenuProps={MenuProps}
              labelId="serials-dropdown"
              multiple
              value={selectedSerials || ""}
              onChange={handleChange}
              inputProps={{ "aria-label": "Without label" }}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Select Serial</em>;
                }

                const selectedSerialsNames = params.row.serials
                  .filter((serial) => selected.includes(serial.serial))
                  .map((serial) => serial.serial);
                return selectedSerialsNames.join(", ");
              }}
            >
              {params.row.serials.map((serial) => (
                <MenuItem
                  key={serial.id}
                  value={serial.serial}
                  disabled={disabled && !selectedValues.includes(serial.serial)}
                >
                  <Checkbox
                    checked={selectedSerials.indexOf(serial.serial) > -1}
                  />
                  <ListItemText
                    primary={`${serial.serial} - ${serial.weight}`}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "discrepancy",
      specialRender: "customRender",
      flex: 2,
      renderFunction: (params) => {
        const discrepancy =
          parseFloat(params.row.newTotal) - parseFloat(params.row.total);
        return `${parseFloat(discrepancy)?.toLocaleString()} EGP`;
      },
    },
    {
      field: "Actions",
      specialRender: "customRender",
      flex: 2,
      renderFunction: (params) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <Typography
            onClick={() => handleSave(params.row)}
            sx={{
              color: "#917244",
              fontSize: "14px",
              fontWeight: "400",
              backgroundColor: "rgba(233, 194, 55, 0.05)",
              padding: "8px 24px",
              borderRadius: "100px",
              cursor: "pointer",
            }}
          >
            Save
          </Typography>
          <Typography
            onClick={() => setSelectedSerials([])}
            sx={{
              color: "#FF453A",
              fontSize: "14px",
              fontWeight: "400",
              backgroundColor: "rgba(242, 48, 69, 0.05)",
              padding: "8px 24px",
              borderRadius: "100px",
              cursor: "pointer",
            }}
          >
            Cancel
          </Typography>
        </Box>
      ),
    },
  ]);

  useEffect(() => {
    getPendingDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) return <LoadingSpinner />;
  return (
    <Box
      style={{
        overflowY: "auto",
        maxHeight: "calc(100vh - 60px)",
        width: "100%",
      }}
    >
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header
        title={pendingDetails?.amount ? "Order Details" : "Fraction Details"}
        marginL={"40px"}
      />
      <Box
        m="20px"
        sx={{
          display: "flex",
          width: "95%",
          flexDirection: "column",
          gap: 2,
          background: "#F0F0F0",
          alignItems: "center",
          height: "auto",
        }}
      >
        <Box sx={{ dispaly: "flex", width: "95%", marginTop: "3%" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <UserInfoField title="Order ID" data={pendingDetails?.id} />
              <UserInfoField title="Type" data={getTypes()} />
              <UserInfoField
                title="Date"
                data={formDate(pendingDetails?.created)}
              />
            </Box>
            <Divider sx={{ backgroundColor: "#F5F5F5" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <UserInfoField
                title="Username"
                data={pendingDetails?.full_name}
              />
              <UserInfoField
                title="Phone number"
                data={pendingDetails?.phone}
              />
            </Box>
            <Divider />
            {pendingDetails?.amount && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <UserInfoField
                    title="Total Ingots amount"
                    data={`${pendingDetails?.amount} Ingots`}
                  />
                  <UserInfoField
                    title="Total Weight"
                    data={`${pendingDetails?.total_weight} Gm`}
                  />
                </Box>
                <Divider />
              </>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {!pendingDetails?.amount && (
                <UserInfoField
                  title="Total Weight"
                  data={`${pendingDetails?.total_weight} Gm`}
                />
              )}
              <UserInfoField
                title="Deposit"
                data={`${parseFloat(
                  pendingDetails?.deposit
                )?.toLocaleString()} EGP`}
              />
              <UserInfoField
                title="Order new Total"
                data={`${calculateNewTotalSum()} EGP`}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "95%",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <Typography sx={{ color: "#FF453A", fontSize: "14px" }}>
            Discrepancy :{" "}
          </Typography>
          <Typography
            sx={{ color: "#FF453A", marginLeft: "5px", fontSize: "14px" }}
          >
            {parseFloat(totalDiscrepancy)?.toLocaleString()} EGP
          </Typography>
        </Box>

        <Typography
          sx={{
            alignSelf: "flex-start",
            color: "#666666",
            fontSize: "20px",
            fontWeight: "500",
            marginLeft: "2%",
          }}
        >
          {pendingDetails?.amount ? "Ingots Details" : "Details"}
        </Typography>
        <Box
          sx={{
            width: "95%",
            height: "auto",
            background: "#FFF",
            borderRadius: "12px",
            boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08);",
          }}
        >
          <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
            <DataGrid
              components={{
                Footer: () => null,
              }}
              disableColumnMenu={true}
              rows={pendingDetailsData}
              loading={loading}
              columns={
                pendingDetails?.amount ? detailsColumns : fractionsColumns
              }
              getRowHeight={(params) => {
                return 100;
              }}
              getRowId={(row) => row.id}
              autoHeight={true}
            />
          </Box>
        </Box>
      </Box>
      <CustomDialog
        open={successDialogOpen}
        title="Serials Saved Successfuly."
        message=""
        buttonText="Go to Pending Orders"
        buttonLink="/pendingOrders"
      />
      <ReusableSnackbar />
    </Box>
  );
};

export default PendingOrdersDetails;
