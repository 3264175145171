import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Typography, Button, Divider } from "@mui/material";
import { IoIosCloseCircleOutline } from "react-icons/io";
import TextFieldComponent from "../../components/TextFieldComponent";
import { formDate } from "../../services/helpers";

const TransactionsPopup = ({
  openDialog,
  handleCloseDialog,
  invoiceImage,
  transaction,
  handleAccept,
  handleReject,
  amount,
  setAmount,
  reference_number,
  setReferenceNumber,
  isSubmitting,
}) => {
  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };
  const handleReferenceNumberChange = (e) => {
    setReferenceNumber(e.target.value.replace(/[^a-zA-Z0-9-]/g, ""));
  };

  return (
    <Dialog
      maxWidth="1000px"
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          width: "900px",
          height: "auto",
          paddingBottom: "2%",
          borderRadius: "24px",
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            alignSelf: "flex-end",
            marginTop: "5px",
            float: "right",
          }}
        >
          <IoIosCloseCircleOutline
            onClick={handleCloseDialog}
            color="#4C4C4C"
            style={{
              width: "24px",
              height: "24px",
              cursor: "pointer",
            }}
          />
        </Box>
        <Box sx={{ marginTop: "5%", marginLeft: "5%", marginBottom: "2%" }}>
          {transaction?.type === "Withdraw" ? (
            <Typography
              sx={{ fontSize: "24px", fontWeight: "500", color: "#666666" }}
            >
              Withdraw
            </Typography>
          ) : (
            <Typography
              sx={{ fontSize: "24px", fontWeight: "500", color: "#666666" }}
            >
              Deposit
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            justifyContent: "space-evenly",
            gap: 3,
          }}
        >
          {transaction?.invoice && (
            <div
              style={{
                height: "350px",
                width: "350px",
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                src={invoiceImage}
                alt=""
                // crossOrigin="anonymous"
              />
            </div>
          )}

          {transaction?.type === "Deposit" && (
            <Box
              sx={{ width: "90%", display: "flex", flexDirection: "column" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "90%",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#808080",
                  }}
                >
                  User ID: {transaction?.user_id}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#808080",
                  }}
                >
                  Transaction ID: {transaction?.id}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#808080",
                  }}
                >
                  Payment Method: {transaction?.paymentMethod}
                </Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#808080",
                  }}
                >
                  Transaction Amount: {transaction?.amount}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#808080",
                  }}
                >
                  Created At : {formDate(transaction?.created)}
                </Typography>
                {transaction?.modified && (
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      color: "#808080",
                    }}
                  >
                    Updated At : {formDate(transaction?.modified)}
                  </Typography>
                )}
              </Box>
            </Box>
          )}
          {transaction?.type === "Withdraw" &&
          transaction?.paymentMethod !== "instaPay" ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                alignSelf: "flex-start",
              }}
            >
              <Box
                sx={{
                  marginLeft: "5%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "90%",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#808080",
                    width: "50%",
                  }}
                >
                  Transaction ID: {transaction?.id}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#808080",
                  }}
                >
                  User Phone number: {transaction?.phone}
                </Typography>
              </Box>
              <Divider sx={{ my: 2, width: "90%", marginLeft: "5%" }} />
              <Box
                sx={{
                  marginLeft: "5%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "90%",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#808080",
                    width: "50%",
                  }}
                >
                  Payment Method: {transaction?.paymentMethod}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#808080",
                  }}
                >
                  Bank Name: {transaction?.bankName}
                </Typography>
              </Box>
              <Divider sx={{ my: 2, width: "90%", marginLeft: "5%" }} />
              <Box
                sx={{
                  marginLeft: "5%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "90%",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#808080",
                    width: "50%",
                  }}
                >
                  Full name: {transaction?.full_name}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#808080",
                  }}
                >
                  Account Number: {transaction?.account}
                </Typography>
              </Box>
              <Divider sx={{ my: 2, width: "90%", marginLeft: "5%" }} />
              <Box
                sx={{
                  marginLeft: "5%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "90%",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#808080",
                    width: "50%",
                  }}
                >
                  IBAN: {transaction?.iban}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#808080",
                  }}
                >
                  Swift Code: {transaction?.swiftCode}
                </Typography>
              </Box>
              <Divider sx={{ my: 2, width: "90%", marginLeft: "5%" }} />
              <Box
                sx={{
                  marginLeft: "5%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  // justifyContent: "space-between",
                  width: "90%",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#808080",
                    width: "50%",
                  }}
                >
                  Transaction Amount: {transaction?.amount} EGP
                </Typography>
              </Box>
              <Divider sx={{ my: 2, width: "90%", marginLeft: "5%" }} />
              <Box
                sx={{
                  marginLeft: "5%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  // justifyContent: "space-between",
                  width: "90%",
                }}
              >
                {transaction?.modified && (
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      color: "#808080",
                      width: "50%",
                    }}
                  >
                    Updated At: {formDate(transaction?.modified)}
                  </Typography>
                )}

                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#808080",
                  }}
                >
                  Created At: {formDate(transaction?.created)}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                alignSelf: "flex-start",
              }}
            >
              <Box
                sx={{
                  marginLeft: "5%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "90%",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#808080",
                    width: "50%",
                  }}
                >
                  Transaction ID: {transaction?.id}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#808080",
                  }}
                >
                  User Phone number: {transaction?.phone}
                </Typography>
              </Box>
              <Divider sx={{ my: 2, width: "90%", marginLeft: "5%" }} />
              <Box
                sx={{
                  marginLeft: "5%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "90%",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#808080",
                    width: "50%",
                  }}
                >
                  Payment Method: {transaction?.paymentMethod}
                </Typography>
              </Box>
              <Divider sx={{ my: 2, width: "90%", marginLeft: "5%" }} />
              <Box
                sx={{
                  marginLeft: "5%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "90%",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#808080",
                    width: "50%",
                  }}
                >
                  IPA: {transaction?.account}
                </Typography>
              </Box>
              <Divider sx={{ my: 2, width: "90%", marginLeft: "5%" }} />
              <Box
                sx={{
                  marginLeft: "5%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "90%",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#808080",
                    width: "50%",
                  }}
                >
                  Transaction Amount: {transaction?.amount} EGP
                </Typography>
              </Box>
              <Divider sx={{ my: 2, width: "90%", marginLeft: "5%" }} />
              <Box
                sx={{
                  marginLeft: "5%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "90%",
                }}
              >
                {transaction?.modified && (
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      color: "#808080",
                      width: "50%",
                    }}
                  >
                    Updated At: {formDate(transaction?.modified)}
                  </Typography>
                )}

                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#808080",
                  }}
                >
                  Created At: {formDate(transaction?.created)}
                </Typography>
              </Box>
            </Box>
          )}
          {transaction?.status_id === "Pending" && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  width: "95%",
                }}
              >
                <TextFieldComponent
                  sx={{ "& fieldset": { border: "none" } }}
                  style={{
                    height: "48px",
                    width: "380px",
                    borderRadius: "9px",
                    backgroundColor: "#F7F7F7",
                  }}
                  name="Confirm payment amount"
                  placeholder="Enter amount"
                  value={amount}
                  onChange={handleAmountChange}
                />
                <TextFieldComponent
                  sx={{ "& fieldset": { border: "none" } }}
                  style={{
                    height: "48px",
                    width: "380px",
                    borderRadius: "9px",
                    backgroundColor: "#F7F7F7",
                  }}
                  name="Reference Number "
                  placeholder="Enter Reference Number"
                  value={reference_number}
                  onChange={handleReferenceNumberChange}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  gap: 2,
                  paddingBottom: "20px",
                }}
              >
                <Button
                  disabled={isSubmitting}
                  sx={{
                    width: "200px",
                    height: "48px",
                    borderRadius: "12px",
                    background: "#917244",
                    color: "#FFF",
                    fontSize: "18px",
                    "&:hover": {
                      background: "#917244",
                    },
                    textTransform: "none",
                  }}
                  onClick={() => {
                    handleAccept(amount, reference_number);
                  }}
                >
                  Approve
                </Button>
                <Button
                  disabled={isSubmitting}
                  variant="outlined"
                  sx={{
                    width: "200px",
                    height: "48px",
                    borderRadius: "12px",
                    // background: "#CF2424",
                    "&:hover": {
                      borderColor: "#917244",
                      background: "#fff",
                    },
                    borderColor: "#917244",
                    color: "#917244",
                    fontSize: "18px",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    handleReject();
                  }}
                >
                  Reject
                </Button>
              </Box>
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TransactionsPopup;
