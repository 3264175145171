import { Box, Grid, Typography } from "@mui/material";
import BlockedUsersChart from "./BlockedUsersChart";
import UserOverviewMainCard from "../../components/UserOverviewMainCard";
import { useState } from "react";
import DashboardDashedChart from "./DashboardDashedChart";
import DashboardLineChart from "./DashboardLineChart";
import OverViewMainCard from "../../components/OverViewMainCard";

const GeneralStatisticsTabPanel = () => {
  const today = new Date();
  const date14DaysAgo = new Date();
  date14DaysAgo.setDate(today.getDate() - 14);
  const formattedToday = today.toLocaleDateString();
  const formattedDate14DaysAgo = date14DaysAgo.toLocaleDateString();
  const [queryParameters, setQueryParameters] = useState({
    date_from: formattedDate14DaysAgo,
    date_to: formattedToday,
  });

  return (
    <Box>
      <Box>
        <Typography
          sx={{ color: "#808080", fontSize: "16px", fontWeight: "500" }}
        >
          OverView
        </Typography>
      </Box>
      {/* users overview */}

      <Grid container spacing={1.5} pt={4}>
        {/* Main Cards Start  */}
        <Grid item lg={4} xs={12} sm={12} md={4} p={1}>
          <OverViewMainCard
            backgroundColor="#FFFFFF"
            title="Total Users"
            total={"500"}
            value={"User"}
          />
        </Grid>
        <Grid item lg={4} xs={12} sm={12} md={4} p={1}>
          <OverViewMainCard
            subtext={"From last month"}
            backgroundColor="#FFFFFF"
            title="New Users"
            total={"521,562"}
            value={"User"}
            arrow={<img alt="icon" src={`../../assets/arrow-up.png`} />}
            perc={`+${"20"} %`}
            perccolor={"#917244"}
            percbackground={"rgba(233, 194, 55, 0.1)"}
          />
        </Grid>
        <Grid item lg={4} xs={12} sm={12} md={4} p={1}>
          <OverViewMainCard
            subtext={"From last month"}
            backgroundColor="#FFFFFF"
            title="Login Frequency"
            total={"98.5%"}
            value={""}
            arrow={<img alt="icon" src={`../../assets/arrow-up.png`} />}
            perc={`+${"20"} %`}
            perccolor={"#917244"}
            percbackground={"rgba(233, 194, 55, 0.1)"}
          />
        </Grid>
        {/* End Main Cards  */}

        {/*  Peak Purchase Days and Times Start  */}
        <Grid mt={2} item xs={11.8} md={11.8} lg={11.8}>
          <DashboardDashedChart
            isfilter={"filter"}
            height={440}
            width={"100%"}
            style={{
              boxShadow: "0px 0px 0px #D3D3D3",
              borderRadius: "24px",
            }}
            title=" Peak Purchase Days and Times"
            queryParameters={queryParameters}
            setQueryParameters={setQueryParameters}
            chart={{
              labels: [
                "Saturday",
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
              ],
              series: [
                {
                  name: "Online",
                  type: "area",
                  fill: "gradient",
                  data: [23, 40, 37, 46, 50, 30, 38],
                  color: "#917244",
                },
              ],
            }}
          />
        </Grid>
        {/* End  Peak Purchase Days and Times  */}

        {/*Age line chart and gender Start */}
        <Grid mt={1.4} item xs={12} md={12} lg={7.7}>
          <DashboardLineChart
            height={288}
            width={"100%"}
            style={{
              boxShadow: "0px 0px 0px #D3D3D3",
              borderRadius: "24px",
            }}
            changeButtonWidth={"1"}
            title="Age of Buyers"
            queryParameters={queryParameters}
            setQueryParameters={setQueryParameters}
            chart={{
              labels: [
                "(18-24)",
                "(25-30)",
                "(31-40)",
                "(41-45)",
                "(46-55)",
                "(Over 55)",
              ],
              series: [
                {
                  name: "Male",
                  type: "column",
                  fill: "solid",
                  data: [23, 44, 22, 35, 28, 22],
                  color: "#917244",
                },
                {
                  name: "Female",
                  type: "column",
                  fill: "solid",
                  data: [32, 33, 41, 30, 27, 37],
                  color: "#FBF3D7",
                },
              ],
            }}
          />
        </Grid>
        <Grid
          item
          mt={3}
          sx={{
            backgroundColor: "#ffffff",
            height: "450px",
            borderRadius: "24px",
            marginLeft: "2%",
          }}
          xs={12}
          md={12}
          lg={4}
        >
          <BlockedUsersChart
            type="donut"
            sx={{ borderRadius: "24px", boxShadow: "none" }}
            title="Gender Distribution of Buyers"
            chart={{
              series: [
                {
                  label: "Male",
                  value: 65,
                },
                {
                  label: "Female",
                  value: 30,
                },
              ],
            }}
            labelTitle={"Total Users"}
            labelNumber={"5,025,024"}
          />
        </Grid>
        {/* End Age line chart and gender  */}
        {/* avg session duration start */}
        <Grid
          item
          mt={1.5}
          sx={{
            height: "448px",
            marginLeft: "1%",
          }}
          xs={12}
          md={12}
          lg={4}
        >
          <Grid item lg={12} xs={12} md={12}>
            <UserOverviewMainCard
              backgroundColor="#FFFFFF"
              title="Avg. Session Duration"
              total={"3,805.30"}
              value={"EGP"}
              arrow={<img alt="icon" src={`../../assets/arrow-up.png`} />}
              perc={`+${"10"} %`}
              perccolor={"#917244"}
              percbackground={"rgba(233, 194, 55, 0.1)"}
            />
            <Grid mt={2} item lg={12} xs={12} md={12}>
              <UserOverviewMainCard
                backgroundColor="#FFFFFF"
                title="Number of sessions per user"
                total={"3,805.25"}
                value={"EGP"}
                arrow={<img alt="icon" src={`../../assets/arrow-up.png`} />}
                perc={`+${"10"} %`}
                perccolor={"#917244"}
                percbackground={"rgba(233, 194, 55, 0.1)"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid mt={1.5} item xs={12} md={12} lg={7.8}>
          <DashboardDashedChart
            isfilter={"filter"}
            height={408}
            width={"100%"}
            style={{
              boxShadow: "0px 0px 0px #D3D3D3",
              borderRadius: "24px",
            }}
            changeButtonWidth={"1"}
            title="Average Session Duration"
            queryParameters={queryParameters}
            setQueryParameters={setQueryParameters}
            chart={{
              labels: [
                "Saturday",
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
              ],
              series: [
                {
                  name: "Online",
                  type: "area",
                  fill: "gradient",
                  data: [23, 40, 37, 46, 50, 30, 38],
                  color: "#917244",
                },
              ],
            }}
          />
        </Grid>
        {/* end avg session duration  */}
        <Grid mt={2} item xs={11.8} md={11.8} lg={11.8}>
          <DashboardDashedChart
            isfilter={"filter"}
            height={440}
            width={"100%"}
            style={{
              boxShadow: "0px 0px 0px #D3D3D3",
              borderRadius: "24px",
            }}
            title="User Engagement"
            queryParameters={queryParameters}
            setQueryParameters={setQueryParameters}
            chart={{
              labels: [
                "Saturday",
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
              ],
              series: [
                {
                  name: "Online",
                  type: "area",
                  fill: "gradient",
                  data: [23, 40, 37, 46, 50, 30, 38],
                  color: "#917244",
                },
              ],
            }}
          />
        </Grid>
      </Grid>

      {/* end users overview */}
    </Box>
  );
};
export default GeneralStatisticsTabPanel;
