import React from "react";
import { Snackbar, Alert } from "@mui/material";
import { useSelector } from "react-redux";
import { useCloseSnack } from "../components/common/useCloseSnack";
const ReusableSnackbar = ({ autoHideDuration = 2000 }) => {
  const handleCloseSnack = useCloseSnack();

  const {
    message,
    severity,
    variant,
    openSnack,
    horizontal = "right",
  } = useSelector((state) => state.errorMessage);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: horizontal }}
      open={openSnack}
      autoHideDuration={autoHideDuration}
      onClose={handleCloseSnack}
    >
      <Alert
        onClose={handleCloseSnack}
        severity={severity}
        variant={variant}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ReusableSnackbar;
