// import { useMutation, useQuery, useQueryClient } from "react-query";
// import { axiosInstance as axios } from "../config";

// // Fetch Terms Hook
// export const useFetchTerms = () => {
//   return useQuery(["terms"], async () => {
//     const response = await axios.get(`/api/terms`);
//     return response.data; // Return the data directly
//   });
// };

// // Add Term Hook
// export const useAddTerm = () => {
//   const queryClient = useQueryClient();

//   return useMutation(
//     async (body) => {
//       const response = await axios.post(`/api/terms/add`, body);
//       return response.data;
//     },
//     {
//       onSuccess: () => {
//         // Invalidate the query to refetch the terms
//         queryClient.invalidateQueries(["terms"]);
//       },
//     }
//   );
// };

// // Edit Term Hook
// export const useEditTerm = () => {
//   const queryClient = useQueryClient();

//   return useMutation(
//     async ({ id, body }) => {
//       const response = await axios.post(`/api/terms/edit/${id}`, body);
//       return response.data;
//     },
//     {
//       onSuccess: () => {
//         queryClient.invalidateQueries(["terms"]);
//       },
//     }
//   );
// };

// // Delete Term Hook
// export const useDeleteTerm = () => {
//   const queryClient = useQueryClient();

//   return useMutation(
//     async (id) => {
//       const response = await axios.get(`/api/terms/delete/${id}`);
//       return response.data;
//     },
//     {
//       onSuccess: () => {
//         queryClient.invalidateQueries(["terms"]);
//       },
//     }
//   );
// };

import { useMutation, useQuery } from "react-query";
import { axiosInstance as axios } from "../config";

const getTermsUs = async () => {
  try {
    const response = await axios.get(`/api/config/terms`);
    return response.data.data; // Assuming the data you need is in the 'data' property
  } catch (error) {
    throw error;
  }
};
export const useTermsUs = () => {
  return useQuery("terms", getTermsUs, {
    refetchOnWindowFocus: false,
  });
};
const editTermsUs = async ({ id, body }) => {
  try {
    const response = await axios.post(`/api/config/edit/${id}`, body);
    return response.data; // Assuming the data you need is in the 'data' property
  } catch (error) {
    throw error;
  }
};
export const useEditTermsUs = () => {
  return useMutation(editTermsUs);
};
