import React, { useState, useEffect } from "react";
import useUserFilterData from "../../zustand/useUserFilterData";

const UserActiveStatusFilter = () => {
  const [selectedStatus, setSelectedStatus] = useState([]);

  const { user_groups, setUserGroups } = useUserFilterData();
  const filterList = [
    { id: "all", name: "All" },
    { id: "active", name: "Active" },
    { id: "blocked", name: "Blocked" },
  ];

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setUserGroups([...user_groups, value]);
    } else {
      setUserGroups(user_groups.filter((status) => status !== value));
    }
  };

  return (
    <div className="mt-4">
      <p className="text-[#404040] font-medium font-ibmArabic text-lg mb-4">
        User Status
      </p>
      <div className="grid grid-cols-3 gap-4">
        {filterList.map((item) => (
          <div key={item.id} className="flex items-center gap-2">
            <input
              className="text-[#595959] size-4 cursor-pointer"
              type="checkbox"
              id={item.id}
              name="documentStatus"
              value={item.id}
              checked={user_groups.includes(item.id)}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor={item.id} // Fixed to 'htmlFor' for JSX
              className="text-[#595959] font-medium font-ibmArabic text-lg"
            >
              {item.name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserActiveStatusFilter;
