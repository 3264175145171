import React from "react";
import notAuthorized from "../../svg/not-authorized.svg";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
const NotAuthorized = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center mt-48">
      <img src={notAuthorized} alt="not authorized" />
      <p className="text-[#917244] text-5xl font-medium my-2">Access Denied</p>
      <p className="text-[#595959] text-lg mt-2 mb-8">
        You are not authorized to view this page. Please contact your
        administrator if you believe this is an error or to request the
        necessary permissions.
      </p>
      <Button
        onClick={() => {
          navigate("/AdminProfile");
        }}
        sx={{
          color: "white",
          width: "200px",
          background: "#917244",
          padding: 2,
          borderRadius: "12px",
          fontSize: "16px",
          "&:hover": {
            background: "#917244",
          },
        }}
      >
        Go to profile
      </Button>
    </div>
  );
};

export default NotAuthorized;
