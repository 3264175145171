import React from "react";
import {
  Button,
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  Select,
  MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  addNewAdminRole,
  useFetchRoles,
} from "../../services/apis/roles-and-permissions";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { setData } from "../../store/slices/errorMessageSlice";
import { useDispatch } from "react-redux";
import { MenuProps } from "../../components/common/MenuProps";

const EditRoleModal = ({
  open,
  onClose,
  label,
  selectedAdminId,
  admins,
  onSuccess,
}) => {
  const [adminSelectedRoles, setAdminSelectedRoles] = useState(null);
  const selectedAdmin = admins.find((admin) => admin.id === selectedAdminId);
  const handleChange = (event) => {
    setAdminSelectedRoles(event.target.value);
  };
  const { data: adminRoles } = useFetchRoles();

  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedAdminId && adminSelectedRoles) {
      try {
        const payload = {
          user_group_id: adminSelectedRoles.id,
        };
        await addNewAdminRole(selectedAdminId, payload);
        onSuccess();
      } catch (error) {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      } finally {
        handleClose();
      }
    }
  };
  return (
    <>
      <Dialog
        maxWidth="721px"
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        borderRadius="24px"
        sx={{ borderRadius: "24px" }}
      >
        <DialogContent
          sx={{
            height: "450px",
            width: "600px",
            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              width: "100%",
              height: "auto",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={onClose}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box sx={{ width: "75%" }}>
              <Box>
                <Typography sx={{ color: "#4C4C4C", fontSize: "20px" }}>
                  Edit Admin Role
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#4C4C4C",
                  marginBottom: "5px",
                  marginTop: "15px",
                }}
              >
                Select Admin Role{" "}
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  displayEmpty
                  value={adminSelectedRoles || ""}
                  onChange={handleChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return <em>Select Admin Role</em>;
                    }
                    return selected?.name;
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {adminRoles
                    ?.filter((item) => item.type === 0)
                    .map((item) => (
                      <MenuItem key={item.id} value={item}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Box sx={{ marginTop: "5%" }}>
                <Box
                  sx={{
                    marginTop: "5%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#808080",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Name :
                  </Typography>
                  <Typography
                    sx={{
                      color: "#808080",
                      fontSize: "14px",
                      fontWeight: "400",
                      marginLeft: "5px",
                    }}
                  >
                    {selectedAdmin?.full_name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginTop: "5%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#808080",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    E-mail :
                  </Typography>
                  <Typography
                    sx={{
                      color: "#808080",
                      fontSize: "14px",
                      fontWeight: "400",
                      marginLeft: "5px",
                    }}
                  >
                    {selectedAdmin?.email}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: 2, marginTop: "10px" }}>
              <Button
                variant="contained"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  background: "#917244",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#917244",
                  },
                }}
                onClick={handleSubmit}
              >
                {label}
              </Button>

              <Button
                variant="outlined"
                autoCapitalize="false"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#F23045",
                  fontWeight: 600,
                  borderColor: "#F23045",
                  "&:hover": {
                    borderColor: "#F23045",
                  },
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <ReusableSnackbar />
    </>
  );
};

export default EditRoleModal;
