import React from "react";
import {
  Button,
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  Avatar,
  Select,
  MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import TextFieldComponent from "../../components/TextFieldComponent";
import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  addNewAdmin,
  useFetchRoles,
} from "../../services/apis/roles-and-permissions";
import CustomDialog from "../../components/CustomDialog";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { MenuProps } from "../../components/common/MenuProps";

const AddNewAdminModal = ({ open, onClose, label }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [newAdminPayload, setNewAdminPayload] = useState({
    email: "",
    user_group_id: null,
  });
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const { data: adminRoles } = useFetchRoles();

  const resetForm = () => {
    setNewAdminPayload({
      email: "",
      user_group_id: null,
    });
  };
  const handleClose = () => {
    resetForm();
    onClose();
  };
  const handleCloseSucessDialog = async () => {
    setOpenDialog(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsSubmitting(true);
      const userGroupId = newAdminPayload?.user_group_id?.id;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (
        newAdminPayload.email &&
        emailRegex.test(newAdminPayload.email) &&
        userGroupId
      ) {
        const payloadToSend = {
          email: newAdminPayload.email,
          user_group_id: userGroupId,
        };
        await addNewAdmin(payloadToSend);
        handleClose();
        setSuccessDialogOpen(true);
      } else {
        dispatch(
          setData({
            openSnack: true,
            message: "Please provide a valid email and Select Admin Role",
          })
        );
      }
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            padding: { xs: "16px", sm: "40px" },
            height: "auto",
            width: "100%",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              width: "100%",
              height: "auto",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={onClose}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box>
                <Typography sx={{ color: "#4C4C4C", fontSize: "20px" }}>
                  Add Admin
                </Typography>
              </Box>

              <TextFieldComponent
                sx={{ "& fieldset": { border: "none" } }}
                style={{
                  marginTop: "10px",
                  height: "48px",
                  width: "100%",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "9px",
                }}
                name="Enter Admin Email"
                placeholder="Enter Admin Email"
                value={newAdminPayload.email}
                onChange={(event) => {
                  var temppayload = { ...newAdminPayload };
                  temppayload.email = event.target.value;
                  setNewAdminPayload({ ...temppayload });
                }}
              />
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#4C4C4C",
                  marginBottom: "5px",
                  marginTop: "15px",
                }}
              >
                Select Admin Role
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  displayEmpty
                  value={newAdminPayload?.user_group_id || ""}
                  // onChange={handleChange}
                  onChange={(event) => {
                    var temppayload = { ...newAdminPayload };
                    temppayload.user_group_id = event.target.value;
                    setNewAdminPayload({ ...temppayload });
                  }}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return <em>Select Admin Role</em>;
                    }
                    return selected?.name;
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {adminRoles
                    ?.filter((item) => item.type === 0)
                    .map((item) => (
                      <MenuItem key={item.id} value={item}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                marginTop: "10px",
                flexDirection: { xs: "column", sm: "row" },
                width: "100%",
              }}
            >
              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={{
                  width: "100%",
                  borderRadius: "9px",
                  background: "#917244",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#917244",
                  },
                }}
                onClick={handleSubmit}
              >
                {label}
              </Button>

              <Button
                variant="outlined"
                autoCapitalize="false"
                sx={{
                  width: "100%",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#917244",
                  fontWeight: 600,
                  borderColor: "#917244",
                  "&:hover": {
                    borderColor: "#917244",
                  },
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDialog}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: {
            width: "585px",
            height: "506px",
            borderRadius: "24px",
          },
        }}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogContent
          sx={{
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            sx={{
              bgcolor: "#D9F4DD",
              height: "80px",
              width: "80px",
              alignSelf: "center",
              marginRight: "15px",
            }}
          >
            <img src={`../../assets/Vector.png`} alt="vector" />
          </Avatar>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                marginTop: "10%",
                color: "#4C4C4C",
                fontSize: "24px",
                width: "75%",
              }}
              variant="h6"
            >
              Admin has been added Successfully.
            </Typography>
            <Typography
              sx={{
                marginBottom: "10%",
                color: "#4C4C4C",
                fontSize: "16px",
                width: "60%",
              }}
              variant="h6"
            >
              We have send registration mail to admin make sure that he check
              his mail
            </Typography>
          </Box>

          <Button
            onClick={handleCloseSucessDialog}
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#917244",
              "&:hover": { backgroundColor: "#917244" },
              height: "46px",
              width: "291px",
              borderRadius: "9px",
            }}
          >
            Go to Admins
          </Button>
        </DialogContent>
      </Dialog>
      <ReusableSnackbar />
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Email has been Sent Successfully."
        message=""
        buttonText="Go to Admins"
      />
    </>
  );
};

export default AddNewAdminModal;
