import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React from "react";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { useState } from "react";
import StepOneComponent from "./StepOneComponent";
import StepTwoComponent from "./StepTwoComponent";
import StepThreeComponent from "./StepThreeComponent";
import {
  addNewRole,
  useFetchForAddRole,
} from "../../services/apis/roles-and-permissions";
import StepFourComponent from "./StepFourComponent";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { useStylesHook } from "../../components/common/useStyles";
const steps = [
  "Enter Role Details",
  "Select Resource Group to Role",
  "Add Permissions to Role",
  "Finish",
];

const AddNewRole = () => {
  const c = useStylesHook();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [selectedCheckboxName, setSelectedCheckboxName] = useState([]);
  const [selectedCheckboxPermissions, setSelectedCheckboxPermissions] =
    useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const { data } = useFetchForAddRole();
  const [roleData, setRoleData] = useState({
    name: "",
    description: "",
    user_group_type_id: "",
    pages: [],
    permissions: [],
  });
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const dispatch = useDispatch();

  const handleNext = () => {
    if (activeStep === 0) {
      if (
        !roleData.name ||
        !roleData.user_group_type_id ||
        !roleData.description
      ) {
        dispatch(
          setData({
            openSnack: true,
            message: "Please fill in all required fields.",
          })
        );
        return;
      }
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleSave = async () => {
    try {
      await addNewRole(roleData);
      setOpenDialog(true);
    } catch (error) {
      if (error.response.status === 422) {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.validation.name,
          })
        );
      } else {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      }
    }
  };
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <StepOneComponent
            roleData={roleData}
            setRoleData={setRoleData}
            setSelectedCategoryName={setSelectedCategoryName}
          />
        );
      case 1:
        return (
          <StepTwoComponent
            resource={data?.data}
            roleData={roleData}
            setRoleData={setRoleData}
            setSelectedCheckboxName={setSelectedCheckboxName}
          />
        );
      case 2:
        return (
          <StepThreeComponent
            resource={data?.data}
            roleData={roleData}
            setRoleData={setRoleData}
            setSelectedCheckboxPermissions={setSelectedCheckboxPermissions}
          />
        );
      case 3:
        return (
          <StepFourComponent
            categoryName={selectedCategoryName}
            selectedCheckboxName={selectedCheckboxName}
            selectedCheckboxPermissions={selectedCheckboxPermissions}
            roleData={roleData}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Box>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <Box sx={{ mb: "3%" }}>
          <DashboardMainHeader />
        </Box>

        <Header title="Add New Role" />
      </Box>
      <Box
        m="20px"
        sx={{
          height: "70vh",
          background: "#FFFFFF",
          borderRadius: "12px",
          boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08);",
          overflow: "auto",
          padding: "4%",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Stepper className={c.root} activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <>
            {renderStepContent(activeStep)}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              {activeStep !== 0 && (
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#ffffff",
                    },
                    backgroundColor: "#ffffff",
                    width: "200px",
                    height: "46px",
                    borderRadius: "12px",
                    color: "#917244",
                    border: "3px solid #917244",
                    fontSize: "14px",
                    marginRight: "20px",
                  }}
                >
                  Back
                </Button>
              )}
              <Button
                disabled={
                  activeStep === 0 &&
                  (!roleData.name ||
                    !roleData.user_group_type_id ||
                    !roleData.description)
                }
                sx={{
                  "&:hover": { backgroundColor: "#917244" },
                  backgroundColor: "#917244",
                  width: "200px",
                  height: "46px",
                  borderRadius: "12px",
                  color: "#ffffff",

                  fontSize: "14px",
                }}
                onClick={
                  activeStep === steps.length - 1 ? handleSave : handleNext
                }
              >
                {activeStep === steps.length - 1 ? "Save" : "Next"}
              </Button>
            </Box>
          </>
        </Box>
      </Box>
      <Dialog
        open={openDialog}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: {
            width: "585px",
            height: "506px",
            borderRadius: "24px",
          },
        }}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogContent
          sx={{
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            sx={{
              bgcolor: "#D9F4DD",
              height: "80px",
              width: "80px",
              alignSelf: "center",
              marginRight: "15px",
            }}
          >
            <img src={`../../assets/Vector.png`} alt="vector" />
          </Avatar>
          <Typography
            sx={{ marginTop: "10%", marginBottom: "10%" }}
            variant="h6"
          >
            Role has been added Successfully.
          </Typography>
          <Button
            onClick={() => {
              navigate("/add-new-role");
            }}
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#917244",
              "&:hover": { backgroundColor: "#917244" },
              height: "46px",
              width: "291px",
              borderRadius: "9px",
            }}
          >
            Go to Roles
          </Button>
        </DialogContent>
      </Dialog>
      <ReusableSnackbar />
    </Box>
  );
};

export default AddNewRole;
