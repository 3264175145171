import { useEffect, useRef } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addReplay,
  adminChangeStatus,
  fetchAssignedAdmins,
  reassign,
  useTicketDetails,
} from "../../services/apis/tickets";
import { formDate } from "../../services/helpers";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import CloseTicketDialog from "./CloseTicketDialog";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { MenuProps } from "../../components/common/MenuProps";
import NewTabs from "../../components/common/NewTabs";
import StatusSelect from "./StatusSelect";
import FirstTicketPanel from "./TicketPanels/FirstTicketPanel";
import SecondTicketPanel from "./TicketPanels/SecondTicketPanel";
import ThirdTicketPanel from "./TicketPanels/ThirdTicketPanel";
import ForthTicketPanel from "./TicketPanels/ForthTicketPanel";
import { Skeleton } from "@mui/material";
const TicketsDetails = () => {
  const [AssignedAdmins, setAssignedAdmins] = useState([]);
  const location = useLocation();
  const ticketsdetails = location.state.ticketsdetails;
  const { data, isLoading, refetch } = useTicketDetails(ticketsdetails?.id);
  const [latestDate, setLatestData] = useState("");
  useEffect(() => {
    const data1Date = new Date(
      data?.replies[data?.replies.length - 1]?.created
    );
    const data2Date = new Date(
      data?.history[data?.history.length - 1]?.created
    );

    // Compare the two dates
    const theLatestDate = data1Date > data2Date ? data1Date : data2Date;
    setLatestData(formDate(theLatestDate));
  }, [data]);
  const [showReassignText, setShowReassignText] = useState(false);
  const handleReassignClick = () => {
    setShowReassignText(true);
  };
  const [openBlockedAlert, setOpenBlockedAlert] = useState(false);
  const [value, setValue] = useState("1");
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();

  const tabs = [
    {
      label: "Ticket INFO",
      value: "1",
    },
    {
      label: "Replies",
      value: "2",
    },
    {
      label: "user info",
      value: "3",
    },
    {
      label: "ticket history",
      value: "4",
    },
  ];
  const ITEM_HEIGHTS = 36;
  const ITEM_PADDING_TOPS = 8;
  const MenuItemProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHTS * 4.5 + ITEM_PADDING_TOPS,
        width: 120,
        backgroundColor: "#FAFAFA",
        border: 0,
      },
    },
  };
  const [selectedTopic, setSelectedTopic] = useState(null);
  const handleTopicChange = async (event) => {
    try {
      const selectedId = event.target.value.id;
      const payload = {
        id: ticketsdetails?.id,
        admin_id: selectedId,
      };
      await reassign(payload);
      setSelectedTopic(event.target.value);
      setShowReassignText(false);
      refetch(ticketsdetails?.id);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const containerRef = useRef(null);

  const scrollToBottom = () => {
    // Scroll to the bottom of the container
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    if (value === "2") {
      // Scroll to the bottom when component mounts or when data changes
      scrollToBottom();
    }
  }, [data, value]);

  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(`/tickets/${data?.user_id}`);
  };

  const getAssignedAdmins = async () => {
    setAssignedAdmins([]);
    try {
      const response = await fetchAssignedAdmins();
      setAssignedAdmins(response.data.data);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const [formData, setFormData] = useState({
    id: ticketsdetails?.id,
    reply: "",
  });
  const handleSave = async () => {
    try {
      if (!formData.reply.trim()) {
        dispatch(
          setData({
            openSnack: true,
            message: "Reply cannot be empty.",
          })
        );
        return;
      }
      await addReplay(formData);
      refetch(ticketsdetails?.id);
      setFormData({
        id: ticketsdetails?.id,
        reply: "",
      });
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && formData.reply.trim() !== "") {
      handleSave();
    }
  };
  useEffect(() => {
    getAssignedAdmins();
  }, []);

  const [selectedStatus, setSelectedStatus] = useState(null);
  const handleStatusChange = async (event) => {
    const selectedStatusId = event.target.value.id;
    if (selectedStatusId === 4) {
      handleOpenBlockAlert();
    } else {
      try {
        const selectedId = event.target.value.id;
        const payload = {
          id: ticketsdetails?.id,
          status: selectedId,
        };
        await adminChangeStatus(payload);
        setSelectedStatus(event.target.value);
        refetch(ticketsdetails?.id);
      } catch (error) {
        if (error.response.status === 422) {
          dispatch(
            setData({
              openSnack: true,
              message: error.response.data.validation.status,
            })
          );
        } else {
          dispatch(
            setData({
              openSnack: true,
              message: error.response.data.message,
            })
          );
        }
      }
    }
  };
  const Status = [
    { id: 1, name: "Open" },
    { id: 2, name: "Pending" },
    { id: 3, name: "Resolved" },
    { id: 4, name: "Close" },
  ];
  const handleOpenBlockAlert = (e) => {
    setOpenBlockedAlert(true);
  };

  const handleCloseBlockAlert = () => {
    setOpenBlockedAlert(false);
  };

  return (
    <div className="overflow-y-auto " style={{ height: "100vh" }}>
      <CloseTicketDialog
        getTickets={() => {
          refetch(ticketsdetails?.id);
        }}
        selectedClosedStatusId={ticketsdetails?.id}
        openAlert={openBlockedAlert}
        handleCloseAlert={handleCloseBlockAlert}
        message={`Are you sure you want to Close this Ticket ?`}
        label={"Confirm"}
      />
      <div className="ml-5 mb-[1.875rem]">
        <DashboardMainHeader />
      </div>
      <div className="w-[95%] mx-auto pb-20">
        <div className={`bg-white rounded-3xl ${value !== 2 ? "p-6" : ""}`}>
          <div className="flex items-center justify-between px-6 pt-6">
            <p className="text-[#202020] text-2xl ">
              {isLoading ? (
                <Skeleton sx={{ width: 200 }} />
              ) : (
                ticketsdetails?.full_name
              )}
            </p>

            {isLoading ? (
              <Skeleton sx={{ width: 200 }} />
            ) : data?.status !== 4 ? (
              <StatusSelect
                selectedStatus={selectedStatus}
                handleStatusChange={handleStatusChange}
                data={data}
                MenuItemProps={MenuItemProps}
                Status={Status}
              />
            ) : (
              <p className="text-[#30d158]  mr-2">Closed</p>
            )}
          </div>

          <div className="flex items-center px-6">
            <p className="text-[#b3b3b3] text-xs">
              {isLoading ? (
                <Skeleton sx={{ width: 200 }} />
              ) : (
                `# ${ticketsdetails?.id}`
              )}
            </p>
            <p className="ml-5 text-xs text-[#b3b3b3]">
              {isLoading ? (
                <Skeleton sx={{ width: 200 }} />
              ) : (
                formDate(ticketsdetails?.responseDate)
              )}
            </p>
          </div>
          <NewTabs
            tabs={tabs}
            onChange={handleChange}
            value={value}
            isSpecial={true}
          />

          {value === "1" ? (
            <FirstTicketPanel
              isLoading={isLoading}
              latestDate={latestDate}
              ticketsdetails={ticketsdetails}
              data={data}
              showReassignText={showReassignText}
              selectedTopic={selectedTopic}
              handleReassignClick={handleReassignClick}
              handleTopicChange={handleTopicChange}
              AssignedAdmins={AssignedAdmins}
              MenuProps={MenuProps}
            />
          ) : value === "2" ? (
            <SecondTicketPanel
              containerRef={containerRef}
              isLoading={isLoading}
              data={data}
              formData={formData}
              handleKeyPress={handleKeyPress}
              setFormData={setFormData}
              handleSave={handleSave}
            />
          ) : value === "3" ? (
            <ThirdTicketPanel
              data={data}
              handleNavigation={handleNavigation}
              isLoading={isLoading}
            />
          ) : value === "4" ? (
            <ForthTicketPanel data={data} isLoading={isLoading} />
          ) : null}
        </div>
      </div>
      <ReusableSnackbar />
    </div>
  );
};

export default TicketsDetails;
