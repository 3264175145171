import { useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Divider,
  Dialog,
  DialogContent,
  Avatar,
  Skeleton,
} from "@mui/material";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  deactivateAdmin,
  deleteAdmin,
  useAdminProfileLogs,
} from "../../services/apis/roles-and-permissions";
import ActionAlert from "../user-profile/ActionAlert";
import UserInfoField from "../user-profile/UserInfoField";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridStyle } from "../../styles";
import { handlePageChange } from "../../services/utils/filters";
import { formDate } from "../../services/helpers";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { setData } from "../../store/slices/errorMessageSlice";
import { useDispatch } from "react-redux";
import { ItemsColumns } from "../../components/common/Columns";
import { CustomLoadingOverlay } from "../../components/common/CustomLoadingOverlay";
const ViewAdminProfile = () => {
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
  });
  const { id } = useParams();
  const { data, isLoading } = useAdminProfileLogs(queryParameters, id);
  const [disableWhileDelete, setDisableWhileDelete] = useState(false);
  const [openDeletedAlert, setOpenDeletedAlert] = useState(false);
  const [openDeactivateAlert, setOpenDeactivateAlert] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false);
  const navigate = useNavigate();
  const handleOpenDeleteAlert = () => {
    setOpenDeletedAlert(true);
  };
  const handleCloseDeleteAlert = () => {
    setOpenDeletedAlert(false);
  };
  const handleOpenDeactivateAlert = () => {
    setOpenDeactivateAlert(true);
  };
  const handleCloseDeactivateAlert = () => {
    setOpenDeactivateAlert(false);
  };
  const dispatch = useDispatch();
  const [paths, setPaths] = useState([]);
  useEffect(() => {
    const pathArray = id ? ["Admins", `${data?.full_name}`] : ["Admins"];
    setPaths(pathArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
  const handleDeleteAdmin = async () => {
    setDisableWhileDelete(true);
    try {
      await deleteAdmin(id);
      setDisableWhileDelete(false);
      setOpenDialog(true);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
    handleCloseDeleteAlert();
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleDeactivateAdmin = async () => {
    try {
      await deactivateAdmin(id);
      setOpenDeactivateDialog(true);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
    handleCloseDeleteAlert();
  };
  const logsColumns = ItemsColumns([
    {
      field: "user_id",
      headerName: "Admin ID",
      specialRender: "customRender",
      renderFunction: (params) => params?.row?.admin_data?.email,
    },
    { field: "message", headerName: "Action" },
    { field: "modelName", headerName: "Target Type" },
    {
      field: "TargetUserID",
      headerName: "Target User ID",
      specialRender: "customRender",
      renderFunction: (params) => params.row.user_data.id,
    },
    { field: "created", headerName: "Date", date: true },
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        padding: "2%",
        gap: 2,
        background: "#F0F0F0",
        alignItems: "center",
        overflowY: "auto",
        maxHeight: "calc(100vh)",
      }}
    >
      <Dialog
        open={openDialog}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: {
            width: "585px",
            height: "506px",
            borderRadius: "24px",
          },
        }}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogContent
          sx={{
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            sx={{
              bgcolor: "#D9F4DD",
              height: "80px",
              width: "80px",
              alignSelf: "center",
              marginRight: "15px",
            }}
          >
            <img src={`../../assets/Vector.png`} alt="vector" />
          </Avatar>
          <Typography
            sx={{ marginTop: "10%", marginBottom: "10%" }}
            variant="h6"
          >
            Admin has been deleted Successfully.
          </Typography>
          <Button
            onClick={handleGoBack}
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#917244",
              "&:hover": { backgroundColor: "#917244" },
              height: "46px",
              width: "291px",
              borderRadius: "9px",
            }}
          >
            Go to Admins
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDeactivateDialog}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: {
            width: "585px",
            height: "506px",
            borderRadius: "24px",
          },
        }}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogContent
          sx={{
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            sx={{
              bgcolor: "#D9F4DD",
              height: "80px",
              width: "80px",
              alignSelf: "center",
              marginRight: "15px",
            }}
          >
            <img src={`../../assets/Vector.png`} alt="vector" />
          </Avatar>
          <Typography
            sx={{ marginTop: "10%", marginBottom: "10%" }}
            variant="h6"
          >
            Admin has been{" "}
            {data?.deactivated_at === null ? "Deactivated" : "Reactivated"}{" "}
            Successfully.
          </Typography>
          <Button
            onClick={handleGoBack}
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#917244",
              "&:hover": { backgroundColor: "#917244" },
              height: "46px",
              width: "291px",
              borderRadius: "9px",
            }}
          >
            Go to Admins
          </Button>
        </DialogContent>
      </Dialog>
      <ActionAlert
        openAlert={openDeletedAlert}
        handleCloseAlert={handleCloseDeleteAlert}
        handleReject={handleDeleteAdmin}
        message={`Are you sure you want to Delete this admin ?`}
        label={"Delete"}
        disabled={disableWhileDelete}
      />
      <ActionAlert
        openAlert={openDeactivateAlert}
        handleCloseAlert={handleCloseDeactivateAlert}
        handleReject={handleDeactivateAdmin}
        message={`Are you sure you want to ${
          data?.deactivated_at === null ? "Deactivate" : "Reactivate"
        } this admin ?`}
        label={data?.deactivated_at === null ? "Deactivate" : "Reactivate"}
        alertType={"Deactivate"}
      />
      <Box sx={{ width: "100%" }}>
        <DashboardMainHeader />
      </Box>
      <Box sx={{ alignSelf: "start", ml: 2 }}>
        {paths?.map((path, index) => (
          <span key={index}>
            {index > 0 && " / "}
            {index === paths?.length - 1 ? (
              <span style={{ color: "#666666", fontSize: "14px" }}>{path}</span>
            ) : (
              <Link
                style={{
                  color: "#917244",
                  textDecoration: "none",
                  fontSize: "14px",
                }}
                to={`/${paths.slice(0, index + 1).join("/")}`}
                onClick={(e) => {
                  e.preventDefault();
                  window.history.back();
                }}
              >
                {path}
              </Link>
            )}
          </span>
        ))}
      </Box>
      <Box sx={{ dispaly: "flex", width: "97%", marginTop: "3%" }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <UserInfoField
              title="Username"
              data={
                isLoading ? <Skeleton sx={{ width: 200 }} /> : data?.full_name
              }
            />
          </Box>
          <Divider />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <UserInfoField
              title="User Status"
              data={isLoading ? <Skeleton sx={{ width: 200 }} /> : "Active"}
            />
          </Box>
          <Divider />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <UserInfoField
              title="Email"
              data={isLoading ? <Skeleton sx={{ width: 200 }} /> : data?.email}
            />
          </Box>
          <Divider />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <UserInfoField
              title="Role"
              data={
                isLoading ? (
                  <Skeleton sx={{ width: 200 }} />
                ) : (
                  data?.userGroupName
                )
              }
            />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <UserInfoField
              title="Role Description"
              data={
                isLoading ? (
                  <Skeleton sx={{ width: 200 }} />
                ) : (
                  data?.userGroupDescription
                )
              }
            />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <UserInfoField
              title="Creation Date"
              data={
                isLoading ? (
                  <Skeleton sx={{ width: 200 }} />
                ) : (
                  formDate(data?.created)
                )
              }
            />
          </Box>
          <Divider />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          alignSelf: "flex-end",
          margin: "5px 10px",
        }}
      >
        <Button
          onClick={handleOpenDeleteAlert}
          variant="outlined"
          sx={{
            color: "#F23045",
            width: "150px",
            backgroundColor: "transparent",
            height: "37px",
            borderRadius: "9px",
            borderColor: "#F23045",
            marginRight: "15px",
          }}
        >
          {"Delete Admin"}
        </Button>
        <Button
          onClick={handleOpenDeactivateAlert}
          variant="contained"
          sx={{
            "&:hover": {
              backgroundColor:
                data?.deactivated_at === null ? "#F23045" : "#917244",
            },
            color: "#FFF",
            width: "150px",
            backgroundColor:
              data?.deactivated_at === null ? "#F23045" : "#917244",
            height: "37px",
            borderRadius: "9px",
          }}
        >
          {data?.deactivated_at === null ? "Deactivate" : "Reactivate"}
        </Button>
      </Box>

      <Typography
        sx={{
          alignSelf: "flex-start",
          color: "#666666",
          fontSize: "20px",
          fontWeight: "500",
          marginLeft: "2%",
        }}
      >
        Admin Activity
      </Typography>
      <Box
        sx={{
          width: "95%",
          height: "auto",
          background: "#FFF",
          borderRadius: "12px",
          boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08);",
        }}
      >
        <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
          <DataGrid
            components={{
              LoadingOverlay: () => CustomLoadingOverlay({ columns: 5 }),
            }}
            rows={data?.logs.length > 0 ? data?.logs : []}
            columns={logsColumns}
            pageSize={10}
            rowCount={data?.logs.length}
            loading={isLoading}
            rowsPerPageOptions={[10]}
            onPageChange={(p) =>
              handlePageChange(p, queryParameters, setQueryParameters)
            }
            sx={{ height: "630px" }}
            totalPages={Math.ceil(data?.logs?.length / 10) || 1}
            disableSelectionOnClick
          />
        </Box>
      </Box>
      <ReusableSnackbar />
    </Box>
  );
};

export default ViewAdminProfile;
