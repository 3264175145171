import { useEffect, useState } from "react";
import {
  Modal,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
} from "@mui/material";
import { imageBaseUrl } from "../../services/config";
import { EditSvgWithFunc } from "../../components/common/SvgWithFunc";
import { useEditProductInfo } from "../../services/apis/products";
import { setData } from "../../store/slices/errorMessageSlice";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner";
export default function ProductEditModal({
  open,
  setOpen,
  product,
  fetchData,
}) {
  const dispatch = useDispatch();
  const [currentImage, setCurrentImage] = useState(product?.photo || "");
  const [sentProduct, setSentProduct] = useState(product);
  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState("en");
  const [inStock, setInStock] = useState("");
  useEffect(() => {
    if (product) {
      setSentProduct(product);
      setInStock(product?.availability === 1 ? true : false);
    }
  }, [product]);

  const handleChange = (event) => {
    setInStock(event.target.value);
  };
  const mutation = useEditProductInfo();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        setCurrentImage(e.target.result);
      };
      reader.readAsDataURL(file);
      setSentProduct((prevPayload) => ({
        ...prevPayload,
        photo: file,
      }));
    }
  };

  const handleTransChange = (e) => {
    const newTransValue = e.target.value;
    setSentProduct((prevProduct) => ({
      ...prevProduct,
      trans: {
        ...prevProduct.trans,
        [language]: newTransValue,
      },
    }));
  };
  const onSubmit = async () => {
    setIsLoading(true);
    const bodyEdit =
      product?.inventory_type === "ingot"
        ? {
            data: JSON.stringify({
              local: language,
              name: sentProduct?.trans[language],
              availability: inStock === "true" ? 1 : 0,
              stored_fees: sentProduct.stored_fees,
            }),
            photo: sentProduct?.photo,
          }
        : {
            data: JSON.stringify({
              availability: inStock === "true" ? 1 : 0,
            }),
          };
    try {
      const response = await mutation.mutateAsync({
        id: sentProduct?.id,
        body: bodyEdit,
      });
      setIsLoading(false);
      setOpen(false);
      fetchData();
      dispatch(
        setData({
          openSnack: true,
          message: "Product Updated Successfully",
          severity: "success",
        })
      );
    } catch (error) {
      // console.log("error", error);
      // if (Object.values(error?.response?.data?.validation)?.length > 0) {
      //   dispatch(
      //     setData({
      //       openSnack: true,
      //       message: Object.values(error?.response?.data?.validation)[0],
      //       severity: "error",
      //     })
      //   );
      // }
      console.error("Error updating product:", error);
    }
  };
  if (!sentProduct) {
    return <LoadingSpinner />;
  }
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="product-edit-modal"
      aria-describedby="product-edit-modal-description"
      sx={{
        height: "fit-content",
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: product?.inventory_type === "ingot" ? null : "center",
      }}
    >
      <div
        className={`bg-white rounded-lg shadow-lg px-8 pt-4 w-[50rem] mx-auto ${
          product?.inventory_type === "ingot" ? "h-auto" : "h-fit"
        } mt-4 mb-4  overflow-y-scroll`}
        onClick={(e) => e.stopPropagation()}
      >
        <div>
          <h1 className="mx-16 text-2xl text-[#4c4c4c]">Product Details</h1>
          {/* Image */}
          <div
            className={`relative w-fit mx-auto my-8 
              ${product?.inventory_type === "ingot" ? "block" : "hidden"}
            `}
          >
            <img
              src={
                currentImage.startsWith("data")
                  ? currentImage
                  : `${imageBaseUrl}/${sentProduct?.photo}`
              }
              alt="product"
              className="w-[7.5rem] h-[7.5rem] object-contain rounded-lg"
            />
            <EditSvgWithFunc
              onClick={() => {
                document.getElementById("imageInput").click();
              }}
              className={"absolute -right-3 -bottom-4 bg-white p-1 rounded-2xl"}
            />
            <input
              type="file"
              id="imageInput"
              className="hidden"
              onChange={handleImageChange}
              accept="image/*"
            />
          </div>
          {/* Language Selector */}
          <div>
            {product?.inventory_type === "ingot" ? (
              <>
                <p className={`text-[#4c4c4c] text-base mb-2 `}>
                  Select Language
                </p>
                <FormControl fullWidth>
                  <Select
                    value={language}
                    onChange={(event) => setLanguage(event.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Language
                    </MenuItem>
                    <MenuItem value={"en"}>English</MenuItem>
                    <MenuItem value={"ar"}>Arabic</MenuItem>
                    <MenuItem value={"eg"}>Egyptian Arabic</MenuItem>
                  </Select>
                </FormControl>
                <p className="text-[#4c4c4c] text-base mb-2 mt-6">Ingot Name</p>
                <TextField
                  fullWidth
                  sx={{
                    backgroundColor: "#f7f7f7",
                  }}
                  value={sentProduct?.trans?.[language] || ""}
                  onChange={handleTransChange}
                  placeholder="24K Gold Ingot 10 Gm"
                />
              </>
            ) : null}

            <div className="flex items-center justify-between mt-6 gap-6">
              <div
                className={`flex-1 ${
                  product?.inventory_type === "ingot" ? "block" : "hidden"
                }`}
              >
                <p className="text-[#4c4c4c] text-base mb-2">Storage Fees</p>
                <TextField
                  type="number"
                  fullWidth
                  sx={{
                    backgroundColor: "#f7f7f7",
                  }}
                  value={sentProduct?.stored_fees || ""}
                  onChange={(e) => {
                    setSentProduct((prevProduct) => ({
                      ...prevProduct,
                      stored_fees: e.target.value,
                    }));
                  }}
                  placeholder="1000 EGP"
                />
              </div>
              <div
                className={` flex-1 ${
                  product?.inventory_type === "ingot" ? "" : "mx-20 "
                } `}
              >
                <p className="text-[#4c4c4c] text-base mb-2">
                  {product?.inventory_type === "ingot"
                    ? "Availability"
                    : "Product Status"}
                </p>
                <RadioGroup
                  row
                  aria-labelledby="product-status-label"
                  name="product-status"
                  value={inStock}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="In Stock"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Out of Stock"
                  />
                </RadioGroup>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end mx-16 gap-10 mt-8 mb-8">
          <Button
            disabled={isLoading}
            onClick={onSubmit}
            variant="contained"
            sx={{
              py: "0.8rem",
              px: "4.2rem",
              borderRadius: "1rem",
              backgroundColor: "#917244",
              color: "white",
              fontWeight: "medium",
              fontSize: "1rem",
              "&:hover": {
                backgroundColor: "#d1a91f", // Darker shade on hover
              },
              "&:active": {
                backgroundColor: "#b89c2f", // Even darker shade when clicked
              },
            }}
          >
            Save
          </Button>
          <Button
            onClick={() => setOpen(false)}
            variant="outlined"
            sx={{
              py: "0.8rem",
              px: "4rem",
              borderRadius: "1rem",
              borderColor: "#917244",
              color: "#917244",
              fontWeight: "medium",
              fontSize: "1rem",
            }}
          >
            Cancel
          </Button>{" "}
        </div>
      </div>
    </Modal>
  );
}
