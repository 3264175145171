import TextFieldComponent from "../../components/TextFieldComponent";
import { Box } from "@mui/material";

const StepTwoFaqComponent = ({ setFaqData, faqData, isArabic }) => {
  const handleInputChange = (field, event) => {
    const inputValue = event.target.value;
    let allowedArabicRegex = /^[\u0600-\u06FF0-9"()-@'?:%.,\s]*$/;
    if (inputValue === "" || allowedArabicRegex.test(inputValue)) {
      const updatedPayload = { ...faqData };
      const arTranslation = updatedPayload.trans.find((t) => t.local === "ar");
      if (arTranslation) {
        arTranslation[field] = inputValue;
        setFaqData(updatedPayload);
      }
    }
  };

  return (
    <Box sx={{ marginTop: "3%" }}>
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Question (Arabic)"
        placeholder="Enter Question"
        value={faqData.trans.find((t) => t.local === "ar").title}
        onChange={(event) => handleInputChange("title", event)}
      />
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Answer (Arabic)"
        placeholder="Enter Answer"
        value={faqData.trans.find((t) => t.local === "ar").description}
        onChange={(event) => handleInputChange("description", event)}
      />
    </Box>
  );
};

export default StepTwoFaqComponent;
