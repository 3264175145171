import { useQuery, useMutation } from "react-query";
import { axiosInstance as axios } from "../config";

export const fetchInventoryRequest = async ({ page, orderBy, sort, type }) => {
  const response = await axios.get("/api/inventory/index", {
    params: {
      page,
      orderBy,
      sort,
      type,
    },
  });
  return response.data;
};

export const useFetchInventoryRequest = (params) => {
  return useQuery(
    ["inventoryRequest", params],
    () => fetchInventoryRequest(params),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true, // Optional: Useful for paginated data
    }
  );
};
export const fetchInventoryStock = async ({ page, orderBy, sort, type }) => {
  const response = await axios.get("/api/inventory/stock", {
    params: {
      page,
      orderBy,
      sort,
      type,
    },
  });
  return response.data;
};

export const useFetchInventoryStock = (params) => {
  return useQuery(
    ["inventoryStock", params],
    () => fetchInventoryStock(params),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true, // Optional: Useful for paginated data
    }
  );
};
export const fetchInventoryRequestSerials = async (q, id) => {
  try {
    const response = await axios.get(`/api/inventory/getSerialsByOrder/${id}`, {
      params: {
        page: q.page,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const addNewInventoryRequest = async (body) => {
  try {
    const response = await axios.post("/api/inventory/addToInventory", body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const changeInventoryRequestStatus = async (body) => {
  try {
    const response = await axios.post("/api/inventory/changeStatus", body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const mergeFractions = async (body) => {
  try {
    const response = await axios.post("/api/inventory/mappingSerial", body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const sellFraction = async (body) => {
  try {
    const response = await axios.post("/api/inventory/sell", body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchSoldIngots = async ({ page }) => {
  const response = await axios.get("/api/inventory/soldFraction", {
    params: {
      page,
    },
  });
  return response;
};
export const useFetchSoldIngots = (params) => {
  return useQuery(["fetch_SoldIngots", params], () => fetchSoldIngots(params), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
export const fetchSellFractionsIngot = async (q) => {
  try {
    const response = await axios.get(
      `/api/inventory/currentInventoryFraction`,
      {
        params: {
          page: q.page,
          limit: q.limit,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const useFetchSellFractionsIngot = (params) => {
  return useQuery(
    ["fetch_SellFractionsIngot", params],
    () => fetchSellFractionsIngot(params),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
};
export const useFetchAllStoreLocations = () => {
  return useQuery(["allStoreLocations"], async () => {
    const response = await axios.get(`/api/store-locations`);
    return response?.data?.data;
  });
};
const useUpdateStatus = async ({ id, body }) => {
  try {
    const response = await axios.put(
      `/api/inventory/changeStatus/${id}`,
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const useSubmitUpdateStatus = () => {
  return useMutation(useUpdateStatus);
};
const useEditLocation = async ({ id, body }) => {
  try {
    const response = await axios.put(
      `/api/inventory/update-location/${id}`,
      body
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const useUpdateLocation = () => {
  return useMutation(useEditLocation);
};
const useUpdateDelivered = async ({ id, body }) => {
  try {
    const response = await axios.put(
      `/api/inventory/update-status/${id}`,
      body
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const useUpdateDeliveredOrder = () => {
  return useMutation(useUpdateDelivered);
};
const fetchOrderHistoryDetails = async (id) => {
  try {
    const response = await axios.get(`/api/inventory/${id}`);
    return response.data?.data;
  } catch (error) {
    throw error;
  }
};
export const useOrderHistory = (id) => {
  return useQuery(
    ["orderHistoryDetails", id],
    () => fetchOrderHistoryDetails(id),
    {
      enabled: !!id,
    }
  );
};
const fetchSerialDetails = async (id) => {
  try {
    const response = await axios.get(`/api/inventory/ingotDetails/${id}`);
    return response.data?.data;
  } catch (error) {
    throw error;
  }
};
export const useSerialDetails = (id) => {
  return useQuery(["serial-details", id], () => fetchSerialDetails(id), {
    enabled: !!id,
  });
};
