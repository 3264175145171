import { Box, Button, IconButton, InputBase, Paper } from "@mui/material";
import { DataGridStyle } from "../../../styles";
import { useState } from "react";
import { fetchLogsThirdTab } from "../../../services/apis/roles-and-permissions";
import { checkString, handlePageChange } from "../../../services/utils/filters";
import { formDate } from "../../../services/helpers";
import { saveAs } from "file-saver";
import LogsDetailsDialog from "../LogsDetailsDialog";
import NewTable from "../../../components/common/NewTable";

const ThirdAdminTabPanel = () => {
  const [logs] = useState([
    {
      id: 1,
      user_id: "test@test.com",
      role: "Super Admin",
      message: "Approve Kyc",
      modelName: "User",
      TargetUserID: "01234567891",
      created: "2024-10-01T14:46:22.000Z",
    },
    {
      id: 2,
      user_id: "test@test.com",
      role: "Super Admin",
      message: "Approve Kyc",
      modelName: "User",
      TargetUserID: "01234567891",
      created: "2024-10-01T14:46:22.000Z",
    },
    {
      id: 3,
      user_id: "test@test.com",
      role: "Super Admin",
      message: "Approve Kyc",
      modelName: "User",
      TargetUserID: "01234567891",
      created: "2024-10-01T14:46:22.000Z",
    },
  ]);
  const [totalPages] = useState(0);
  const [pagesCount] = useState(10);
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
    email: null,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const handleClose = async () => {
    setOpenDialog(false);
  };
  // const getLogs = async () => {
  //   setLoading(true);
  //   setLogs([]);
  //   try {
  //     const response = await fetchLogsThirdTab(queryParameters);
  //     const request = response?.data?.data?.result;
  //     setLogs(request);
  //     const logscount = response.data.data.totalItems;
  //     const logssPagecount = response.data.data.totalPages;
  //     setTotalPages(logssPagecount);
  //     setPagesCount(logscount);
  //   } catch (error) {
  //     console.log(error.response.data.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const handleSearchInput = (e) => {
    const { value } = e.target;
    checkString(value, queryParameters, setQueryParameters);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const downloadLogs = async () => {
    try {
      const response = await fetchLogsThirdTab(queryParameters);
      const logs = response.data.data.result;

      const headers = [
        "Admin ID",
        "Role",
        "Action",
        "Target Type",
        "Target User ID",
        "Date",
      ];

      const csvContent = [
        headers.join(","),
        ...logs.map((log) => {
          return [
            log.admin_data?.email,
            log.admin_data?.userGroup?.name,
            log.message,
            log.modelName,
            log.user_data?.id,
            `"${formDate(log.created)}"`,
          ].join(",");
        }),
      ].join("\n");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      saveAs(blob, "logs.csv");
    } catch (error) {
      console.error("Error downloading logs:", error);
    }
  };
  // useEffect(() => {
  //   getLogs();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [queryParameters]);
  const logsColumns = [
    {
      field: "user_id",
      headerName: "Admin ID",
      flex: 1,
      align: "cener",
      headerAlign: "center",
      renderCell: (params) => params?.row?.admin_data?.email,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => params?.row?.admin_data?.userGroup?.name,
    },
    {
      field: "message",
      headerName: "Action",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "modelName",
      headerName: "Target Type",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "TargetUserID",
      headerName: "Target User ID",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => params?.row?.user_data?.id,
    },
    {
      field: "created",
      headerName: "Date",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => formDate(params?.value),
    },
  ];
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 2,
          mb: 2,
          p: 2,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Paper
          component="form"
          sx={{
            // p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "600px",
            height: "48px",
            backgroundColor: "#F5F5F5",
            boxShadow: "none",
            borderRadius: "12px",
          }}
          onSubmit={handleSubmit}
        >
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <img
              alt="search-normal"
              src={`../../../assets/search-normal.png`}
            />
          </IconButton>
          <InputBase
            onChange={handleSearchInput}
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Email , National ID or Phone number"
          />
          {/* <FilterDrawer /> */}
        </Paper>
        <Box>
          <Button
            onClick={() => downloadLogs()}
            sx={{
              borderRadius: "9px",
              background: "#917244",
              "&:hover": {
                background: "#917244",
              },
              width: "171px",
              height: "42px",
              color: "#FFF",
              fontSize: "16px",
              textTransform: "none",
            }}
          >
            {"Download Report"}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          height: "auto",
          background: "#FFF",
          borderRadius: "12px",
        }}
      >
        <Box m="40px 0 0 0" height="100%" sx={DataGridStyle}>
          <NewTable
            columns={logsColumns}
            // isLoading={loading}
            data={logs}
            totalPages={totalPages}
            totalItems={pagesCount}
            handlePageChange={(newPage) =>
              handlePageChange(newPage, queryParameters, setQueryParameters)
            }
            currentPage={queryParameters}
            onCellClick={() => {
              setOpenDialog(true);
            }}
          />
          {/* <DataGrid
            components={{
              LoadingOverlay: () => CustomLoadingOverlay({ columns: 6 }),
            }}
            rows={logs}
            columns={logsColumns}
            pageSize={10}
            rowCount={pagesCount}
            onRowClick={() => {
              setOpenDialog(true);
            }}
            // loading={loading}
            paginationMode="server"
            onPageChange={(p) =>
              handlePageChange(p, queryParameters, setQueryParameters)
            }
            sx={{
              cursor: "pointer",
              backgroundColor: "#ffffff",
              height: "630px",
            }}
            rowsPerPageOptions={[10]}
            totalPages={totalPages}
            disableSelectionOnClick
          /> */}
        </Box>
      </Box>
      <LogsDetailsDialog open={openDialog} onClose={handleClose} />
    </Box>
  );
};
export default ThirdAdminTabPanel;
