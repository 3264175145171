import React from "react";
import { Button, Box, Typography, Avatar } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  useSubmitUpdateStatus,
  useUpdateDeliveredOrder,
} from "../../../services/apis/inventory";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";
import mark from "../../../svg/mark.svg";

const CancelOrderDialog = ({
  open,
  onClose,
  label,
  requestData,
  refetch,
  getRequestSerials,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const { mutate } = useUpdateDeliveredOrder();
  const dispatch = useDispatch();
  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };

  const handleSubmit = () => {
    const payload = {
      request_status: 2,
    };
    setIsSubmitting(true);
    mutate(
      {
        id: requestData.order_id,
        body: payload,
      },
      {
        onSuccess: () => {
          setSuccessDialogOpen(true);
          onClose();
          refetch();
          getRequestSerials();
          setIsSubmitting(false);
        },
        onError: (error) => {
          dispatch(
            setData({
              openSnack: true,
              message: "An error occurred while submitting the order",
            })
          );
          setIsSubmitting(false);
        },
      }
    );
  };
  return (
    <>
      <Dialog
        open={successDialogOpen}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: {
            width: "585px",
            height: "506px",
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            sx={{
              bgcolor: "#D9F4DD",
              height: "80px",
              width: "80px",
              alignSelf: "center",
              marginRight: "15px",
            }}
          >
            <img src={`../../assets/Vector.png`} alt="vector" />
          </Avatar>
          <Typography
            sx={{ marginTop: "10%", marginBottom: "10%" }}
            variant="h6"
          >
            Order Canceled Successfully.
          </Typography>
          <Button
            onClick={() => {
              handleSuccessDialogClose();
            }}
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#917244",
              "&:hover": { backgroundColor: "#917244" },
              height: "46px",
              width: "291px",
              borderRadius: "9px",
            }}
          >
            Go to order Details
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "630px",
            height: "506px",
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            height: "100%",
            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              width: "90%",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 3,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={onClose}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box
              sx={{
                width: "80px",
                height: "80px",
                borderRadius: "50px",
              }}
            >
              <img src={mark} alt="mark" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  color: "#595959",
                  fontWeight: "400",
                  fontSize: "18px",
                  display: "flex",
                  alignSelf: "flex-start",
                }}
              >
                Are you sure you want to cancel this Order ?
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: 2, marginTop: "10px" }}>
              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  background: "#917244",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#917244",
                  },
                }}
                onClick={handleSubmit}
              >
                {label}
              </Button>

              <Button
                variant="outlined"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#917244",
                  fontWeight: 600,
                  borderColor: "#917244",
                  "&:hover": {
                    borderColor: "#917244",
                  },
                }}
                onClick={onClose}
              >
                No , Keep order
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <ReusableSnackbar />
    </>
  );
};

export default CancelOrderDialog;
