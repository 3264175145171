import { useEffect, useState } from "react";
import { IconButton, TextField } from "@mui/material";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import {
  configuration,
  updateFraction,
} from "../../services/apis/configuration";
import CustomDialog from "../../components/CustomDialog";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { ItemsColumns } from "../../components/common/Columns";
import NewTable from "../../components/common/NewTable";

const ContactUsInfo = () => {
  const [loading, setLoading] = useState(true);
  const [fractions, setFractions] = useState([]);
  const [editingRowId, setEditingRowId] = useState(null);
  const [editedData, setEditedData] = useState(null);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const dispatch = useDispatch();

  const getConfiguration = async () => {
    setLoading(true);
    setFractions([]);
    try {
      const response = await configuration(5);
      setLoading(false);
      setFractions(response);
    } catch (error) {
      console.log("error getting fractions");
    } finally {
      setLoading(false);
    }
  };
  const handleEditClick = (rowId, rowData) => {
    // If there's an ongoing edit, reset the previous row to its original state
    if (editingRowId !== null && editingRowId !== rowId) {
      setFractions((prevFractions) => {
        return prevFractions.map((fraction) => {
          if (fraction.id === editingRowId) {
            // Reset to original value stored in `editedData`
            return { ...fraction, value: editedData?.value };
          }
          return fraction;
        });
      });
    }

    // Start editing the new row
    setEditedData(rowData); // Save the current data of the row being edited
    setEditingRowId(rowId); // Set the new row as the one being edited
  };

  const handleCancelEdit = (rowId) => {
    setEditingRowId(null);
    setFractions((prevFractions) => {
      return prevFractions.map((fractions) => {
        if (fractions?.id === rowId) {
          return editedData;
        }
        return fractions;
      });
    });
  };

  const handleSaveEdit = async (rowId) => {
    try {
      const editedFraction = fractions.find(
        (fraction) => fraction.id === editingRowId
      );
      if (!editedFraction) {
        dispatch(
          setData({
            openSnack: true,
            message: "Edited fraction not found",
          })
        );
        return;
      }
      if (editedFraction.value === "" || editedFraction.value === null) {
        dispatch(
          setData({
            openSnack: true,
            message: "Please fill in the amount field.",
          })
        );
        return;
      }
      await updateFraction(editedFraction.id, {
        value: editedFraction.value,
      });
      setEditingRowId(null);
      setEditedData(null);
      setSuccessDialogOpen(true);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
    setEditingRowId(null);
  };
  const renderEditIcons = (params) => {
    const row = params?.row;
    if (!row) return null;
    const isEditing = row.id === editingRowId;
    return isEditing ? (
      <Box>
        <IconButton onClick={() => handleSaveEdit(row.id)}>
          <img src={`../../assets/check.png`} alt="check" />
        </IconButton>
        <IconButton onClick={() => handleCancelEdit(row.id)}>
          <img src={`../../assets/close.png`} alt="close" />
        </IconButton>
      </Box>
    ) : (
      <IconButton onClick={() => handleEditClick(row.id, row)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          style={{ cursor: "pointer" }}
        >
          <path
            d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H11C11.41 1.25 11.75 1.59 11.75 2C11.75 2.41 11.41 2.75 11 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V13C21.25 12.59 21.59 12.25 22 12.25C22.41 12.25 22.75 12.59 22.75 13V15C22.75 20.43 20.43 22.75 15 22.75Z"
            fill="#00303C"
          />
          <path
            d="M8.49984 17.69C7.88984 17.69 7.32984 17.47 6.91984 17.07C6.42984 16.58 6.21984 15.87 6.32984 15.12L6.75984 12.11C6.83984 11.53 7.21984 10.78 7.62984 10.37L15.5098 2.49C17.4998 0.499998 19.5198 0.499998 21.5098 2.49C22.5998 3.58 23.0898 4.69 22.9898 5.8C22.8998 6.7 22.4198 7.58 21.5098 8.48L13.6298 16.36C13.2198 16.77 12.4698 17.15 11.8898 17.23L8.87984 17.66C8.74984 17.69 8.61984 17.69 8.49984 17.69ZM16.5698 3.55L8.68984 11.43C8.49984 11.62 8.27984 12.06 8.23984 12.32L7.80984 15.33C7.76984 15.62 7.82984 15.86 7.97984 16.01C8.12984 16.16 8.36984 16.22 8.65984 16.18L11.6698 15.75C11.9298 15.71 12.3798 15.49 12.5598 15.3L20.4398 7.42C21.0898 6.77 21.4298 6.19 21.4798 5.65C21.5398 5 21.1998 4.31 20.4398 3.54C18.8398 1.94 17.7398 2.39 16.5698 3.55Z"
            fill="#00303C"
          />
          <path
            d="M19.8501 9.83003C19.7801 9.83003 19.7101 9.82003 19.6501 9.80003C17.0201 9.06003 14.9301 6.97003 14.1901 4.34003C14.0801 3.94003 14.3101 3.53003 14.7101 3.41003C15.1101 3.30003 15.5201 3.53003 15.6301 3.93003C16.2301 6.06003 17.9201 7.75003 20.0501 8.35003C20.4501 8.46003 20.6801 8.88003 20.5701 9.28003C20.4801 9.62003 20.1801 9.83003 19.8501 9.83003Z"
            fill="#00303C"
          />
        </svg>
      </IconButton>
    );
  };
  const renderAmountField = (params) => {
    const isEditing = params.row.id === editingRowId;
    const handleInputChange = (e) => {
      const newValue = e.target.value;
      if (newValue < 0) {
        dispatch(
          setData({
            openSnack: true,
            message: "Negative numbers are not allowed.",
          })
        );
        return;
      }
      setFractions((prevFractions) => {
        const updatedFractions = prevFractions.map((fraction) => {
          if (fraction.id === params.row.id) {
            return { ...fraction, value: newValue };
          }
          return fraction;
        });
        return updatedFractions;
      });
    };
    return isEditing ? (
      <TextField
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "41px",
          width: "230px",
          borderRadius: "9px",
          border: "1px solid #917244",
        }}
        name="amount"
        placeholder="amount"
        value={params.row.value}
        type={params.row.type}
        onChange={handleInputChange}
      />
    ) : (
      params.row.value
    );
  };
  const contactUsColumns = ItemsColumns([
    { field: "title", headerName: "Contact Detail" },
    {
      field: "value",
      headerName: "Current Value",
      specialRender: "customRender",
      renderFunction: renderAmountField,
    },
    {
      field: "description",
      headerName: "Description/Notes",
    },
    {
      field: "action",
      headerName: "Edit",
      specialRender: "customRender",
      renderFunction: renderEditIcons,
    },
  ]);

  useEffect(() => {
    getConfiguration();
  }, []);

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Contact Us Info" marginL={"20px"} marginB={4} />
      <div className="p-5 rounded-2xl">
        <NewTable
          columns={contactUsColumns}
          isLoading={loading}
          data={fractions}
          noPagination
          rows={2}
        />
      </div>

      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Configuration has been updated Successfully."
        message=""
        buttonText="Go to Configuration"
      />
      <ReusableSnackbar />
    </Box>
  );
};

export default ContactUsInfo;
