import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Typography } from "@mui/material";
import DateFiltering from "./DateFiltering";
import FilteringOptions from "./FilteringOptions";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoFilterOutline } from "react-icons/io5";
import closeCircle from "../../svg/new-close-circle.svg";
export default function FilterDrawer({
  queryParameters,
  setQueryParameters,
  firstTitle,
  secondTitle,
  firstList,
  secondList,
  firstKey,
  secondKey,
  handleFirstCheckBox,
  handleSecondCheckBox,
  isUsers,
  isRole,
  isGold,
}) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (event, anchor, open) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <React.Fragment>
      <IoFilterOutline
        size={25}
        style={{ cursor: "pointer", marginRight: "10px" }}
        onClick={(event) => toggleDrawer(event, "right", true)} // Open the drawer from the right
      />
      <Drawer
        anchor="right" // Changed from "left" to "right"
        open={state["right"]}
        onClose={(event) => toggleDrawer(event, "right", false)} // Toggle the right state
      >
        <Box sx={{ width: 420, display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              padding: 4,
            }}
          >
            <img
              className="cursor-pointer mb-8"
              width={32}
              src={closeCircle}
              alt="closeCircle"
              onClick={(event) => toggleDrawer(event, "right", false)} // Close the right drawer
            />
            {!isRole && (
              <>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 500,
                  }}
                >
                  Date
                </Typography>

                <DateFiltering
                  queryParameters={queryParameters}
                  setQueryParameters={setQueryParameters}
                />
              </>
            )}

            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 500,
                mt: 5,
              }}
            >
              {firstTitle}
            </Typography>
            <FilteringOptions
              firstTitle={firstTitle}
              filterList={firstList}
              queryParameters={queryParameters}
              k={firstKey}
              handleCheckbox={handleFirstCheckBox}
              isGold={isGold}
            />

            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 500,
                mt: 5,
              }}
            >
              {secondTitle}
            </Typography>

            <FilteringOptions
              filterList={secondList}
              k={secondKey}
              queryParameters={queryParameters}
              setQueryParameters={setQueryParameters}
              handleCheckbox={handleSecondCheckBox}
              isUsers={isUsers}
              isRole={isRole}
            />
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
