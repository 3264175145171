import TextFieldComponent from "../../components/TextFieldComponent";
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import CustomTextArea from "../../components/CustomTextArea";
import { FirstTypography } from "../../components/common/CustomTypogrphy";

const StepTwoTicketQuestions = ({
  setFaqData,
  faqData,
  type,
  subjectId,
  setIsValid,
}) => {
  useEffect(() => {
    const allSubQuestionsValid = faqData?.subQuestions.every(
      (item) => item?.trans[1]?.question && item?.trans[1]?.answer
    );

    const isMainQuestionValid = faqData?.trans[1]?.question;
    const isMainAnswerValid = faqData?.trans[1]?.answer;

    if (isMainQuestionValid && type === "No" && isMainAnswerValid) {
      setIsValid([true, true, false]);
    } else if (isMainQuestionValid && type === "Yes" && allSubQuestionsValid) {
      setIsValid([true, true, false]);
    } else {
      setIsValid([true, false, false]);
    }
  }, [faqData, type, setIsValid]);
  const handleSubQuestionChange = (index, field, fieldType, value) => {
    setFaqData((prevFaqData) => ({
      ...prevFaqData,
      subQuestions: prevFaqData.subQuestions.map((subQuestion, i) => {
        if (i === index) {
          return {
            ...subQuestion,
            trans: subQuestion.trans.map((translation) => {
              if (translation.local === field) {
                return {
                  ...translation,
                  [fieldType]: value,
                };
              }
              return translation;
            }),
          };
        }
        return subQuestion;
      }),
    }));
  };
  return (
    <Box sx={{ marginTop: "3%" }}>
      {type === "No" && (
        <Box>
          <TextFieldComponent
            sx={{ "& fieldset": { border: "none" } }}
            style={{
              height: "48px",
              width: "100%",
              backgroundColor: "#F7F7F7",
              borderRadius: "9px",
            }}
            name="Question (Arabic)"
            placeholder="Enter Question (Arabic)"
            value={faqData.trans.find((t) => t.local === "ar").question}
            onChange={(event) => {
              const allowArabicAndSpecialCharsRegex =
                /^[\u0600-\u06FF0-9"()-@'?:%.,\s]*$/; // Allow Arabic characters, numbers, and specific special characters

              // Check if the value is either empty or matches the Arabic-only pattern
              if (
                event.target.value === "" ||
                allowArabicAndSpecialCharsRegex.test(event.target.value)
              ) {
                const updatedPayload = { ...faqData };
                const arTranslation = updatedPayload.trans.find(
                  (t) => t.local === "ar"
                );
                if (arTranslation) {
                  arTranslation.question = event.target.value;
                  setFaqData(updatedPayload);
                }
              }
            }}
          />

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <FirstTypography title={"Answer (Arabic)"} />
            <CustomTextArea
              style={{
                minHeight: "80px",
                maxHeight: "200px",
                maxWidth: "100%",
                minWidth: "100%",
                backgroundColor: "#FFF",
                borderRadius: "9px",
                boxShadow: "none",
                border: "1px solid #917244",
              }}
              name="Answer"
              placeholder="Enter Answer"
              value={faqData.trans.find((t) => t.local === "ar").answer}
              // onChange={(event) => {
              //   const disallowEnglishRegex = /^[^a-zA-Z]*$/;
              //   if (
              //     event.target.value === "" ||
              //     disallowEnglishRegex.test(event.target.value)
              //   ) {
              //     const updatedPayload = { ...faqData };
              //     const enTranslation = updatedPayload.trans.find(
              //       (t) => t.local === "ar"
              //     );
              //     if (enTranslation) {
              //       enTranslation.answer = event.target.value;
              //       setFaqData(updatedPayload);
              //     }
              //   }
              // }}
              onChange={(event) => {
                const allowArabicAndSpecialCharsRegex =
                  /^[\u0600-\u06FF0-9"()-@'?:%.,\s]*$/; // Allow Arabic characters, numbers, and specific special characters

                // Check if the value is either empty or matches the Arabic-only pattern
                if (
                  event.target.value === "" ||
                  allowArabicAndSpecialCharsRegex.test(event.target.value)
                ) {
                  const updatedPayload = { ...faqData };
                  const arTranslation = updatedPayload.trans.find(
                    (t) => t.local === "ar"
                  );
                  if (arTranslation) {
                    arTranslation.answer = event.target.value;
                    setFaqData(updatedPayload);
                  }
                }
              }}
            />
          </Box>
        </Box>
      )}
      {type === "Yes" && (
        <Box>
          <TextFieldComponent
            sx={{ "& fieldset": { border: "none" } }}
            style={{
              height: "48px",
              width: "100%",
              backgroundColor: "#F7F7F7",
              borderRadius: "9px",
            }}
            name="Question (Arabic)"
            placeholder="Enter Question (Arabic)"
            value={faqData.trans.find((t) => t.local === "ar").question}
            onChange={(event) => {
              const disallowEnglishRegex = /^[^a-zA-Z]*$/;
              if (
                event.target.value === "" ||
                disallowEnglishRegex.test(event.target.value)
              ) {
                const updatedPayload = { ...faqData };
                const enTranslation = updatedPayload.trans.find(
                  (t) => t.local === "ar"
                );
                if (enTranslation) {
                  enTranslation.question = event.target.value;
                  setFaqData(updatedPayload);
                }
              }
            }}
          />
          {!subjectId && (
            <Box>
              {faqData.subQuestions.map((subQuestion, index) => (
                <Box key={index}>
                  <TextFieldComponent
                    sx={{ "& fieldset": { border: "none" } }}
                    style={{
                      height: "48px",
                      width: "100%",
                      backgroundColor: "#F7F7F7",
                      borderRadius: "9px",
                    }}
                    name={`Sub Question ${index + 1} (Arabic)`}
                    placeholder={`Enter Sub Question ${index + 1} (Arabic)`}
                    value={
                      faqData.subQuestions[index].trans.find(
                        (t) => t.local === "ar"
                      ).question
                    }
                    onChange={(event) =>
                      handleSubQuestionChange(
                        index,
                        "ar",
                        "question",
                        event.target.value
                      )
                    }
                  />

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#4C4C4C",
                        marginBottom: "5px",
                        marginTop: "15px",
                      }}
                    >
                      Answer (Arabic)
                    </Typography>
                    <CustomTextArea
                      style={{
                        minHeight: "80px",
                        maxHeight: "200px",
                        maxWidth: "100%",
                        minWidth: "100%",
                        backgroundColor: "#FFF",
                        borderRadius: "9px",
                        boxShadow: "none",
                        border: "1px solid #917244",
                      }}
                      name="Answer"
                      placeholder="Enter Answer"
                      value={
                        faqData.subQuestions[index].trans.find(
                          (t) => t.local === "ar"
                        ).answer
                      }
                      onChange={(event) => {
                        const disallowEnglishRegex = /^[^a-zA-Z]*$/;
                        if (
                          event.target.value === "" ||
                          disallowEnglishRegex.test(event.target.value)
                        ) {
                          handleSubQuestionChange(
                            index,
                            "ar",
                            "answer",
                            event.target.value
                          );
                        }
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
export default StepTwoTicketQuestions;
