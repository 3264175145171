import { useMutation } from "react-query";
import { axiosInstance as axios } from "../config";

export const fetchPaymentInfo = async () => {
  try {
    const response = await axios.get(`/api/paymentTypes/index`);
    return response;
  } catch (error) {
    throw error;
  }
};
const editPaymentInfo = async (data) => {
  const response = await axios.post(`/api/paymentTypes/edit`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const useEditPaymentInfo = () => {
  return useMutation(editPaymentInfo, {
    onError: (error) => {
      console.error("Error submitting data:", error);
    },
  });
};
