import React, { useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useRejectReasonsDropDown } from "../../services/apis/rejectionReasons";
import brownDanger from "../../svg/danger-brown.svg";
import circle from "../../svg/new-close-circle.svg";
import arrowCircleDown from "../../svg/arrow-circle-down.svg";

const ActionAlert = ({
  openAlert,
  handleCloseAlert,
  handleReject,
  label,
  message,
  ...props
}) => {
  const { data } = useRejectReasonsDropDown();
  const [isError, setIsError] = useState(false);

  const handleSubmit = () => {
    if (!props.formData.reject_kyc_reason) {
      setIsError(true);
    } else {
      setIsError(false);
      handleReject(); // Call the reject handler
    }
  };

  return (
    <Dialog
      open={openAlert}
      onClose={handleCloseAlert}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          borderRadius: "24px",
        },
      }}
    >
      <DialogContent
        sx={{
          borderRadius: "24px",
        }}
      >
        <div>
          <img
            src={circle}
            alt="circle "
            className="ml-auto cursor-pointer"
            onClick={handleCloseAlert}
          />
          <p className="text-2xl text-[#595959] font-medium font-ibmArabic mb-12">
            Reject Document
          </p>
          <img src={brownDanger} alt="brownDanger" className="mx-auto" />
          <p className="text-2xl text-[#595959] font-medium font-ibmArabic mt-3">
            {message}
          </p>
          <div className="relative mt-8">
            <p className="text-[#595959] font-ibmArabic font-medium text-sm mb-2">
              Select Rejection Reason
            </p>
            <select
              onChange={(e) => {
                props.setFormData({ reject_kyc_reason: e.target.value });
                setIsError(false); // Reset error when selecting a reason
              }}
              className={`relative w-full p-4 text-[#808080] bg-[#f5f5f5] rounded-xl appearance-none focus:outline-none focus:ring-2 focus:bg-grey-100 cursor-pointer pr-10 ${
                isError ? "border border-red-500" : ""
              }`}
              defaultValue=""
            >
              <option value="" disabled>
                Please select a reason
              </option>
              {data?.map(({ id, reason_en }) => (
                <option key={id} value={id}>
                  {reason_en}
                </option>
              ))}
            </select>
            {isError && (
              <p className="text-red-500 text-sm mt-2">
                Please select a rejection reason.
              </p>
            )}
            <img
              src={arrowCircleDown}
              alt="arrowCircleDown"
              className="absolute right-3 transform top-1/2 text-gray-500 pointer-events-none"
            />
          </div>

          <div className="flex items-center justify-center gap-2 mt-12 mb-16">
            <button
              onClick={handleSubmit}
              className="bg-[#917244] rounded-xl px-20 py-3 text-white font-medium font-ibmArabic"
            >
              {label}
            </button>
            <button
              onClick={handleCloseAlert}
              className="text-[#595959] rounded-xl px-20 py-3 border border-[#595959] font-medium font-ibmArabic"
            >
              Cancel
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ActionAlert;
