import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Button, Skeleton, TextField } from "@mui/material";
import { Box } from "@mui/material";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import closeCircle from "../../svg/new-close-circle.svg";
const KycDialog = ({
  openDialog,
  handleCloseDialog,
  image,
  handleApprove,
  isLoading,
}) => {
  const [formData, setFormData] = useState({
    national_name: "",
    nationalId: "",
    confirmNationalId: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    if (name === "national_name") {
      // Allow only Arabic characters
      const arabicTextRegex = /^[\u0600-\u06FF\s]+$/;
      if (arabicTextRegex.test(value) || value === "") {
        setFormData({ ...formData, [name]: value });
      }
    } else if (name === "nationalId" || name === "confirmNationalId") {
      if (/^\d{0,14}$/.test(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const isValidNationalName = (name) => {
    const nameParts = name.trim().split(" ");
    return (
      (nameParts.length === 4 || nameParts.length === 5) &&
      nameParts.every((part) => part.length > 0)
    );
  };

  const dispatch = useDispatch();

  const handleWheel = (event) => {
    event.target.blur();
    setTimeout(() => {
      event.target.focus();
    }, 0);
  };

  const resetFormData = () => {
    setFormData({
      national_name: "",
      nationalId: "",
      confirmNationalId: "",
    });
  };

  const handleCloseDialogWithReset = () => {
    resetFormData();
    handleCloseDialog();
  };

  const validateFormData = (formData) => {
    if (
      !formData.national_name ||
      !formData.nationalId ||
      !formData.confirmNationalId
    ) {
      return "Please fill in all required fields";
    }
    if (formData.nationalId !== formData.confirmNationalId) {
      return "National ID doesn't match";
    }
    if (
      formData.nationalId.length !== 14 ||
      formData.confirmNationalId.length !== 14
    ) {
      return "National ID must be 14 digits";
    }
    if (!isValidNationalName(formData.national_name)) {
      return "Please enter the user's full name as displayed on their national ID.";
    }
    return null; // No errors
  };

  const handleSubmit = async () => {
    try {
      const errorMessage = validateFormData(formData);
      if (errorMessage) {
        dispatch(
          setData({
            openSnack: true,
            message: errorMessage,
          })
        );
        return;
      }

      const dataToSend = {
        national_name: formData.national_name,
        nationalId: formData.nationalId,
      };
      await handleApprove(dataToSend);
      setFormData({
        national_name: "",
        nationalId: "",
        confirmNationalId: "",
      });
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message:
            error?.response?.data?.validation?.nationalId ||
            error?.data?.data?.message ||
            "An unexpected error occurred",
        })
      );
    }
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialogWithReset}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "800px",
            minHeight: "201px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent sx={{}}>
          <div className="mb-8">
            <img
              src={closeCircle}
              alt="closeCircle"
              className="ml-auto cursor-pointer"
              onClick={() => {
                handleCloseDialogWithReset();
              }}
            />
            <p className="text-2xl text-[#595959] font-medium font-ibmArabic mb-8">
              Approve Document
            </p>
            {isLoading ? (
              <Skeleton variant="rectangular" width={300} height={200} /> // Display skeleton while image is loading
            ) : (
              <img
                src={image}
                alt=""
                className="mx-auto max-w-[302px] max-h-[250px]"
              />
            )}
          </div>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <p className="mb-4 text-sm text-[#595959] font-medium font-ibmArabic">
              Full Name as shown in National ID Card in Arabic
            </p>

            <input
              name="national_name"
              type="text"
              onChange={handleChange}
              onPaste={(e) => e.preventDefault()}
              onWheel={handleWheel}
              value={formData.national_name}
              autoComplete="off"
              className="bg-[#f5f5f5] rounded-xl p-3 "
              placeholder="Please Enter Full Name as shown in National ID Card in Arabic"
            />

            <p className="mb-4 text-sm text-[#595959] font-medium font-ibmArabic mt-4">
              National ID
            </p>
            <input
              onKeyDown={(evt) =>
                ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
              }
              name="nationalId"
              type="text"
              onChange={handleChange}
              onPaste={(e) => e.preventDefault()}
              onWheel={handleWheel}
              value={formData.nationalId}
              autoComplete="off"
              className="bg-[#f5f5f5] rounded-xl p-3 "
              placeholder="Please Enter National ID"
            />
            <p className="mb-4 text-sm text-[#595959] font-medium font-ibmArabic mt-4">
              Confirm National ID
            </p>
            <input
              onKeyDown={(evt) =>
                ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
              }
              name="confirmNationalId"
              placeholder="Please Confirm National ID"
              type="text"
              onChange={handleChange}
              onPaste={(e) => e.preventDefault()}
              onWheel={handleWheel}
              value={formData.confirmNationalId}
              autoComplete="off"
              className="bg-[#f5f5f5] rounded-xl p-3 "
            />
          </Box>
          <div className="flex items-center justify-center gap-2 mt-8">
            <button
              onClick={handleSubmit}
              className="px-16 py-3 text-white bg-[#917244] rounded-xl"
            >
              Approve
            </button>
            <button
              onClick={handleCloseDialogWithReset}
              className="px-16 py-3 text-[#595959] border-[#595959] border rounded-xl"
            >
              Cancel
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <ReusableSnackbar />
    </>
  );
};

export default KycDialog;
