import { FormControlLabel } from "@mui/material";
import React from "react";
import { MaterialUISwitch } from "../../data/MuiButton";

const AutoRefresh = ({ toggleListen, setToggleListen }) => {
  return (
    <div className="flex items-center justify-between bg-white p-8   mt-10 rounded-xl border-b font-ibmArabic">
      <div>
        <p className="text-[#333333] text-base">Auto-Refresh</p>
        <p className="text-[#595959] text-xs mt-2 w-4/5 md:w-full">
          Enable to keep the table up-to-date automatically. Disable to refresh
          manually for the latest data.
        </p>
        <p></p>
      </div>

      <FormControlLabel
        checked={toggleListen}
        onChange={() => {
          setToggleListen(!toggleListen);
        }}
        className="ml-2"
        control={<MaterialUISwitch defaultChecked />}
      />
    </div>
  );
};

export default AutoRefresh;
