import React from "react";
import {
  Button,
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  Avatar,
  Select,
  MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import TextFieldComponent from "../../../components/TextFieldComponent";
import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  useFetchAllStoreLocations,
  useSubmitUpdateStatus,
  useUpdateDeliveredOrder,
} from "../../../services/apis/inventory";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";
import { MenuProps } from "../../../components/common/MenuProps";
import mark from "../../../svg/mark.svg";

const DeliveredOrderDialog = ({
  open,
  onClose,
  label,
  requestData,
  refetch,
  getRequestSerials,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [requestsPayload, setRequestsPayload] = useState({
    amount: null,
    storage_location: null,
  });
  const { data } = useFetchAllStoreLocations();
  const { mutate } = useUpdateDeliveredOrder();
  const dispatch = useDispatch();

  const resetForm = () => {
    setRequestsPayload({
      amount: null,
      storage_location: "",
    });
    onClose();
  };
  const handleCloseDialog = () => {
    resetForm();
  };
  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };
  const handleChange = (name, value) => {
    if (name === "storage_location") {
      const selectedLocation = data?.result?.find((item) => item.id === value);
      setRequestsPayload((prevPayload) => ({
        ...prevPayload,
        storage_location: selectedLocation ? selectedLocation.name : "",
      }));
    } else {
      setRequestsPayload((prevPayload) => ({
        ...prevPayload,
        [name]: value,
      }));
    }
  };
  const handleSubmit = () => {
    if (
      requestsPayload.amount == requestData.quantity &&
      requestsPayload.storage_location == requestData.location
    ) {
      const payload = {
        request_status: 1,
      };
      setIsSubmitting(true);
      mutate(
        {
          id: requestData.order_id,
          body: payload,
        },
        {
          onSuccess: () => {
            handleCloseDialog();
            setSuccessDialogOpen(true);
            refetch();
            getRequestSerials();
            setIsSubmitting(false);
          },
          onError: (error) => {
            dispatch(
              setData({
                openSnack: true,
                message: "An error occurred while submitting the order",
              })
            );
            setIsSubmitting(false);
          },
        }
      );
    } else {
      dispatch(
        setData({
          openSnack: true,
          message: "Please enter The Correct Quantity and Storage Location",
        })
      );
    }
  };
  return (
    <>
      <Dialog
        open={successDialogOpen}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: {
            width: "585px",
            height: "506px",
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            sx={{
              bgcolor: "#D9F4DD",
              height: "80px",
              width: "80px",
              alignSelf: "center",
              marginRight: "15px",
            }}
          >
            <img src={`../../assets/Vector.png`} alt="vector" />
          </Avatar>
          <Typography
            sx={{ marginTop: "10%", marginBottom: "10%" }}
            variant="h6"
          >
            Order Delivered Successfully.
          </Typography>
          <Button
            onClick={() => {
              handleSuccessDialogClose();
            }}
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#917244",
              "&:hover": { backgroundColor: "#917244" },
              height: "46px",
              width: "291px",
              borderRadius: "9px",
            }}
          >
            Go to order Details
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="700px"
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "700px",
            height: "auto",
            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            paddingTop: "5%",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              width: "80%",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={onClose}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box
              sx={{
                width: "80px",
                height: "80px",
                borderRadius: "50px",
              }}
            >
              <img src={mark} alt="mark" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  color: "#595959",
                  fontWeight: "400",
                  fontSize: "18px",
                  display: "flex",
                  alignSelf: "flex-start",
                }}
              >
                Are you sure you want to mark this Order as a Delivered?
              </Box>
            </Box>
            <FormControl
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <TextFieldComponent
                sx={{ "& fieldset": { border: "none" }, color: "#595959" }}
                style={{
                  color: "#595959",
                  height: "48px",
                  backgroundColor: "transparent",
                  border: "1px solid #E6E6E6",
                  borderRadius: "12px",
                }}
                name="Confirm Quantity "
                placeholder="Confirm Quantity"
                value={requestsPayload.amount || ""}
                onChange={(event) => {
                  var temppayload = { ...requestsPayload };
                  temppayload.amount = event.target.value;
                  setRequestsPayload({ ...temppayload });
                }}
              />
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#595959",
                    marginBottom: "5px",
                    marginTop: "15px",
                  }}
                >
                  Select Storage Location
                </Typography>
                <FormControl
                  style={{
                    border: "1px solid #E6E6E6",
                    borderRadius: "12px",
                  }}
                  sx={{ width: "100%", "& fieldset": { border: "none" } }}
                >
                  <Select
                    displayEmpty
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                    value={
                      data?.result?.find(
                        (item) => item.name === requestsPayload.storage_location
                      )?.id || ""
                    }
                    onChange={(event) =>
                      handleChange("storage_location", event.target.value)
                    }
                    inputProps={{ "aria-label": "Without label" }}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>Select Storage Location </em>;
                      }
                      return (
                        data?.result?.find((item) => item.id === selected)
                          ?.name || ""
                      );
                    }}
                  >
                    {data?.result?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </FormControl>

            <Box sx={{ display: "flex", gap: 2, marginTop: "10px" }}>
              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  background: "#917244",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#917244",
                  },
                }}
                onClick={handleSubmit}
              >
                {label}
              </Button>

              <Button
                variant="outlined"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#917244",
                  fontWeight: 600,
                  borderColor: "#917244",
                  "&:hover": {
                    borderColor: "#917244",
                  },
                }}
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <ReusableSnackbar />
    </>
  );
};

export default DeliveredOrderDialog;
