import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import Header from "../../../components/Header";
import { DataGridStyle } from "../../../styles";
import DashboardMainHeader from "../../../components/DashboardMainHeader";
import {
  changeResourcePermissions,
  fetchRoleDropdown,
  getforEdit,
} from "../../../services/apis/roles-and-permissions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";
import { MenuProps } from "../../../components/common/MenuProps";
import { SecondBox } from "../../../components/common/CustomBox";

const ResourcePermissions = () => {
  const [selectedResourceGroup, setSelectedResourceGroup] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [requestEdit, setRequestEdit] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleResourceGroupChange = (event) => {
    const selectedResourceId = event.target.value.id;
    const selectedResource = requestEdit.find(
      (resource) => resource.id === selectedResourceId
    );
    setSelectedResourceGroup(selectedResource);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };
  const handleApiRequest = async () => {
    if (selectedRole?.name) {
      try {
        const dataToSend = {
          user_group_id: selectedRole.id,
        };
        const response = await getforEdit(dataToSend);

        setRequestEdit(response?.data?.data);
        setSelectedResourceGroup(
          response?.data?.data.find(
            (resource) => resource.id === selectedResourceGroup?.id
          )
        );
      } catch (error) {
        dispatch(
          setData({
            openSnack: true,
            message: error?.response?.data?.message,
          })
        );
      }
    }
  };
  const handleCheckboxChange = async (resourceId, isChecked) => {
    try {
      const updatedResources = requestEdit?.map((resourceGroup) => {
        if (resourceGroup.id === resourceId) {
          return { ...resourceGroup, isChecked: !resourceGroup.isChecked };
        } else if (resourceGroup.pages && resourceGroup.pages.length > 0) {
          const updatedPages = resourceGroup.pages.map((page) => {
            if (page.id === resourceId) {
              return { ...page, isChecked: !page.isChecked };
            }
            return page;
          });
          return { ...resourceGroup, pages: updatedPages };
        }
        return resourceGroup;
      });
      setRequestEdit(updatedResources);
      const newSelectedResourceGroup = updatedResources.find(
        (resource) => resource.id === selectedResourceGroup.id
      );
      setSelectedResourceGroup(newSelectedResourceGroup);
      const dataToSend = {
        id: resourceId,
        role: selectedRole.id,
      };
      await changeResourcePermissions(dataToSend);
      setOpen(true);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
  };
  const [role, setRole] = useState([]);
  const getRoleDropdown = async () => {
    setLoading(true);
    setRole([]);
    try {
      const response = await fetchRoleDropdown();
      const role = response.data.data;
      setRole(role);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectedRole) {
      handleApiRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRole]);
  useEffect(() => {
    getRoleDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) return <LoadingSpinner />;
  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Resource Permissions" marginL={"25px"} />
      <SecondBox>
        <Box
          m="40px 0 0 0"
          height="auto"
          style={{ paddingBottom: "2%" }}
          sx={DataGridStyle}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: requestEdit.length === 0 ? null : "space-evenly",
              width: "100%",
              marginTop: "3%",
            }}
          >
            <Box
              sx={{
                width: "45%",
                marginLeft: requestEdit.length === 0 ? "3.4%" : "0px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#4C4C4C",
                  marginBottom: "5px",
                  marginTop: "15px",
                }}
              >
                Select Role
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  displayEmpty
                  onChange={handleRoleChange}
                  value={selectedRole || ""}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return <em>Select Role</em>;
                    }
                    return selected?.name;
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {role.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {requestEdit.length !== 0 && (
              <Box sx={{ width: "45%" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#4C4C4C",
                    marginBottom: "5px",
                    marginTop: "15px",
                  }}
                >
                  Select Resource Group
                </Typography>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    displayEmpty
                    onChange={handleResourceGroupChange}
                    value={selectedResourceGroup || ""}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <em>Select Resource Group</em>;
                      }
                      return selected?.title;
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {requestEdit.map((item) => (
                      <MenuItem key={item.id} value={item}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          </Box>
          {selectedResourceGroup?.pages?.length === 0 && (
            <Box
              sx={{
                width: "100%",
                marginTop: "3%",
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  width: "90%",
                }}
              >
                <Typography
                  sx={{
                    width: "20%",
                    color: "#4C4C4C",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                  variant="h6"
                >
                  Resource Name
                </Typography>
                <Typography
                  sx={{
                    width: "5%",
                    color: "#4C4C4C",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                  variant="h6"
                >
                  Allow
                </Typography>
              </Box>
              <Divider sx={{ my: 1 }} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  width: "90%",
                }}
                key={selectedResourceGroup.id}
                mb={2}
              >
                <Typography sx={{ width: "20%" }} variant="h6">
                  {selectedResourceGroup.title}
                </Typography>
                <FormControlLabel
                  sx={{
                    width: "3%",
                  }}
                  key={selectedResourceGroup.id}
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: "#2196F3",
                        },
                        "&.MuiCheckbox-root:not(.Mui-checked)": {
                          color: "#4C4C4C",
                        },
                      }}
                      checked={selectedResourceGroup.isChecked}
                      onChange={() =>
                        handleCheckboxChange(
                          selectedResourceGroup.id,
                          !selectedResourceGroup.isChecked
                        )
                      }
                    />
                  }
                />
              </Box>
            </Box>
          )}
          {selectedResourceGroup?.pages?.length > 0 && (
            <Box
              sx={{
                width: "100%",
                marginTop: "3%",
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  width: "90%",
                }}
              >
                <Typography
                  sx={{
                    width: "20%",
                    color: "#4C4C4C",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                  variant="h6"
                >
                  Resource Name
                </Typography>
                <Typography
                  sx={{
                    width: "5%",
                    color: "#4C4C4C",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                  variant="h6"
                >
                  Allow
                </Typography>
              </Box>
              <Divider sx={{ my: 1 }} />
              {selectedResourceGroup?.pages?.map((resource) => (
                <div key={resource?.id}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      width: "90%",
                    }}
                    key={resource.id}
                    mb={2}
                  >
                    <Typography sx={{ width: "20%" }} variant="h6">
                      {resource.title}
                    </Typography>
                    <FormControlLabel
                      sx={{
                        width: "3%",
                      }}
                      key={resource.id}
                      control={
                        <Checkbox
                          sx={{
                            "&.Mui-checked": {
                              color: "#917244",
                            },
                            "&.MuiCheckbox-root:not(.Mui-checked)": {
                              color: "#4C4C4C",
                            },
                          }}
                          checked={resource?.isChecked}
                          onChange={() =>
                            handleCheckboxChange(
                              resource.id,
                              !resource.isChecked
                            )
                          }
                        />
                      }
                    />
                  </Box>
                  <Divider sx={{ my: 1 }} />
                </div>
              ))}
            </Box>
          )}
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Action added to Role
            </Alert>
          </Snackbar>
        </Box>
      </SecondBox>
      <ReusableSnackbar />
    </Box>
  );
};

export default ResourcePermissions;
