import React from "react";

const NewUserInfoField = ({ title, data, field }) => {
  return (
    <div className="font-ibmArabic flex items-center ">
      <p className="text-lg text-[#595959] mr-2">{title} :</p>
      {field ? field : <p className="text-lg text-[#595959] ">{data || "-"}</p>}
    </div>
  );
};

export default NewUserInfoField;
