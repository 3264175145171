// import React, { useRef, useMemo } from "react";
// import JoditEditor from "jodit-react";

// export const MyEditor = ({ placeholder, text, setText }) => {
//   const editor = useRef(null);
//   const config = useMemo(
//     () => ({
//       disablePlugins: ["paste"],
//       askBeforePasteHTML: false,
//       processPasteHTML: false,
//       nl2brInPlainText: false,
//       readonly: false, // all options from https://xdsoft.net/jodit/docs/,
//       placeholder: placeholder || "Start typings...",
//       defaultActionOnPasteFromWord: "insert_clear_html",
//     }),
//     [placeholder]
//   );
//   return (
//     <JoditEditor
//       ref={editor}
//       value={text}
//       config={config}
//       tabIndex={1} // tabIndex of textarea
//       onBlur={setText} // preferred to use only this option to update the content for performance reasons
//       onChange={(newContent) => {}}
//     />
//   );
// };

import React, { useRef, useMemo } from "react";
import JoditEditor from "jodit-react";

export const MyEditor = ({ placeholder, text, setText }) => {
  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: placeholder || "Start typing...",
      defaultActionOnPasteFromWord: "insert_clear_html",
      buttons: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "ul",
        "ol",
        "font",
        "fontsize",
        "brush",
        "paragraph",
        "align",
        "undo",
        "redo",
        "link",
        "image",
        "file", // Add "file" button to allow file uploads
      ],
      removeButtons: ["video"], // Explicitly remove the video button
      uploader: {
        insertImageAsBase64URI: true, // Allows direct insertion of image data
        url: "https://your-upload-endpoint.com/upload", // Replace with your upload endpoint
        format: "application/pdf", // Optional: Restrict uploads to PDF format
        isSuccess: (response) => response.success, // Update based on your API response
        getMessage: (response) => response.message || "Upload failed", // Optional: custom error message
      },
      filebrowser: {
        ajax: {
          url: "https://your-file-browser-endpoint.com/files", // Replace with your file browser endpoint
        },
        format: "application/pdf", // Optional: Restrict to PDF files
      },
    }),
    [placeholder]
  );

  return (
    <JoditEditor
      ref={editor}
      value={text}
      config={config}
      tabIndex={1} // tabIndex of textarea
      onBlur={(newContent) => setText(newContent)} // Update the parent state on blur
      onChange={(newContent) => {}}
    />
  );
};
