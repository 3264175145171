import { Box, IconButton, InputBase } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { useFetchUsers } from "../../services/apis/users";
import { debounce, formDate } from "../../services/helpers";
import { handlePageChange, checkString } from "../../services/utils/filters";
import FilterDrawer from "../../components/common/FilterDrawer";
import { handleFilterByString } from "../../services/utils/filters";
import Header from "../../components/Header";
import { saveAs } from "file-saver";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";
import AutoRefresh from "../../components/common/AutoRefresh";
import { useGlobalSocket } from "../../useGlobalSocket";
import NewTable from "../../components/common/NewTable";
import UserFilterDrawer from "./UserFilterDrawer";
const Users = () => {
  const navigate = useNavigate();

  const handleRowClick = (row) => {
    navigate(`/users/${row.id}`);
  };
  const [queryParameters, setQueryParameters] = useState({
    date_from: null,
    date_to: null,
    kyc_status: "",
    is_online: "all",
    orderBy: null,
    sort: null,
    page: 0,
    limit: 10,
    query: "",
    user_groups: [],
  });
  console.log("queryParameters", queryParameters);

  const { data, isFetching, refetch } = useFetchUsers(queryParameters);
  const [toggleListen, setToggleListen] = useState(false);
  const { addEventListener, removeEventListener } = useGlobalSocket();

  useEffect(() => {
    if (toggleListen) {
      refetch();
      addEventListener("users-update", ({ user_id }) => {
        refetch();
      });
      return () => {
        removeEventListener("users-update", () => {});
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addEventListener, toggleListen]);

  const newUsersColumns = ItemsColumns([
    { field: "full_name", headerName: "Username", flex: 2 },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 2,
      specialRender: "customRender",
      renderFunction: (params) => {
        return <p>0{params?.row?.phone}</p>;
      },
    },
    { field: "email", flex: 2 },
    {
      field: "status",
      headerName: "Document Status",
      documentStatus: true,
      flex: 2,
    },
    {
      field: "is_online",
      headerName: "Online Status",
      isOnline: true,
      flex: 2,
    },

    { field: "created", headerName: "Join Date", date: true, flex: 2 },
  ]);

  // Debounced handler
  const handleSearch = useCallback(
    debounce((value) => {
      setQueryParameters((prev) => ({
        ...prev,
        query: value,
      }));
    }, 500),
    []
  );

  const downloadUsers = async () => {
    try {
      const headers = [
        "ID",
        "Username",
        "Email",
        "Phone Number",
        "Balance",
        "Online Status",
        "Join Date",
        "KYC",
      ];
      const csvContent = [
        headers.join(","),
        ...data?.result?.map((user) => {
          return [
            user.id,
            user.full_name,
            user.email,
            user.phone,
            user.currentBalance ?? 0,
            user.is_online ? "online" : "offline",
            `"${formDate(user.created)}"`,
            user.kyc_status,
          ].join(",");
        }),
      ].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      saveAs(blob, "users.csv");
    } catch (error) {
      console.error("Error downloading users:", error);
    }
  };

  return (
    <Box>
      <Header title="User Information" marginL={"25px"} />
      <div
        className="bg-white mx-4 rounded-xl"
        style={{
          boxShadow: "0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012",
        }}
      >
        <AutoRefresh
          setToggleListen={setToggleListen}
          toggleListen={toggleListen}
        />
        <div className=" m-8 md:flex items-center justify-between">
          <div className="flex items-center rounded-xl bg-[#f5f5f5] p-2 w-[95%] md:w-3/5">
            <IconButton type="button" aria-label="search">
              <img alt="search-normal" src={`../../assets/search-normal.png`} />
            </IconButton>
            <InputBase
              onChange={(event) => {
                handleSearch(event.target.value);
              }}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search using Phone number , email or National ID.... "
            />
            <UserFilterDrawer
              data={queryParameters}
              setData={setQueryParameters}
            />
          </div>
          <button
            onClick={() => downloadUsers()}
            className="rounded-xl bg-[#917244] text-white px-12 py-3 text-sm mt-4 w-[95%] md:w-fit hidden md:block   md:m-0 mr-10"
          >
            Download Report
          </button>
        </div>
        <div className="">
          <NewTable
            setLimit={setQueryParameters}
            limit={queryParameters.limit}
            centerHeader
            columns={newUsersColumns}
            isLoading={isFetching}
            data={data?.data?.data?.result}
            totalItems={data?.data?.data?.totalItems}
            totalPages={data?.data?.data?.totalPages}
            handlePageChange={(newPage) =>
              handlePageChange(newPage, queryParameters, setQueryParameters)
            }
            handleRowClick={handleRowClick}
            currentPage={queryParameters}
          />
        </div>
        <div className="flex items-center justify-center m-2">
          <button
            onClick={() => downloadUsers()}
            className="rounded-lg bg-[#917244] text-white px-4 py-2 text-sm mt-4 w-[90%] md:hidden mx-auto  md:m-0"
          >
            Download Report
          </button>
        </div>
      </div>
    </Box>
  );
};

export default Users;
