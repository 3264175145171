import React, { useState } from "react";
import { Drawer } from "@mui/material";
import { IoFilterOutline } from "react-icons/io5";
import closeCircle from "../../svg/new-close-circle.svg";
import UserDateFilter from "./UserDateFilter";
import UserDocumentStatusFilter from "./UserDocumentStatusFilter";
import UserStatusFilter from "./UserStatusFilter";
import UserActiveStatusFilter from "./UserActiveStatusFilter";
import useUserFilterData from "../../zustand/useUserFilterData";
const UserFilterDrawer = ({ data, setData }) => {
  const {
    date_from,
    date_to,
    kyc_status,
    is_online,
    user_groups,
    setDateFrom,
    setDateTo,
    setKycStatus,
    setOnlineStatus,
    setUserGroups,
  } = useUserFilterData();
  const [open, setOpen] = useState(false);
  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(isOpen);
  };

  return (
    <div>
      <IoFilterOutline
        size={25}
        style={{ cursor: "pointer" }}
        onClick={toggleDrawer(true)} // Open the drawer when clicked
      />
      <Drawer
        anchor="right" // Open drawer from the right
        open={open}
        onClose={toggleDrawer(false)} // Close the drawer when needed
      >
        <div className="w-96 p-8">
          <img
            src={closeCircle}
            alt="close"
            className="mb-8 cursor-pointer"
            onClick={toggleDrawer(false)}
          />
          {/* //? <--------------Date-------------> */}
          <UserDateFilter />
          {/* //! Document Status */}
          <UserDocumentStatusFilter />
          {/* //! Online Status */}
          <UserStatusFilter />
          {/* //! User Status */}
          <UserActiveStatusFilter />
          {/* //? Filter and Reset Button */}
          <div className="flex items-center gap-2 justify-center mt-10">
            <button
              onClick={() => {
                setOpen(false); // Directly close the drawer
                setData({
                  ...data,
                  page: 0,
                  date_to: date_to,
                  date_from: date_from,
                  kyc_status: kyc_status,
                  is_online: is_online.includes("all")
                    ? "all"
                    : is_online.includes("true") && is_online.includes("false")
                    ? "all"
                    : is_online.includes("true") && !is_online.includes("false")
                    ? "true"
                    : !is_online.includes("true") && is_online.includes("false")
                    ? "false"
                    : "all",
                  user_groups: user_groups.includes("all")
                    ? []
                    : user_groups.includes("active") &&
                      user_groups.includes("blocked")
                    ? []
                    : user_groups.includes("active") &&
                      !user_groups.includes("blocked")
                    ? [3, 4]
                    : !user_groups.includes("active") &&
                      user_groups.includes("blocked")
                    ? [5]
                    : [],
                });
              }}
              className="text-xs font-medium px-16 py-3 font-ibmArabic bg-[#917244] rounded-xl text-white"
            >
              Filter
            </button>
            <button
              onClick={() => {
                setDateFrom("");
                setDateTo("");
                setKycStatus([]);
                setOnlineStatus([]);
                setUserGroups([]);
                setData({
                  ...data,
                  date_from: null,
                  date_to: null,
                  kyc_status: "",
                  is_online: "all",
                  orderBy: null,
                  sort: null,
                  page: 0,
                  limit: 10,
                  query: "",
                  user_groups: [],
                });
              }}
              className="text-xs font-medium px-16 py-3 font-ibmArabic   border border-[#917244] rounded-xl text-[#917244]"
            >
              Reset
            </button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default UserFilterDrawer;
