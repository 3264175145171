import React from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const CustomDialog = ({
  open,
  onClose,
  title,
  message,
  buttonText,
  buttonLink,
  goBack,
}) => {
  const navigate = useNavigate();

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleButtonClick = () => {
    if (goBack) {
      navigate(-1);
    } else if (buttonLink) {
      navigate(buttonLink);
    } else handleClose();
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      PaperProps={{
        style: {
          width: "585px",
          height: "506px",
          borderRadius: "24px",
        },
      }}
    >
      <DialogContent
        sx={{
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Avatar
          sx={{
            bgcolor: "#D9F4DD",
            height: "80px",
            width: "80px",
            alignSelf: "center",
            marginRight: "15px",
          }}
        >
          <img src={`../../assets/Vector.png`} alt="Avatar" />
        </Avatar>
        <Typography sx={{ marginTop: "10%", marginBottom: "10%" }} variant="h6">
          {title}
        </Typography>
        <Typography>{message}</Typography>
        {buttonText && (
          <Button
            onClick={handleButtonClick}
            type="button"
            variant="contained"
            sx={{
              backgroundColor: "#917244",
              "&:hover": { backgroundColor: "#917244" },
              height: "46px",
              width: "291px",
              borderRadius: "9px",
            }}
          >
            {buttonText}
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
