import React from "react";
import ImageDialog from "../../components/common/ImageDialog";

const KycCard = ({ img, title, isLoading }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="h-[210px] w-full bg-white  overflow-hidden">
      {isLoading ? (
        <div className="h-[140px] w-full bg-gray-300 animate-pulse" />
      ) : (
        <img
          src={img}
          alt={title}
          loading="lazy"
          className="h-[158px] w-full object-cover cursor-pointer rounded-xl"
          onClick={handleClickOpen}
        />
      )}
      <p className="text-sm text-[#595959]  font-ibmArabic pt-3">{title}</p>
      <ImageDialog isOpen={open} onClose={handleClose} imageUrl={img} />
    </div>
  );
};

export default KycCard;
