import { useState, useEffect } from "react";
import TextFieldComponent from "../../components/TextFieldComponent";
import {
  Box,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { MenuProps } from "../../components/common/MenuProps";

const StepOneFaqComponent = ({ setFaqData, faqData, isEnglish }) => {
  const [selectedTopic, setSelectedTopic] = useState(null);

  // Sync `selectedTopic` with `faqData?.is_popular` when `faqData` changes
  useEffect(() => {
    const initialTopic = topic.find(
      (item) => item.is_popular === faqData?.is_popular
    );
    setSelectedTopic(initialTopic || "");
  }, [faqData]);

  const topic = [
    { id: 1, name: "Is Popular", is_popular: 1 },
    { id: 2, name: "Other", is_popular: 0 },
  ];

  const handleTopicChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedTopic(selectedValue);
    setFaqData((prevFaqData) => ({
      ...prevFaqData,
      is_popular: selectedValue.is_popular,
    }));
  };

  const handleInputChange = (field, event) => {
    const inputValue = event.target.value;
    if (isEnglish(inputValue)) {
      const updatedPayload = { ...faqData };
      const enTranslation = updatedPayload.trans.find((t) => t.local === "en");
      if (enTranslation) {
        enTranslation[field] = inputValue;
        setFaqData(updatedPayload);
      }
    }
  };

  return (
    <Box sx={{ marginTop: "3%" }}>
      <Box sx={{ width: "100%" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#4C4C4C",
            marginBottom: "5px",
            marginTop: "15px",
          }}
        >
          Select Topic
        </Typography>

        <FormControl sx={{ width: "100%" }}>
          <Select
            displayEmpty
            onChange={handleTopicChange}
            value={selectedTopic || ""}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (!selected) {
                return <em>Select Topic</em>;
              }
              return selected.name;
            }}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            {topic.map((item) => (
              <MenuItem key={item.id} value={item}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Question"
        placeholder="Enter Question"
        value={faqData.trans.find((t) => t.local === "en").title}
        onChange={(event) => {
          let allowedEnglishRegex = /^[a-zA-Z0-9"()@'?:%.,\- ]*$/;
          if (allowedEnglishRegex.test(event.target.value)) {
            handleInputChange("title", event);
          }
        }}
      />
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Answer"
        placeholder="Enter Answer"
        value={faqData.trans.find((t) => t.local === "en").description}
        onChange={(event) => {
          let allowedEnglishRegex = /^[a-zA-Z0-9"()@'?:%.,\- ]*$/;
          if (allowedEnglishRegex.test(event.target.value)) {
            handleInputChange("description", event);
          }
        }}
      />
    </Box>
  );
};

export default StepOneFaqComponent;
