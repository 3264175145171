import React from "react";
import { Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { handleFromDate, handleToDate } from "../../services/utils/filters";
import dayjs from "dayjs";

const DateFiltering = ({ queryParameters, setQueryParameters }) => {
  return (
    <div className="  flex-col mt-4 ">
      <p className="text-[#595959] text-sm font-ibmArabic mb-2">From</p>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* Customized From Date Picker */}
        <DatePicker
          onChange={(d) =>
            handleFromDate(d, queryParameters, setQueryParameters)
          }
          value={
            queryParameters?.date_from !== ""
              ? dayjs(queryParameters?.date_from)
              : null
          }
          maxDate={dayjs()}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              padding: "8px",
            },
            "& .MuiInputBase-input": {
              color: "#757575",
              fontSize: "14px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiSvgIcon-root": {
              color: "#757575",
              marginRight: "8px",
            },
          }}
        />
      </LocalizationProvider>
      <p className="text-[#595959] text-sm font-ibmArabic my-2">To</p>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* Customized To Date Picker */}
        <DatePicker
          value={
            queryParameters?.date_to !== ""
              ? dayjs(queryParameters?.date_to)
              : null
          }
          onChange={(d) => handleToDate(d, queryParameters, setQueryParameters)}
          minDate={
            queryParameters.date_from !== ""
              ? dayjs(queryParameters.date_from)
              : null
          }
          maxDate={dayjs()}
          sx={{
            width: "100%",

            "& .MuiOutlinedInput-root": {
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              padding: "8px 12px",
            },
            "& .MuiInputBase-input": {
              color: "#757575",
              fontSize: "14px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiSvgIcon-root": {
              color: "#757575",
              marginRight: "8px",
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default DateFiltering;
