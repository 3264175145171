import React from "react";
import {
  Button,
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  Avatar,
  Select,
  MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import TextFieldComponent from "../../../components/TextFieldComponent";
import { useEffect } from "react";

import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  addNewInventoryRequest,
  useFetchAllStoreLocations,
} from "../../../services/apis/inventory";
import { useFetchProducts } from "../../../services/apis/products";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";
import { MenuProps } from "../../../components/common/MenuProps";

const RequestOrderDialog = ({
  open,
  onClose,
  label,
  fetchData,
  handleResponse,
  selectedRequest,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [type, setType] = useState("fraction");
  const dispatch = useDispatch();
  const [productsqueryParameters] = useState({
    inventory_type: "fraction",
  });
  const { data: storeLocationData } = useFetchAllStoreLocations();

  const { data: products } = useFetchProducts(
    productsqueryParameters.inventory_type
  );
  const [requestsPayload, setRequestsPayload] = useState({
    product_id:
      selectedRequest?.name === "Fractions"
        ? null
        : selectedRequest?.gold_bar_id,
    amount: null,
    storage_location: null,
  });
  const provider = [
    { id: 1, name: "Dahab masr" },
    { id: 2, name: "Dahab masr 2" },
  ];

  const resetForm = () => {
    setRequestsPayload({
      product_id: null,
      amount: null,
      storage_location: null,
    });
    onClose();
  };
  const handleCloseDialog = () => {
    resetForm();
    setType("fraction");
  };
  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };
  const handleChange = (name, value) => {
    if (name === "type") {
      setType(value);
    } else {
      setRequestsPayload((prevPayload) => ({
        ...prevPayload,
        [name]: value,
      }));
    }
  };
  const validateFields = () => {
    return (
      requestsPayload.product_id &&
      requestsPayload.amount &&
      requestsPayload.storage_location
    );
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (validateFields()) {
        const amount = parseFloat(requestsPayload.amount);
        const isValidAmount = /^\d+(\.\d+)?$/.test(requestsPayload.amount);
        if (isValidAmount && !isNaN(amount) && amount > 0) {
          setIsSubmitting(true);
          await addNewInventoryRequest(requestsPayload);
          onClose();
          setSuccessDialogOpen(true);
          await handleResponse();
        } else {
          dispatch(
            setData({
              openSnack: true,
              message: "Please enter a valid number for the amount.",
            })
          );
        }
      } else {
        dispatch(
          setData({
            openSnack: true,
            message: "Please fill in all fields.",
          })
        );
      }
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error?.response?.data?.message,
        })
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    setRequestsPayload({
      product_id:
        selectedRequest?.name === "Fractions"
          ? null
          : selectedRequest?.gold_bar_id,
      amount: null,
      storage_location: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    open,
    selectedRequest,
    // fetchData,
    // productsqueryParameters,
    // type,
    // selectedRequest?.gold_bar_id,
  ]);
  return (
    <>
      <Dialog
        open={successDialogOpen}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: {
            width: "585px",
            height: "506px",
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            sx={{
              bgcolor: "#D9F4DD",
              height: "80px",
              width: "80px",
              alignSelf: "center",
              marginRight: "15px",
            }}
          >
            <img src={`../../assets/Vector.png`} alt="vector" />
          </Avatar>
          <Typography
            sx={{ marginTop: "10%", marginBottom: "10%" }}
            variant="h6"
          >
            Request has been sent Successfully.
          </Typography>
          <Button
            onClick={() => {
              handleSuccessDialogClose();
            }}
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#917244",
              "&:hover": { backgroundColor: "#917244" },
              height: "46px",
              width: "291px",
              borderRadius: "9px",
            }}
          >
            Go to Pending Orders
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="700px"
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "700px",
            height: "auto",
            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            paddingTop: "5%",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              width: "80%",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={onClose}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  color: "#000000",
                  fontWeight: "400",
                  fontSize: "24px",
                  display: "flex",
                  alignSelf: "flex-start",
                }}
              >
                Request Order
              </Box>
            </Box>

            <FormControl
              sx={{ display: "flex", width: "100%", flexDirection: "column" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: "5px",
                  marginTop: "2%",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#4C4C4C",
                  }}
                >
                  Type:
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    color: "#808080",
                    marginLeft: "5px",
                  }}
                >
                  {selectedRequest?.name === "Fractions"
                    ? "Fractions"
                    : "Ingots"}
                </Typography>
              </Box>
              {selectedRequest?.name !== "Fractions" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "5px",
                    marginTop: "4%",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      color: "#4C4C4C",
                    }}
                  >
                    Product Name :
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      color: "#808080",
                      marginLeft: "5px",
                    }}
                  >
                    {selectedRequest?.name}
                  </Typography>
                </Box>
              )}
              {selectedRequest?.name === "Fractions" && (
                <Box sx={{ width: "100%" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#4C4C4C",
                      marginBottom: "5px",
                      marginTop: "15px",
                    }}
                  >
                    Select Product
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      displayEmpty
                      input={<OutlinedInput />}
                      MenuProps={MenuProps}
                      value={requestsPayload.product_id || ""}
                      onChange={(event) =>
                        handleChange("product_id", event.target.value)
                      }
                      inputProps={{ "aria-label": "Without label" }}
                      renderValue={(selected) => {
                        if (!selected) {
                          return <em>Select Product</em>;
                        }
                        const selectedProduct = products.find(
                          (product) => product.id === selected
                        );
                        return selectedProduct ? selectedProduct.name : "";
                      }}
                    >
                      {products.map((product) => (
                        <MenuItem key={product.id} value={product.id}>
                          {product.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}

              {/* <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#4C4C4C",
                    marginBottom: "5px",
                    marginTop: "15px",
                  }}
                >
                  Select Provider
                </Typography>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    displayEmpty
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                    value={requestsPayload.provider_id || ""}
                    onChange={(event) =>
                      handleChange("provider_id", event.target.value)
                    }
                    inputProps={{ "aria-label": "Without label" }}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>Select Provider</em>;
                      }
                      return (
                        provider.find((item) => item.id === selected)?.name ||
                        ""
                      );
                    }}
                  >
                    {provider.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box> */}
              <TextFieldComponent
                sx={{ "& fieldset": { border: "none" } }}
                style={{
                  height: "48px",
                  width: "534px",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "9px",
                }}
                name="Enter Quantity"
                placeholder="Enter Quantity"
                value={requestsPayload.amount || ""}
                onChange={(event) => {
                  const value = event.target.value;

                  // Only allow numeric values
                  if (/^\d*$/.test(value)) {
                    setRequestsPayload((prevPayload) => ({
                      ...prevPayload,
                      amount: value,
                    }));
                  }
                }}
                // onChange={handleChange}
              />
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#595959",
                    marginBottom: "5px",
                    marginTop: "15px",
                  }}
                >
                  Select Storage Location
                </Typography>
                <FormControl
                  style={{
                    border: "1px solid #E6E6E6",
                    borderRadius: "12px",
                  }}
                  sx={{ width: "100%", "& fieldset": { border: "none" } }}
                >
                  <Select
                    displayEmpty
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                    value={requestsPayload.storage_location || ""}
                    onChange={(event) =>
                      handleChange("storage_location", event.target.value)
                    }
                    inputProps={{ "aria-label": "Without label" }}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>Select Storage Location </em>;
                      }
                      return (
                        storeLocationData?.result?.find(
                          (item) => item.id === selected
                        )?.name || ""
                      );
                    }}
                  >
                    {storeLocationData?.result?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {/* <TextFieldComponent
                sx={{ "& fieldset": { border: "none" } }}
                style={{
                  height: "48px",
                  width: "534px",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "9px",
                }}
                name="Enter Storage Location"
                placeholder="Enter Storage Location"
                value={requestsPayload.storage_location || ""}
                onChange={(event) => {
                  var temppayload = { ...requestsPayload };
                  temppayload.storage_location = event.target.value;
                  setRequestsPayload({ ...temppayload });
                }}
              /> */}
            </FormControl>

            <Box sx={{ display: "flex", gap: 2, marginTop: "10px" }}>
              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  background: "#917244",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#917244",
                  },
                }}
                onClick={handleSubmit}
              >
                {label}
              </Button>

              <Button
                variant="outlined"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#917244",
                  fontWeight: 600,
                  borderColor: "#917244",
                  "&:hover": {
                    borderColor: "#917244",
                  },
                }}
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <ReusableSnackbar />
    </>
  );
};

export default RequestOrderDialog;
