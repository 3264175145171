import useWindowSize from "../../hooks/useWindowSize";
import arrowLeft from "../../svg/arrow-left.svg";
import arrowRight from "../../svg/arrow-right.svg";
const NewTable = ({
  isLoading,
  columns,
  data,
  totalPages,
  handlePageChange,
  currentPage,
  handleRowClick = () => {},
  onCellClick,
  noPagination,
  rows,
  centerHeader,
  className,
  setLimit,
}) => {
  const { width } = useWindowSize();
  const rowsPerPage = rows ? rows : 10; // Adjust this based on your pagination size

  // Helper function to display page numbers with only 3 on the left and 2 on the right of the current page
  const renderPageNumbers = () => {
    const pageNumbers = [];

    const startPage = Math.max(0, currentPage?.page - 1); // 3 numbers on the left
    const endPage = Math.min(totalPages - 1, currentPage?.page + 1); // 2 numbers on the right

    // Add first page if necessary
    if (startPage > 0) {
      pageNumbers.push(
        <button
          key={0}
          className={`  px-3 py-1  ${
            currentPage?.page === 0
              ? "border-blue-500 text-blue-500 font-semibold"
              : " text-gray-500 hover:text-gray-700 "
          } transition-all`}
          onClick={() => handlePageChange(0)}
        >
          1
        </button>
      );

      if (startPage > 1) {
        pageNumbers.push(
          <span key="start-ellipsis" className="px-3 py-1 text-gray-500">
            ...
          </span>
        );
      }
    }

    // Display the pages between startPage and endPage
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={` mx-2  ${
            currentPage?.page === i
              ? "text-[#333333] font-semibold bg-[#f5f5f5] p-4 rounded-lg"
              : " text-[#b3b3b3] hover:text-gray-700"
          } transition-all`}
          onClick={() => handlePageChange(i)}
        >
          {i + 1}
        </button>
      );
    }

    // Add ellipsis and last page if necessary
    if (endPage < totalPages - 2) {
      pageNumbers.push(
        <span key="end-ellipsis" className="px-3 py-1 text-gray-500">
          ...
        </span>
      );
    }

    if (endPage < totalPages - 1) {
      pageNumbers.push(
        <button
          key={totalPages - 1}
          className={`px-3 py-1  ${
            currentPage?.page === totalPages - 1
              ? "border-blue-500 text-blue-500 font-semibold"
              : "border-transparent text-gray-500 hover:text-gray-700"
          } transition-all`}
          onClick={() => handlePageChange(totalPages - 1)}
        >
          {totalPages}
        </button>
      );
    }
    return pageNumbers;
  };
  return (
    <div className={`${className} font-ibmArabic`}>
      <div className={`font-ibmArabic overflow-x-auto  `}>
        <table className="min-w-full bg-white border-b ">
          <thead className="bg-[#f5f5f5]">
            <tr>
              {columns.map((col) => (
                <th
                  key={col.field}
                  className={`
                    ${
                      col.headerName === "KYC" ||
                      col.headerName === "Actions" ||
                      col.headerName === "Order Status" ||
                      col.headerName === "Product Status"
                        ? "text-center"
                        : "text-left"
                    }
                    px-5 py-5  text-sm font-medium text-[#595959]   tracking-wider border-b border-gray-200 font-ibm 
                    `}
                >
                  {col.headerName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 text-left ">
            {isLoading ? (
              [...Array(rowsPerPage)].map((_, index) => (
                <tr key={index} className="animate-pulse">
                  {columns.map((col, colIndex) => (
                    <td key={colIndex} className="px-6 py-4 whitespace-nowrap">
                      <div className="h-4 bg-gray-200  "></div>
                    </td>
                  ))}
                </tr>
              ))
            ) : data && data.length > 0 ? (
              data.map((row) => (
                <tr
                  key={row.id}
                  className="hover:bg-gray-50 cursor-pointer"
                  onClick={() => handleRowClick(row)}
                >
                  {columns.map((col, colIndex) => (
                    <td
                      key={colIndex}
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-700"
                      onClick={() =>
                        onCellClick && onCellClick({ row, field: col.field })
                      }
                    >
                      {col.renderCell
                        ? col.renderCell({ row })
                        : row[col.field] || "-"}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={columns.length}
                  className="text-center px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* Pagination Controls */}
      <div
        className={` 
            p-4
         flex  items-center  justify-center    mx-4 
          `}
      >
        <button
          className={`text-[#595959] font-semibold text-xs font-ibmArabic mx-2  flex items-center   hover:text-gray-700 transition-colors   ${
            currentPage?.page === 0 ? "cursor-not-allowed opacity-50" : ""
          }`}
          onClick={() => handlePageChange(currentPage?.page - 1)}
          disabled={currentPage?.page === 0}
        >
          <img src={arrowLeft} alt="" className="" />
          <span className="mx-2"> Previous</span>
        </button>

        <div
          className={`
           flex   items-center w-fit text-center justify-center text-nowrap
            ${width > 700 && renderPageNumbers().length === 0 ? "hidden" : ""}
          `}
        >
          {/* Page Numbers with Ellipsis */}

          {width > 700 ? (
            renderPageNumbers()
          ) : (
            <span className="text-gray-500 text-sm ">
              {data?.length > 0 ? currentPage?.page + 1 : 0} of {totalPages}
            </span>
          )}
        </div>

        <button
          className={`text-[#595959] font-semibold text-xs font-ibmArabic   flex items-center   hover:text-gray-700 transition-colors   ${
            currentPage?.page === totalPages - 1
              ? "cursor-not-allowed opacity-50"
              : ""
          }`}
          onClick={() => handlePageChange(currentPage?.page + 1)}
          disabled={currentPage?.page === totalPages - 1 || totalPages === 0}
        >
          <span className="mx-2    ">Next</span>
          <img src={arrowRight} alt="" className=" " />
        </button>
        <div className="flex items-center gap-3">
          <p className="text-[#595959] font-semibold text-xs ml-14 font-ibmArabic">
            Rows Per Page:
          </p>
          <select
            onChange={(e) => {
              setLimit((prev) => {
                return { ...prev, limit: e.target.value };
              });
            }}
            name=""
            id=""
            className="border-2 rounded-lg border-[#e6e6e6] py-2 pl-1 font-semibold text-[#595959] text-xs font-ibmArabic"
          >
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default NewTable;
