// StepperStyles.js
import { makeStyles } from "@mui/styles";

export const useStylesHook = makeStyles(() => ({
  root: {
    "& .MuiStepIcon-active": { color: "#2196F3 !important" },
    "& .MuiStepIcon-completed": { color: "#4C4C4C !important" },
    "& .Mui-disabled .MuiStepIcon-root": { color: "#B3B3B3 !important" },
    "& .MuiStepLabel-root .Mui-active": {
      color: "#917244",
    },
    "& .MuiStepLabel-root .Mui-completed": {
      color: "#917244",
    },
  },
}));
