import React, { useEffect } from "react";
import { Typography, Box, Button, Divider, Skeleton } from "@mui/material";
import {
  approveUser,
  unBlockUser,
  useNewBlock,
  useUserDetails,
} from "../../services/apis/users";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { imageBaseUrl } from "../../services/config";
import KycDialog from "./KycDialog";
import Header from "../../components/Header";
import UserInfoField from "./UserInfoField";
import { makeStyles } from "@mui/styles";
import KycCard from "./KycCard";
import ActionAlert from "./ActionAlert";
import BlockUserDialog from "./BlockUserDialog";
import CustomDialog from "../../components/CustomDialog";
import dayjs from "dayjs";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { useGlobalSocket } from "../../useGlobalSocket";
import NewUserInfoField from "./NewUserInfoField";
import { formDate } from "../../services/helpers";
import { capitalizeFirstLetter } from "../../services/utils/stylesModifiers";
import BottomAlert from "../../components/common/BottomAlert";
const useStyles = makeStyles({
  root: {
    textTransform: "none",
  },
});

const UserProfile = () => {
  const [disableWhileDeleting, setDisableWhileDeleting] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useUserDetails(id);
  const { addEventListener } = useGlobalSocket();

  // Example of adding a custom event listener
  useEffect(() => {
    addEventListener("users-update", ({ user_id }) => {
      // eslint-disable-next-line eqeqeq
      if (user_id == id) {
        refetch();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addEventListener]);

  const block = "";
  const [formData, setFormData] = useState({
    reject_kyc_reason: "",
  });
  const [message, setMessage] = useState("");
  const [approveButton, setApproveButton] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [rejectButton, setRejectButton] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openBlockedAlert, setOpenBlockedAlert] = useState(false);
  const [type, setType] = useState("");
  const [userGroup, setUserGroup] = useState(null);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const { mutateAsync } = useNewBlock();
  const dispatch = useDispatch();
  const handleApprove = async (nid) => {
    try {
      await approveUser("approved", id, nid);
      refetch();
      setApproveButton(false);
      setOpenDialog(false);
      setMessage("The document has been successfully approved.");
      setOpenSuccessAlert(true);
      setTimeout(() => setOpenSuccessAlert(false), 5000);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error?.data?.data?.message || error?.response?.data?.message,
        })
      );
    }
  };

  const handleReject = async () => {
    setDisableWhileDeleting(true);
    try {
      await approveUser("rejected", id, {
        kyc_rejection_reason_id: formData.reject_kyc_reason,
      });
      setFormData({ reject_kyc_reason: "" });
      setDisableWhileDeleting(false);
      refetch();
      setRejectButton(false);
      setMessage("The document has been successfully rejected.");
      setOpenSuccessAlert(true);
      setTimeout(() => setOpenSuccessAlert(false), 5000);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error?.response?.data?.message,
        })
      );
    }
    setOpenAlert(false);
  };

  const handleBlock = async (data) => {
    await mutateAsync({
      id: Number(id),
      block_reason_id: data?.selectedReason,
      unBlock_at: data?.dateTo,
    }).then(() => {
      dispatch(
        setData({
          severity: "success",
          openSnack: true,
          message: "User has been blocked successfully",
        })
      );
    });
    refetch();
    setOpenBlockedAlert(false);
  };
  const handleUnBlock = async () => {
    try {
      await unBlockUser(id);
      refetch();
      setSuccessDialogOpen(true);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error?.data?.data?.message,
        })
      );
    }
    setOpenBlockedAlert(false);
  };

  const checkUserStatus = (user) => {
    if (user.kyc_status.toLowerCase() === "pending") {
      setApproveButton(true);
      setRejectButton(true);
    }

    if (user.kyc_status.toLowerCase() === "approved") {
      setApproveButton(false);
      setRejectButton(true);
    }

    if (user.kyc_status.toLowerCase() === "rejected") {
      setApproveButton(true);
      setRejectButton(false);
    }
  };

  useEffect(() => {
    if (data) {
      checkUserStatus(data);
      setUserGroup(data?.group);
    }
  }, [data]);

  return (
    <>
      <div className="mx-6 font-ibmArabic mb-20">
        <h1 className="text-sm  text-[#595959] mb-12">
          Users Information {`>`}{" "}
          <span className="text-[#917244]">User Personal Information</span>
        </h1>
        <div
          className="p-12 rounded-3xl "
          style={{
            boxShadow: `0px 1px 3px 0px #00000005,0px 1px 6px 0px #00000012`,
          }}
        >
          <div className="flex items-center justify-between mb-8">
            <p className="text-[#333333] font-medium text-2xl">
              Personal Information
            </p>
            <div className="flex items-center gap-4">
              <button
                onClick={() => {
                  navigate(`/transactions/${id}`);
                }}
                className="text-[#917244] font-medium text-sm bg-[#faf0e2] px-6 py-3 rounded-xl"
              >
                Money Transactions
              </button>
              <button className="text-[#e9c237] font-medium text-sm bg-[#fbf5de] px-6 py-3 rounded-xl">
                Gold Transactions
              </button>
            </div>
          </div>
          <div className="grid">
            <div className="grid grid-cols-2 py-3">
              <NewUserInfoField title={"Username"} data={data?.full_name} />
              <NewUserInfoField
                title={"Join Date"}
                data={formDate(data?.created)}
              />
            </div>
            <hr className="my-6" />
            <div className="grid grid-cols-2 py-3">
              <NewUserInfoField
                title={"Online Status"}
                field={
                  <div
                    className={`
                  border-l-2 px-2  
                  text-lg
                   ${
                     data?.is_online === 1
                       ? "text-[#28A745] border-[#28A745]"
                       : "text-[#DC3545] border-[#DC3545]"
                   } `}
                  >
                    {data?.is_online === 1 ? "Online" : "Offline"}
                  </div>
                }
              />
              <NewUserInfoField
                title={"User Status"}
                data={data?.group === "blockedUser" ? "Blocked" : "Active"}
              />
            </div>
            <hr className="my-6" />

            <div className="grid grid-cols-2 py-3">
              <NewUserInfoField title={"National ID"} data={data?.nationalId} />
              <NewUserInfoField
                title={"National ID Name"}
                data={data?.national_name}
              />
            </div>
            <hr className="my-6" />
            <div className="grid grid-cols-2 py-3">
              <NewUserInfoField
                title={"Document Status"}
                field={
                  <div
                    className={`
                  border-l-2 px-2  
                  text-lg
                   ${
                     data?.kyc_status === "approved" ||
                     data?.kyc_status === "Accepted"
                       ? "text-[#28A745] border-[#28A745]"
                       : data?.kyc_status === "rejected" ||
                         data?.kyc_status === "Rejected"
                       ? "text-[#DC3545] border-[#DC3545]"
                       : data?.kyc_status === "pending" ||
                         data?.kyc_status === "Pending"
                       ? "text-[#FFC107] border-[#FFC107]"
                       : "text-[#595959] border-[#595959]"
                   } `}
                  >
                    {capitalizeFirstLetter(
                      data?.kyc_status === "No KYC"
                        ? "No Document"
                        : data?.kyc_status
                    )}
                  </div>
                }
              />
              <NewUserInfoField title={"Location"} data={"Egypt"} />
            </div>
            <hr className="my-6" />
            <div className="grid grid-cols-2 py-3 relative">
              <NewUserInfoField
                title={"Phone Number"}
                data={`0${data?.phone}`}
              />
              <NewUserInfoField title={"Email"} data={data?.email} />
              <p
                className={`absolute right-0 top-3 text-lg font-ibmArabic ${
                  data?.is_email_verified === 1
                    ? "text-[#28a745]"
                    : "text-[#DC3545] "
                }`}
              >
                {data?.is_email_verified === 1 ? "Verified" : "Not Verified"}
              </p>
            </div>
            <hr className="my-6" />
            <div className="grid grid-cols-2 py-3">
              <NewUserInfoField
                title={"Wallet Balance"}
                field={
                  <p className="text-lg text-[#595959] mr-2">
                    {data?.currentBalance || 0} EGP
                  </p>
                }
              />
              <NewUserInfoField
                title={"Total Gold Fractions"}
                data={`${data?.total_weight} Grams`}
              />
            </div>
            {data?.kyc_status === "rejected" ? (
              <>
                <hr className="my-6" />
                <div className="grid  py-3">
                  <NewUserInfoField
                    title={"Rejection Reason"}
                    data={data?.reject_kyc_reason}
                  />
                </div>
              </>
            ) : null}
            {userGroup === "blockedUser" ? (
              <>
                <hr className="my-6" />
                <div className="grid  py-3">
                  <NewUserInfoField
                    title={"Block Reason"}
                    data={data?.blockReason}
                  />
                </div>
              </>
            ) : null}
            <hr className="my-6" />

            {data?.deleted_at ? (
              <>
                {" "}
                <div className="grid grid-cols-2 py-3 relative">
                  <NewUserInfoField
                    title={"Deleted Reason"}
                    data={data?.delete_reason}
                  />
                </div>
                <hr className="my-6" />
                <div className="grid grid-cols-2 py-3 relative">
                  <NewUserInfoField
                    title={"Feedback"}
                    data={data?.delete_reason}
                  />
                </div>
              </>
            ) : null}
          </div>
          {data?.deleted_at === null ? (
            <div className="flex items-start justify-end mt-8">
              {userGroup === "blockedUser" ? (
                <button
                  onClick={() => {
                    setOpenBlockedAlert(true);
                  }}
                  className="text-white text-lg font-medium font-ibmArabic px-16 py-3 bg-[#917244] rounded-xl"
                >
                  Unblock user
                </button>
              ) : (
                <button
                  onClick={() => {
                    setOpenBlockedAlert(true);
                  }}
                  className="text-[#DC3545] text-lg font-medium font-ibmArabic px-16 py-3 bg-[#DC35451A] rounded-xl"
                >
                  Block user
                </button>
              )}
            </div>
          ) : null}
          {/* this appear only when there is pending documents */}
          {data?.photo_verification ? (
            <>
              {" "}
              <hr className="my-12" />
              <div>
                <p className="text-[#33333] text-2xl font-medium font-ibmArabic mb-8">
                  User Documents
                </p>
                <div className="flex  flex-col gap-4  md:flex-row   w-full justify-center md:gap-2">
                  <KycCard
                    isLoading={isLoading}
                    title="Selfie"
                    img={
                      data?.photo_verification
                        ? `${imageBaseUrl}/${data?.photo_verification}`
                        : `../../assets/noKyc.png`
                    }
                  />
                  <KycCard
                    isLoading={isLoading}
                    title="Front ID Card"
                    img={
                      data?.national_front
                        ? `${imageBaseUrl}/${data?.national_front}`
                        : `../../assets/noKyc.png`
                    }
                  />
                  <KycCard
                    isLoading={isLoading}
                    title="Back ID Card"
                    img={
                      data?.national_back
                        ? `${imageBaseUrl}/${data?.national_back}`
                        : `../../assets/noKyc.png`
                    }
                  />
                </div>
                {data?.kyc_status === "pending" ? (
                  <div className="flex items-center justify-end gap-8 mt-8">
                    <button
                      onClick={() => {
                        setOpenDialog(true);
                      }}
                      className="text-white text-lg font-medium font-ibmArabic px-16 py-3 bg-[#917244] rounded-xl"
                    >
                      Approve Document
                    </button>
                    <button
                      onClick={() => {
                        setType("Reject");
                        setOpenAlert(true);
                      }}
                      className="text-[#917244] text-lg font-medium font-ibmArabic px-16 py-3 border border-[#917244] rounded-xl"
                    >
                      Reject Document
                    </button>
                  </div>
                ) : null}
                {data?.kyc_status === "rejected" ? (
                  <div className="flex items-center justify-end gap-8 mt-8">
                    <button
                      onClick={() => {
                        setOpenDialog(true);
                      }}
                      className="text-white text-lg font-medium font-ibmArabic px-16 py-3 bg-[#917244] rounded-xl"
                    >
                      Approve Document
                    </button>
                  </div>
                ) : null}
                {data?.kyc_status === "approved" ? (
                  <div className="flex items-center justify-end gap-8 mt-8">
                    <button
                      onClick={() => {
                        setType("Reject");
                        setOpenAlert(true);
                      }}
                      className="text-[#917244] text-lg font-medium font-ibmArabic px-16 py-3 border border-[#917244] rounded-xl"
                    >
                      Reject Document
                    </button>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      </div>
      <BlockUserDialog
        openAlert={openBlockedAlert}
        handleCloseAlert={() => {
          setOpenBlockedAlert(false);
        }}
        type={block}
        userGroup={userGroup}
        handleReject={userGroup === "blockedUser" ? handleUnBlock : handleBlock}
        message={`Are you sure you want to ${
          userGroup === "blockedUser" ? "unblock" : "block"
        } this user?`}
        label={userGroup === "blockedUser" ? "Unblock" : "Block"}
        alertType={"Block"}
      />
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title={`User has been ${
          userGroup === "blockedUser" ? "Blocked" : "UnBlocked"
        } Successfully`}
        message=""
        buttonText="Users Information"
      />
      <ActionAlert
        disabled={disableWhileDeleting || !formData?.reject_kyc_reason}
        setFormData={setFormData}
        formData={formData}
        openAlert={openAlert}
        handleCloseAlert={() => {
          setOpenAlert(false);
        }}
        type={type}
        handleReject={handleReject}
        message={`Are you sure you want to ${
          type === "Approve" ? "approve" : "reject"
        } this document?`}
        label={type === "Approve" ? "Approve" : "Reject"}
        alertType={"KYC"}
      />
      <KycDialog
        isLoading={isLoading}
        openDialog={openDialog}
        handleCloseDialog={() => {
          setOpenDialog(false);
        }}
        handleApprove={handleApprove}
        image={
          data?.national_front
            ? `${imageBaseUrl}/${data?.national_front}`
            : `../../assets/noKyc.png`
        }
      />
      <ReusableSnackbar />
      <BottomAlert
        open={openSuccessAlert}
        setOpen={setOpenSuccessAlert}
        message={message}
      />
    </>
  );
};

export default UserProfile;
