import React from "react";
import DashboardMainHeader from "../components/DashboardMainHeader";
import { Outlet } from "react-router-dom";

const PagesWithMainHeader = ({ children }) => {
  return (
    <div>
      <DashboardMainHeader />
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default PagesWithMainHeader;
